import React, { Component } from 'react';
import { Button, Radio, Form, FormField } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class CustomInput extends Component {
  render() {
    return (
      <input
        readOnly
        value={this.props.value}
        onClick={this.props.onClick}
        onChange={this.props.onChange}
      />
    );
  }
}

class PreviousStream extends React.Component {
  render() {
    const {
      availableTimes,
      time,
      date,
      channel,
      handleTime,
      handleDate,
      handleChannel,
      fetchStreamUrl,
      fetchAvailableTimes,
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className='date-time-report' style={{ color: '#000000de' }}>
          <DatePicker
            timeIntervals={5}
            dateFormat='DD/MM/YYYY'
            selected={date}
            onChange={handleDate}
            maxDate={moment().add(0, 'days')}
            customInput={<CustomInput />}
          />
        </div>

        <Form style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormField>
            <Radio
              label={'Channel 1'}
              value={1}
              checked={channel === 1}
              onChange={handleChannel}
            />
          </FormField>
          <FormField style={{ marginLeft: '10px' }}>
            <Radio
              label={'Channel 2'}
              value={2}
              checked={channel === 2}
              onChange={handleChannel}
            />
          </FormField>
        </Form>

        {availableTimes.length ? (
          <select
            onChange={(e) => handleTime(e.target.value)}
            className='select-semantic'
            style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.87)' }}
            value={time}
          >
            {availableTimes.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : null}

        {availableTimes.length ? (
          <Button
            color='teal'
            content='Stream'
            disabled={!time}
            onClick={() => fetchStreamUrl(channel, date, time)}
          />
        ) : (
          <Button
            color='teal'
            content='Submit'
            onClick={() => fetchAvailableTimes(channel, date)}
          />
        )}
      </div>
    );
  }
}

export default PreviousStream;

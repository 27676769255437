import React from 'react';
import { Button, Loader, Table, Search } from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

const token = () => window.localStorage.getItem('user');

class List extends React.Component {
  state = {
    loading: true,
    data: [],
    search: '',
    deleteId: '',
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    this.setState({ loading: true });
    axios
      .get(`${REGULAR_PATH}/dpoints?&access_token=${token()}`)
      .then((response) => {
        this.setState({
          loading: false,
          data: response.data.filter((item) => item),
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleDelete = (id) => {
    this.setState({ deleteId: id });

    axios
      .delete(`${REGULAR_PATH}/dpoints?point_id=${id}&access_token=${token()}`)
      .then((response) => {
        const { status } = response.data;

        if (status === 'success') {
          this.setState({
            deleteId: '',
          });

          this.fetchList();
        }
      })
      .catch(() => {
        this.setState({ deleteId: '' });
      });
  };

  handleSearch = (_, data) => {
    this.setState({ search: data.value.toLowerCase() });
  };

  render() {
    const { loading, data, search, deleteId } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div>
        <Search
          onSearchChange={this.handleSearch}
          value={search}
          placeholder='Search Here'
          showNoResults={false}
          style={{ width: '300px' }}
        />

        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data
              .filter(({ name }) => name.toLowerCase().includes(search))
              .map(({ type, name, _id }, index) => (
                <Table.Row key={_id.$oid}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{type}</Table.Cell>
                  <Table.Cell>
                    <Button
                      color='red'
                      loading={_id.$oid === deleteId}
                      disabled={_id.$oid === deleteId}
                      onClick={() => this.handleDelete(_id.$oid)}
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default List;

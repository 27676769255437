import React from 'react';
import { Icon, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import { reportDownloadExcel } from '../helper/dataService';
import { getIdsAndTime, getActiveAssetList, getOrg } from '../helper/helper';
import reportsEnv from '../reportsEnv';
import { alertMessage } from '../../../helper/alert-message';

class LocationLiveA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelDisableBtn: false,
      selectedAssets: [],
    };
  }

  componentDidMount() {
    const { startTime, endTime } = JSON.parse(
      window.localStorage.getItem('reports_state'),
    );

    this.setState({
      selectedAssets: getActiveAssetList().map((item) => item.value),
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids } = getIdsAndTime(this.state);

    reportDownloadExcel(reportsEnv.locationLiveA, {
      asset_ids: ids,
      org_oid: getOrg(),
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Live_A_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const { selectedAssets, excelDisableBtn } = this.state;

    return (
      <div>
        <div className=' center-report'>
          <Header as='h2'>Location Live [A]</Header>

          <div style={{ margin: '20px 0' }}>
            <Label.Group size='large'>
              <Label>
                <Icon name='car' />
                {selectedAssets.length} assets [Default all assets are selected]
              </Label>
            </Label.Group>
          </div>

          {selectedAssets.length > 0 && (
            <Button.Group>
              <Button
                color='teal'
                style={{ width: '200px', fontSize: '16px' }}
                icon='file excel outline'
                content='Download Excel'
                onClick={this.handleDownloadExcelBtn}
                disabled={excelDisableBtn}
                loading={excelDisableBtn}
              />
            </Button.Group>
          )}
        </div>
      </div>
    );
  }
}

export default LocationLiveA;

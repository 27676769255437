import React from 'react';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Area,
  Tooltip as TooltipChart,
} from 'recharts';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportView,
  reportDownloadPdf,
  reportDownloadExcel,
} from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.fuel;

class Fuel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredFuelData: [],
      fuelStatusSelect: 'all',
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      graphList: [],
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportView(reportsEnv.fuel, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          filteredFuelData: response.data.map((item) => item.fuel_data),
          graphList: response.data.map((asset) => {
            const list = [];
            asset.fuel_data.forEach((day) => {
              day.forEach((item) =>
                list.push({
                  fuel: item.fuel_litre,
                  speed: item.speed,
                  time: moment(item.time.$date).format('D/M/YYYY hh:mm A'),
                }),
              );
            });
            return list;
          }),
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadPdf(reportsEnv.fuel, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Fuel_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(reportsEnv.fuel, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Fuel_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleFuelStatus = (event) => {
    const value = event.target.value;
    const statusCheck = (status) => {
      if (
        (status.fuel_status === 'minus' || status.fuel_status === 'plus') &&
        value === 'both'
      ) {
        return status;
      } else if (value === 'all') {
        return status;
      }
    };

    this.setState({
      fuelStatusSelect: value,
      filteredFuelData: this.state.data.map((item) =>
        item.fuel_data.map((data) =>
          data.filter((fuel_item) => statusCheck(fuel_item)),
        ),
      ),
    });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      fuelStatusSelect,
      filteredFuelData,
      graphList,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Fuel [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file pdf outline'
                  content='Download PDF'
                  onClick={this.handleDownloadPdfBtn}
                  disabled={pdfDisableBtn || excelDisableBtn}
                  loading={pdfDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Fuel </Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />

            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' />
                              Fuel Sensor Connected:{' '}
                              {item.asset_detail.fuel_connected ? 'Yes' : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h3 className='ui center aligned icon header'>
                          Distance: {parseInt(item.asset_detail.distance)} km
                        </h3>

                        {['5da4610f1d41c87c6a7df44a'].includes(
                          window.localStorage.getItem('organization'),
                        ) ||
                        [
                          'shamim@finder-lbs.com',
                          'reaznahid@finder-lbs.com',
                          'momit@finder-lbs.com',
                          'ishmam@finder-lbs.com',
                        ].includes(window.localStorage.getItem('email')) ? (
                          <div>
                            <h3 className='ui center aligned icon header'>
                              Total Fuel Consumed:
                              {' ~ '}
                              {item.asset_detail.fuel_consumed.toFixed(2) ||
                                '0'}{' '}
                              litre
                            </h3>
                            {item.asset_detail.fuel_consumed > 0 && (
                              <h3 className='ui center aligned icon header'>
                                Fuel Consumption Rate:
                                {' ~ '}
                                {(
                                  item.asset_detail.distance /
                                  item.asset_detail.fuel_consumed
                                ).toFixed(2) || '0'}{' '}
                                km(s)/litre
                              </h3>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {graphList[i].length ? (
                  <ResponsiveContainer height={200} width='100%'>
                    <ComposedChart data={graphList[i]}>
                      <CartesianGrid stroke='#ced4da' />
                      <XAxis dataKey='time' />
                      <YAxis />
                      <TooltipChart />
                      <Legend />

                      <Area
                        type='monotone'
                        dataKey='fuel'
                        name='Fuel'
                        stroke='#ffa600'
                        fill='#ffa600'
                        dot={false}
                        isAnimationActive={true}
                      />

                      <Area
                        type='monotone'
                        dataKey='speed'
                        name='Speed'
                        stroke='#2f4b7c'
                        fill='#2f4b7c'
                        dot={false}
                        isAnimationActive={true}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : null}
                <select
                  className='select-semantic'
                  value={fuelStatusSelect}
                  onChange={(event) => this.handleFuelStatus(event)}
                  style={{ float: 'right', margin: '10px 0' }}
                >
                  <option value='all'>All</option>
                  <option value='both'>Fuel Loaded and Unloaded</option>
                </select>

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Fuel (Litre)</Table.HeaderCell>
                      <Table.HeaderCell>Fuel Status</Table.HeaderCell>
                      <Table.HeaderCell>Speed</Table.HeaderCell>
                      <Table.HeaderCell>Nearest Landmark</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {fuelStatusSelect &&
                      filteredFuelData.length > 0 &&
                      filteredFuelData[i].map((dateData, idx) => {
                        return dateData.map((last, i) => {
                          return last.fuel_litre ? (
                            <Table.Row key={i}>
                              <Table.Cell>
                                {last.time
                                  ? moment(last.time.$date).format(
                                      'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                    )
                                  : ''}
                              </Table.Cell>
                              <Table.Cell>{`~ ${last.fuel_litre}`}</Table.Cell>
                              <Table.Cell>
                                {last.fuel_status === 'plus'
                                  ? 'Fuel Loaded'
                                  : last.fuel_status === 'minus'
                                    ? 'Fuel Unloaded'
                                    : ''}
                              </Table.Cell>
                              <Table.Cell>{last.speed}</Table.Cell>
                              <Table.Cell>
                                {last.nearest_location !== undefined
                                  ? last.nearest_location.length > 0
                                    ? last.nearest_location[0].name
                                    : ''
                                  : ''}
                              </Table.Cell>
                            </Table.Row>
                          ) : null;
                        });
                      })}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default Fuel;

import React from 'react';
import {
  Loader,
  Modal,
  Input,
  Button,
  Message,
  Card,
  Icon,
  Label,
} from 'semantic-ui-react';
import { Map, Polyline, FeatureGroup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { getData, postData, putData } from '../../vms/dataHelper';
import moment from 'moment';
import MapLayers from '../../MapLayers';

class CreateGeofence extends React.Component {
  state = {
    loadingRoute: true,
    routePolyline: [],
    geofenceNameList: [],
    currentGeofence: [],
    currentGeofenceName: '',
    geofences: [],
    showModal: false,
    submitted: false,
    hideMap: false,
    message: false,
    negativeMessage: false,
  };
  componentDidMount() {
    this.fetchRoute();
    this.fetchGeofenceNames();
  }

  fetchRoute() {
    const { assetId = '', start = '', end = '' } = this.props;
    getData(
      `assets/${assetId}/history_data_fuel`,
      `from=${moment(start).unix()}&to=${moment(end).unix()}`,
    )
      .then(({ data }) => {
        if (data.length) {
          this.setState({
            loadingRoute: false,
            routePolyline: data.map(({ loc }) => loc.coordinates.reverse()),
          });
          this.refs.leaflet.leafletElement.fitBounds(
            this.refs.leafletPolyline.leafletElement.getBounds(),
          );
        } else {
          this.setState({ loadingRoute: false, hideMap: true });
        }
      })
      .catch(() => this.setState({ loadingRoute: false }));
  }

  fetchGeofenceNames = () => {
    getData('geofence', `org_id=${window.localStorage.getItem('organization')}`)
      .then(({ data }) => {
        this.setState({
          geofenceNameList: data.map(({ name }) => name.trim()),
        });
      })
      .catch(() => {});
  };

  handleGeofenceCreate = (e) => {
    const currentGeofence = e.layer._latlngs[0].map((item) => [
      item.lng,
      item.lat,
    ]);
    this.setState({ showModal: true, currentGeofence });
  };

  handleModalClose = () => {
    this.setState({ showModal: false, currentGeofence: [] });
  };

  handleCurrentGeofenceName = (e) => {
    this.setState({ currentGeofenceName: e.target.value });
  };

  addGeofence = () => {
    this.setState({ submitted: true });
    const {
      currentGeofence,
      currentGeofenceName,
      geofences,
      geofenceNameList,
    } = this.state;

    postData('geofence', {
      coordinates: currentGeofence,
      name: currentGeofenceName,
      org_id: window.localStorage.getItem('organization'),
    })
      .then(({ data: { status = '', geo_oid = '' } }) => {
        this.setState({
          submitted: false,
          showModal: false,
          currentGeofence: [],
          currentGeofenceName: '',
        });
        if (status === 'geofence created') {
          this.setState({
            geofences: [
              ...geofences,
              {
                name: currentGeofenceName,
                positions: currentGeofence.map((item) => item.reverse()),
              },
            ],
            geofenceNameList: [...geofenceNameList, currentGeofenceName],
          });
          this.addAssetToGeofence(geo_oid);
          this.showMessage('Geofence Created Succesfully');
        } else {
          this.showNegMessage(status);
        }
      })
      .catch(() => {
        this.setState({
          submitted: false,
          showModal: false,
          currentGeofence: [],
          currentGeofenceName: '',
        });
      });
  };

  addAssetToGeofence = (geoId) => {
    putData(
      `geofence`,
      {
        enforced_assets: [this.props.assetId],
      },
      `geo_id=${geoId}`,
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  showMessage = (message) => {
    this.setState({
      message,
    });
    const hideMessage = setTimeout(() => {
      this.setState({
        message: false,
      });
      clearTimeout(hideMessage);
    }, 2000);
  };
  showNegMessage = (message = 'Sorry. Something went wrong.') => {
    this.setState({
      negativeMessage: message,
    });
    const hideNegMessage = setTimeout(() => {
      this.setState({
        negativeMessage: false,
      });
      clearTimeout(hideNegMessage);
    }, 2000);
  };

  render() {
    const {
      loadingRoute,
      routePolyline,
      geofences,
      currentGeofenceName,
      geofenceNameList,
      showModal,
      submitted,
      hideMap,
      message,
      negativeMessage,
    } = this.state;

    const { assetName, start, end } = this.props;

    if (loadingRoute) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    if (hideMap) {
      return <h2>Sorry! No route data found.</h2>;
    }

    return (
      <div>
        <Label.Group>
          <Label>
            <Icon name='car' />
            {assetName}
          </Label>
          <Label>
            <Icon name='play' />
            {moment(start).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
          </Label>
          <Label>
            <Icon name='stop' />
            {moment(end).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
          </Label>
        </Label.Group>
        {message && <Message icon='check' header={message} color='green' />}
        {negativeMessage && (
          <Message icon='check' header={negativeMessage} color='red' />
        )}
        <Map
          id='GeofenceMap'
          ref='leaflet'
          animate={true}
          style={{ height: '65vh' }}
        >
          <MapLayers />
          {routePolyline.length > 0 ? (
            <Polyline
              ref='leafletPolyline'
              positions={routePolyline}
              color='red'
              smoothFactor='1'
            />
          ) : null}

          <FeatureGroup>
            <EditControl
              position='topright'
              onCreated={this.handleGeofenceCreate}
              draw={{
                polygon: true,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{
                edit: false,
                remove: true,
              }}
            />
          </FeatureGroup>
          {geofences.length > 0
            ? geofences.map(({ positions, name }) => (
                <Polygon
                  key={name}
                  positions={positions}
                  attribution={name}
                  lineCap='round'
                  smoothFactor={1}
                />
              ))
            : null}
        </Map>

        <Card style={{ width: '100%', minHeight: '100px' }}>
          <Card.Content>
            <Card.Header>
              <Icon name='life ring' />
              New Geofences
            </Card.Header>
            <Card.Description>
              {geofences.map(({ name }) => (
                <Button basic key={name}>
                  {name}
                </Button>
              ))}
            </Card.Description>
          </Card.Content>
        </Card>

        <Modal
          closeOnDimmerClick={false}
          closeIcon
          onClose={this.handleModalClose}
          open={showModal}
        >
          <Modal.Header>Geofence Name</Modal.Header>
          <Modal.Content>
            <Input
              fluid
              value={currentGeofenceName}
              onChange={this.handleCurrentGeofenceName}
              placeholder='Input Geofence Name'
            />
            <br />
            {geofenceNameList.some(
              (name) =>
                name.toLowerCase() === currentGeofenceName.toLowerCase().trim(),
            ) ? (
              <Message negative>This name already exist</Message>
            ) : (
              <Button
                color='violet'
                fluid
                onClick={this.addGeofence}
                disabled={submitted}
              >
                Submit
              </Button>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default CreateGeofence;

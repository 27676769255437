import React from 'react';
import { Menu } from 'semantic-ui-react';

import DamageItem from './DamageItem';
import Branch from './Branch';
import Particular from './Particular';
import Payment from './Payment';

class AddOptions extends React.Component {
  state = { activeItem: '' };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, editEvent: false });

  render() {
    const { activeItem } = this.state;
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name='Damage Item'
            active={activeItem === 'Damage Item'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Branch'
            active={activeItem === 'Branch'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Particular'
            active={activeItem === 'Particular'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Payment'
            active={activeItem === 'Payment'}
            onClick={this.handleItemClick}
          />
        </Menu>

        {activeItem === 'Damage Item' && <DamageItem org={this.props.org} />}
        {activeItem === 'Branch' && <Branch org={this.props.org} />}
        {activeItem === 'Particular' && <Particular org={this.props.org} />}
        {activeItem === 'Payment' && <Payment org={this.props.org} />}
      </div>
    );
  }
}

export default AddOptions;

import { getData } from './dataService.js';
import { REGULAR_PATH } from '../../env';

const logoutApp = function () {
  const url = `${REGULAR_PATH}/auth/logout?access_token=`;
  if (!JSON.parse(window.localStorage.getItem('staff'))) {
    const logoutResponse = getData(url);
    logoutResponse.then((res) => {});
  }
  window.localStorage.clear();
  window.location.reload(true);
};

export default logoutApp;

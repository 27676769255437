import React from 'react';
import { Segment, Dropdown, Button } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getActiveAssetList } from '../../reports/helper/helper';

class RouteTimeAndAsset extends React.Component {
  state = {
    startTime: moment().startOf('day'),
    endTime: moment().endOf('day'),
    assetList: [],
    selectedAsset: '',
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        assetList: getActiveAssetList(),
      });
    }, 1000);
  }

  handleStartTime = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTime = (value) => {
    this.setState({
      endTime: value,
    });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ selectedAsset: data.value });
  };

  render() {
    const { startTime, endTime, assetList, selectedAsset } = this.state;

    if (!assetList.length) {
      return <Segment>No Asset Found</Segment>;
    }

    const hours = endTime.diff(startTime, 'hours');

    return (
      <div>
        <div className='date-time-report'>
          <DatePicker
            selected={startTime}
            onChange={this.handleStartTime}
            showTimeSelect
            maxDate={moment().add(0, 'days')}
            timeIntervals={5}
            dateFormat='LLL'
          />
          <span
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            —
          </span>
          <DatePicker
            selected={endTime}
            onChange={this.handleEndTime}
            showTimeSelect
            maxDate={moment().add(0, 'days')}
            timeIntervals={5}
            dateFormat='LLL'
          />
        </div>
        <br />
        <div>
          <Dropdown
            placeholder='Select Asset(s)'
            fluid
            search
            selection
            options={assetList}
            onChange={this.handleAssetSelect}
            value={selectedAsset}
          />
        </div>
        <br />
        {selectedAsset && startTime && endTime && hours > 0 && hours < 24 ? (
          <Button
            color='teal'
            content='Route'
            onClick={() =>
              this.props.setValues(
                selectedAsset,
                assetList.find((item) => item.value === selectedAsset).name,
                startTime,
                endTime,
              )
            }
          />
        ) : (
          `You can't select more than 24 hours range`
        )}
      </div>
    );
  }
}

export default RouteTimeAndAsset;

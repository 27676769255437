import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import { alertMessage } from '../../../helper/alert-message';

const token = () => window.localStorage.getItem('user');

class Download extends React.Component {
  state = {
    loading: false,
    startTime: moment().startOf('day'),
    endTime: moment().endOf('day'),
  };

  handleStartTime = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTime = (value) => {
    this.setState({
      endTime: value,
    });
  };

  handleUserReport = () => {
    const { startTime, endTime } = this.state;

    this.setState({ loading: true });

    axios({
      method: 'post',
      url: `https://api.finder-lbs.com/v1.3/reports/user_behav_excel?access_token=${token()}`,
      data: JSON.stringify({
        from: moment(startTime).unix(),
        to: moment(endTime).unix(),
        org_id: '5efd6d101d41c855060b1632',
        report_names: [
          'distance_day_wise',
          'distance_time_wise',
          'speed',
          'location',
          'location_live',
          'raw',
          'summary',
          'fuel',
          'ignition',
          'geofence_violation',
          'ignition_halt',
        ],
      }),
      responseType: 'blob',
      headers: {
        'content-type': 'text/plain',
      },
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `User_Report${moment().format('DD-MMMM-YYYY_hh:mm A')}.xlsx`,
        );
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const { loading, startTime, endTime } = this.state;

    const duration = parseInt(endTime.diff(startTime, 'days')) + 1;
    const limitCrossed = duration > 31;

    if (limitCrossed) {
      alertMessage(`You can't select this report more than 31 days.`);
    }

    return (
      <div className=' center-of-page'>
        <Header as='h3'>Download Report</Header>
        <div className='date-time-report'>
          <DatePicker
            selected={startTime}
            onChange={this.handleStartTime}
            maxDate={moment().add(0, 'days')}
            dateFormat='DD/MM/YYYY'
          />
          <span
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            —
          </span>
          <DatePicker
            selected={endTime}
            onChange={this.handleEndTime}
            maxDate={moment().add(0, 'days')}
            dateFormat='DD/MM/YYYY'
          />
        </div>
        {limitCrossed ? null : (
          <Button
            color='teal'
            icon='file excel outline'
            content='User'
            onClick={this.handleUserReport}
            loading={loading}
            disabled={loading}
          />
        )}
      </div>
    );
  }
}

export default Download;

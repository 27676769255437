import React from 'react';
import { Link } from 'react-router-dom';

class MonthlyBillRedirect extends React.Component {
  componentDidMount() {
    window.location.href = '/monthlybill';
  }
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Link to='/monthlybill'>Redirecting to Monthly Bill</Link>
      </div>
    );
  }
}

export default MonthlyBillRedirect;

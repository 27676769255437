import React from 'react';
import { Menu, Segment, Header } from 'semantic-ui-react';

import Add from './Add';
import BulkAdd from './BulkAdd';
import List from './List';
import Edit from './Edit';
import Download from './Download';

class AssetPanel extends React.Component {
  state = { activeItem: '', userOidForEdit: '' };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, userOidForEdit: '' });

  selectUserIdForEdit = (id) =>
    this.setState({ userOidForEdit: id, activeItem: 'Edit' });

  editComplete = () =>
    this.setState({ activeItem: 'List', userOidForEdit: '' });

  render() {
    if (
      !['5efd6d101d41c855060b1632'].includes(
        window.localStorage.getItem('organization'),
      )
    ) {
      return (
        <h3 style={{ textAlign: 'center' }}>
          You are not eligible to browse this link.
        </h3>
      );
    }

    const { activeItem, userOidForEdit } = this.state;
    return (
      <Segment>
        <Menu pointing secondary>
          <Menu.Item
            name='List'
            active={activeItem === 'List'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Add'
            active={activeItem === 'Add'}
            onClick={this.handleItemClick}
          />
          {/* <Menu.Item
            name='Bulk Add'
            active={activeItem === 'Bulk Add'}
            onClick={this.handleItemClick}
          /> */}
          <Menu.Item
            name='Download'
            active={activeItem === 'Download'}
            onClick={this.handleItemClick}
          />
          {userOidForEdit ? (
            <Menu.Item
              name='Edit'
              active={activeItem === 'Edit'}
              onClick={this.handleItemClick}
            />
          ) : null}
        </Menu>
        {activeItem === 'Add' && <Add />}
        {activeItem === 'Bulk Add' && <BulkAdd />}
        {activeItem === 'List' && (
          <List selectUserIdForEdit={this.selectUserIdForEdit} />
        )}
        {activeItem === 'Download' && <Download />}
        {activeItem === 'Edit' && (
          <Edit
            userOidForEdit={userOidForEdit}
            editComplete={this.editComplete}
          />
        )}
        {activeItem === '' && <Header as='h4'>Select a Tab</Header>}
      </Segment>
    );
  }
}

export default AssetPanel;

import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

const accessToken = () => window.localStorage.getItem('user');
const cros = {
  'Access-Control-Allow-Origin': '*',
};

export const getData = (path, params = '') => {
  return axios.get(
    `${REGULAR_PATH}/${path}?access_token=${accessToken()}&${params}`,
  );
};

export const postData = (path, data, params = '') => {
  const jsonData = JSON.stringify(data);
  return axios.post(
    `${REGULAR_PATH}/${path}?access_token=${accessToken()}&${params}`,
    jsonData,
    cros,
  );
};

export const putData = (path, data, params = '') => {
  const jsonData = JSON.stringify(data);
  return axios.put(
    `${REGULAR_PATH}/${path}?access_token=${accessToken()}&${params}`,
    jsonData,
    cros,
  );
};

export const deleteData = (path, id, params = '') => {
  return axios.delete(
    `${REGULAR_PATH}/${path}/${id}?access_token=${accessToken()}&${params}`,
  );
};

export const deleteOption = (path, data) => {
  const jsonData = JSON.stringify(data);
  return axios.patch(
    `${REGULAR_PATH}/${path}?access_token=${accessToken()}`,
    jsonData,
    cros,
  );
};

export const editData = (path, data) => {
  const jsonData = JSON.stringify(data);
  return axios.put(
    `${REGULAR_PATH}/${path}?access_token=${accessToken()}`,
    jsonData,
    cros,
  );
};

import React from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Header,
  Loader,
} from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class Edit extends React.Component {
  state = {
    userId: '',
    name: '',
    unit: '',
    id: '',
    submit: false,
    status: '',
    loading: true,
  };

  componentDidMount() {
    axios
      .post(
        `${BASE_PATH}/v1.2/reports/get_runners_employee?access_token=${token()}`,
        JSON.stringify({
          user_oids: [this.props.userOidForEdit],
        }),
      )
      .then((response) => {
        const data = response.data[0];
        const { employee_name, employee_unit, employee_id, user_id } = data;

        this.setState({
          loading: false,
          userId: user_id,
          name: employee_name,
          id: employee_id,
          unit: employee_unit,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  }

  saveData = () => {
    const { userId, name, id, unit } = this.state;

    const data = {
      org_oid: org(),
      user_oid: this.props.userOidForEdit,
      user_id: userId,
      employee_name: name,
      employee_id: id,
      employee_unit: unit,
    };

    this.setState({ submit: true, status: '' });

    axios
      .put(
        `${BASE_PATH}/v1.2/reports/runners_employee?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const message = response.data.message;
        this.setState({ status: message, submit: false });
        if (message === 'Success') {
          this.props.editComplete();
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const { userId, name, unit, id, submit, loading, status } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Header as='h5'>USER ID: {userId}</Header>
        <Form>
          <Form.Field
            control={Input}
            label='Name'
            onChange={this.handleInput}
            value={name}
            name='name'
          />
          <Form.Field
            control={Input}
            label='ID'
            onChange={this.handleInput}
            value={id}
            name='id'
          />
          <Form.Field
            control={Input}
            label='Unit'
            onChange={this.handleInput}
            value={unit}
            name='unit'
          />
        </Form>

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          disabled={submit}
        >
          Submit
        </Button>

        {status === 'Success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'Success' && (
          <Message negative> {status}</Message>
        )}
      </div>
    );
  }
}

export default Edit;

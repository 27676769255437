import React from 'react';
import { Circle, Map, Marker, Polyline, Tooltip } from 'react-leaflet';
import { alertMessage } from './../../helper/alert-message.js';
import Leaflet, { divIcon } from 'leaflet';
import { observer, inject } from 'mobx-react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Button, Icon } from 'semantic-ui-react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Area,
  Tooltip as TooltipChart,
} from 'recharts';
import LandMarkPopUp from './LandMarkPopUp';
import SpeedMeter from './speedMeter/speedMeter';
import moment from 'moment';
import { isCrown } from '../../helper/check-org.js';
import RotatedMarker from '../RotatedMarker';
import MapLayers from '../MapLayers';

const time = new Date().toString();

@inject('assets')
@observer
class Maps extends React.Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.intervalId = null;
    this.intervalTimer = 1000;
    this.cardata = [];
    this.polyAnim = [];
    this.animateData = [];
    this.animateDataFinalTest = [];
    this.countDistanceMeter = 50;
    this.sequenceCount = 3;
    this.state = {
      markers: [],
      position: [24.146754, 90.499477],
      zoom: 7,
      minZoom: 6,
      maxZoom: 18,
      circle: [0, 0],
      name: 'Your Asset Name',
      time: time,
      mapBounds: [
        [26.273714, 87.434692],
        [21.912471, 94.092407],
      ],
      polylines: [
        [0, 0],
        [0, 0],
      ],
      bearing: 0,
      totalDistance: 0,
      routeColor: '#000',
      assetLiveMode: true,
      assetLiveModeCheck: true,
      nearLanMark: [],
      isAnimationRunning: false,
      isAnimationRunningStop: false,
      isRouteDrawing: false,
      icon: 'pause',
      content: 'Pause',
      startPoint: [],
      endPoint: [],
      haltIconPoint: [],
      animateDataFinal: [],
      HideBar: true,
      animateCount: false,
      colorCode: '#000000',
      routeDataFinalArray: [],
      showLandMark: false,
      showSelectedAssetLandMark: [],
      speed: 0,
      finalFuelLiters: 0,
      permanent: false,
      firstTimeZoom: false,
      animationIndex: 0,
      animateOrRoute: false,
      animationPrinted: false,
      slowerActive: false,
      fasterActive: false,
      normalActive: true,
      zoomLevel: 5,
      bounds: [
        [26.765230565697482, 93.17504882812501],
        [22.11617714721062, 86.5667724609375],
      ],
      livePolyline: [],
      showChart: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const insideBd = (latitude, longitude) => {
      return (
        latitude >= 20.575613 &&
        latitude <= 26.641968 &&
        longitude >= 87.7666143 &&
        longitude <= 92.680189
      );
    };

    const insideNp = (latitude, longitude) => {
      return (
        latitude >= 26.330028 &&
        latitude <= 30.493346 &&
        longitude >= 80.030058 &&
        longitude <= 88.225859
      );
    };

    const insideAu = (latitude, longitude) => {
      return (
        latitude >= -38.916723 &&
        latitude <= -10.721462 &&
        longitude >= 112.814585 &&
        longitude <= 153.603819
      );
    };

    const insideUsa = (latitude, longitude) => {
      return (
        latitude >= 25.837328 &&
        latitude <= 49.400774 &&
        longitude >= -124.749178 &&
        longitude <= -66.873077
      );
    };

    if (prevProps.id !== this.props.id) {
      this.refs.leaflet.leafletElement.setZoom(8);

      this.setState({
        polylines: [],
        animateDataFinal: [],
        totalDistance: 0,
        isAnimationRunning: false,
        isAnimationRunningStop: false,
        startPoint: [],
        endPoint: [],
        permanent: false,
        isRouteDrawing: false,
        HideBar: false,
        livePolyline: [],
      });
      this.circleBound(this.props.currentAsset);
    }

    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate.length < 1) {
        this.setState({
          isAnimationRunning: false,
          isAnimationRunningStop: false,
          startPoint: [],
          endPoint: [],
          permanent: false,
          totalDistance: 0,
        });
        clearInterval(this.intervalId);
      } else {
        clearInterval(this.intervalId);
        this.setState({
          HideBar: true,
          polylines: [
            [0, 0],
            [0, 0],
          ],
          isAnimationRunning: false,
          animateOrRoute: true,
          livePolyline: [],
        });

        let markerInfo = this.props.animate;

        const startPoint = markerInfo[0].position;
        const endPoint = markerInfo[markerInfo.length - 1].position;
        let bounds = [];
        let animateData = [];
        let animateDataFinal = [];

        for (let i in markerInfo) {
          const [latitude, longitude] = markerInfo[i].position;

          if (insideBd(latitude, longitude) || insideNp(latitude, longitude) || insideAu(latitude, longitude) || insideUsa(latitude, longitude)) {
            let speed, colorCode, time, bearing, asset_type, name;
            speed = markerInfo[i].children[4].speed;
            bearing = markerInfo[i].children[5].bearing;
            asset_type = markerInfo[i].children[0].asset_type;
            name = markerInfo[i].children[1].name;
            time = moment(markerInfo[i].children[6].time.$date).format(
              'DD/MM/YY, hh:mm:ss a',
            );

            if (speed >= 110) {
              colorCode = '#db4d4c';
            } else if (speed >= 80) {
              colorCode = '#b74dfe';
            } else if (speed >= 60) {
              colorCode = '#4db64c';
            } else if (speed >= 25) {
              colorCode = '#6db6fe';
            } else if (speed >= 5) {
              colorCode = '#fcb66f';
            } else if (speed < 5) {
              colorCode = '#6d6f6f';
            }

            let ployLine = {
              color: colorCode,
              position: markerInfo[i].position,
              speed: speed,
              time: time,
              totalDistance: 0,
            };
            // Fuel calculation
            if (markerInfo[i].fuelConnected) {
              ployLine.litter = markerInfo[i].fuel;
            }
            ployLine.bearing = bearing;
            ployLine.asset_type = asset_type;
            ployLine.name = name;
            animateData.push(ployLine);
            ployLine = {};
            if (
              markerInfo[i].position[0] !== 0 ||
              markerInfo[i].position[1] !== 0
            ) {
              bounds.push(markerInfo[i].position);
            }
          }
        }

        let distance = 0;
        for (let index = 0; index < animateData.length - 1; index++) {
          let a = animateData[index].position;
          let b = animateData[index + 1].position;
          let c = [a, b];

          /* Distance calculate code */
          let radlat1 = (Math.PI * a[0]) / 180;
          let radlat2 = (Math.PI * b[0]) / 180;
          let theta = a[1] - b[1];
          let radtheta = (Math.PI * theta) / 180;
          let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          dist = Math.acos(dist);
          dist = (dist * 180) / Math.PI;
          dist = dist * 60 * 1.1515;
          dist = dist * 1.609344;
          if (dist) {
            distance = distance + dist;
          } else {
            distance = distance + 0;
          }
          animateData[index].totalDistance = distance;
          animateData[index].position = c;
          animateDataFinal.push(animateData[index]);
        }

        this.setState({
          polylines: animateDataFinal,
          animateDataFinal: animateDataFinal,
          totalDistance:
            animateDataFinal[animateDataFinal.length - 1].totalDistance,
          position: startPoint,
          startPoint: startPoint,
          endPoint: endPoint,
          zoom: 18,
          assetLiveModeCheck: false,
          isRouteDrawing: false,
          isAnimationRunning: true,
          HideBar: true,
          animateOrRoute: true,
          permanent: true,
          icon: 'pause',
          content: 'Pause',
        });
        this.playAnimation();
      }
    }

    if (prevProps.route !== this.props.route) {
      if (this.props.route) {
        if (this.props.route.length < 1) {
          this.setState({
            isRouteDrawing: false,
            startPoint: [],
            endPoint: [],
          });
          clearInterval(this.intervalId);
        } else {
          clearInterval(this.intervalId);
          this.polyAnim = [];
          this.setState({
            HideBar: true,
            polylines: [
              [0, 0],
              [0, 0],
            ],
            isAnimationRunning: false,
            isAnimationRunningStop: false,
            animateOrRoute: true,
            routeDataFinalArray: [],
            totalDistance: 0,
            livePolyline: [],
          });
          let markerInfo = this.props.route;

          const FirstRoutePoint = markerInfo[0].position;
          const LastRoutePoint = markerInfo[markerInfo.length - 1].position;
          let bounds = [];
          let routeData = [],
            routeDataFinal = [];

          for (let i in markerInfo) {
            const [latitude, longitude] = markerInfo[i].position;

            if (
              insideBd(latitude, longitude) ||
              insideNp(latitude, longitude) ||
              insideAu(latitude, longitude) ||
              insideUsa(latitude, longitude)
            ) {
              let speed, colorCode, time, bearing;

              speed = markerInfo[i].children[4].speed;
              bearing = markerInfo[i].children[5].bearing;
              time = moment(markerInfo[i].children[6].time.$date).format(
                'DD/MM/YY, hh:mm:ss a',
              );

              if (speed >= 110) {
                colorCode = '#db4d4c';
              } else if (speed >= 80) {
                colorCode = '#b74dfe';
              } else if (speed >= 60) {
                colorCode = '#4db64c';
              } else if (speed >= 25) {
                colorCode = '#6db6fe';
              } else if (speed >= 5) {
                colorCode = '#fcb66f';
              } else if (speed < 5) {
                colorCode = '#6d6f6f';
              }

              let ployLine = {
                color: colorCode,
                position: markerInfo[i].position,
                speed: speed,
                time: time,
                totalDistance: 0,
                bearing: bearing,
              };

              // Fuel calculation
              if (markerInfo[i].fuelConnected) {
                ployLine.litter = markerInfo[i].fuel;
              }

              // Temperature
              if (markerInfo[i].temperatureSensor) {
                ployLine.temperature = markerInfo[i].temperature;
              }

              routeData.push(ployLine);
              ployLine = {};
              if (
                markerInfo[i].position[0] !== 0 ||
                markerInfo[i].position[1] !== 0
              ) {
                bounds.push(markerInfo[i].position);
              }
            }
          }

          let distance = 0;
          for (let index = 0; index < routeData.length - 1; index++) {
            let a = routeData[index].position;
            let b = routeData[index + 1].position;
            let c = [a, b];

            /* Distance calculate code */
            let radlat1 = (Math.PI * a[0]) / 180;
            let radlat2 = (Math.PI * b[0]) / 180;
            let theta = a[1] - b[1];
            let radtheta = (Math.PI * theta) / 180;
            let dist =
              Math.sin(radlat1) * Math.sin(radlat2) +
              Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;

            if (dist) {
              distance = distance + dist;
            } else {
              distance = distance + 0;
            }
            routeData[index].totalDistance = distance;
            routeData[index].position = c;
            routeDataFinal.push(routeData[index]);
          }

          let marker = Object.assign({}, this.state.markers[this.props.id]);
          marker.position = LastRoutePoint;

          this.setState({
            polylines: routeDataFinal,
            routeDataFinalArray: routeDataFinal,
            isAnimating: false,
            isAnimationRunning: false,
            isAnimationRunningStop: false,
            totalDistance:
              routeDataFinal[routeDataFinal.length - 1].totalDistance,
            startPoint: FirstRoutePoint,
            endPoint: LastRoutePoint,
            position: FirstRoutePoint,
            zoom: 12,
            assetLiveModeCheck: false,
            isRouteDrawing: true,
            markers: [marker],
          });
        }
      }
    }

    if (prevProps.data !== this.props.data) {
      clearInterval(this.intervalId);
      const landMarkAsset = this.props.currentLandMarkAsset;
      if (landMarkAsset) {
        const allAssetList = this.props.data;
        const showSelectedAssetLandMark = allAssetList.filter(
          (ast) => landMarkAsset.assetId === ast.assetId,
        );
        this.setState({
          isAnimating: false,
          isAnimationRunning: false,
          isAnimationRunningStop: false,
          isRouteDrawing: false,
          HideBar: !this.state.HideBar,
          showLandMark: this.props.showLandMark,
          showSelectedAssetLandMark: showSelectedAssetLandMark,
        });
      }

      if (this.props.data.length > 0) {
        const currentPosition = this.props.data[this.props.id].position;
        this.setState({
          position: currentPosition,
          firstTimeZoom: true,
          animateOrRoute: false,
          isAnimationRunning: false,
          isAnimationRunningStop: false,
          isRouteDrawing: false,
          startPoint: [],
          endPoint: [],
          totalDistance: 0,
        });
        if (
          JSON.stringify(prevState.position) !== JSON.stringify(currentPosition)
        ) {
          this.setState({
            livePolyline: [...this.state.livePolyline, currentPosition],
          });
        }
      } else if (this.props.data.length === 0) {
        this.setState({
          firstTimeZoom: false,
          animateOrRoute: false,
          isAnimationRunning: false,
          isAnimationRunningStop: false,
          isRouteDrawing: false,
          startPoint: [],
          endPoint: [],
          livePolyline: [],
        });
      }

      this.setState({
        startPoint: [],
        endPoint: [],
      });

      let markerPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.props.data);
        }, 1500);
      });

      markerPromise.then((markerInfo) => {
        let bounds = [];
        for (let i in markerInfo) {
          if (
            markerInfo[i].position[0] !== 0 ||
            markerInfo[i].position[1] !== 0
          ) {
            bounds.push(markerInfo[i].position);
          }
        }

        if (bounds.length === 0) {
          this.setState({
            markers: markerInfo,
            polylines: [
              [0, 0],
              [0, 0],
            ],
            animateData: [],
            routeDataFinal: [],
          });
        } else {
          this.setState({
            markers: markerInfo,
            polylines: [
              [0, 0],
              [0, 0],
            ],
            animateData: [],
            routeDataFinal: [],
          });
        }
      });
    }

    if (prevProps.showLandMark !== this.props.showLandMark) {
      this.setState({
        showLandMark: false,
        position: this.props.data[this.props.id].position,
      });
    }
  }

  playAnimation = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
    this.intervalTimer = 1000;
    this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
  };

  runAnimation = () => {
    this.setState((prevState) => {
      const animateDataFinal = this.state.animateDataFinal;
      const animationIndex = prevState.animationIndex;
      if (animationIndex < animateDataFinal.length) {
        const cloneData = Array.from(animateDataFinal);
        const position = cloneData[animationIndex].position[1];
        let markers = Object.assign({}, cloneData[animationIndex]);
        markers.position = position;
        const markerIndex = prevState.markers.length > 1 ? this.props.id : 0;
        const current_markers = [
          Object.assign(prevState.markers[markerIndex], markers),
        ];
        return {
          polylines: animateDataFinal,
          markers: current_markers,
          mapBounds: [animateDataFinal[animationIndex].position],
          animationIndex: animationIndex + 1,
          icon: 'pause',
          isAnimationRunning: true,
          isAnimationRunningStop: false,
          position: position,
        };
      } else {
        clearInterval(this.intervalId);
        alertMessage('Animation Finished');
        return {
          isAnimationRunning: false,
          isAnimationRunningStop: true,
          animationIndex: 0,
          icon: 'play',
          content: 'Play',
        };
      }
    });
  };

  animationOnOffButton = () => {
    if (this.state.isAnimationRunning) {
      clearInterval(this.intervalId);
      this.setState({
        isAnimationRunning: false,
        isAnimationRunningStop: true,
        icon: 'play',
        content: 'Play',
      });
    } else {
      this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
      this.setState({
        icon: 'pause',
        content: 'Pause',
        isAnimationRunningStop: false,
        isAnimationRunning: true,
      });
    }
  };

  animationSkipToEnd = () => {
    clearInterval(this.intervalId);
    this.intervalTimer = 1000;
    this.setState({ animationIndex: this.state.animateDataFinal.length - 2 });
    this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
  };

  animationSlowerBtn = () => {
    clearInterval(this.intervalId);
    this.intervalTimer = 1000;
    this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
    this.setState({
      slowerActive: true,
      fasterActive: false,
      normalActive: false,
    });
  };

  animationNormalBtn = () => {
    clearInterval(this.intervalId);
    this.intervalTimer = 600;
    this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
    this.setState({
      slowerActive: false,
      fasterActive: false,
      normalActive: true,
    });
  };

  animationFasterBtn = () => {
    clearInterval(this.intervalId);
    this.intervalTimer = 300;
    this.intervalId = setInterval(this.runAnimation, this.intervalTimer);
    this.setState({
      slowerActive: false,
      fasterActive: true,
      normalActive: false,
    });
  };

  assetLiveMode = () => {
    let routetest = true;
    this.props.makeLiveModeOn(routetest);
  };

  markerClick(id) {
    this.setState({ totalDistance: 0 });
    this.props.sendToDetails(id);
    this.refs.leaflet.leafletElement.setZoom(13);
  }

  circleBound = (value) => {
    if (value !== null) {
      let Xc = value.info.position[0],
        Yc = value.info.position[1];

      this.setState({
        mapBounds: [
          [Xc, Yc],
          [Xc, Yc + 0.03],
          [Xc, Yc - 0.03],
          [Xc - 0.03, Yc],
          [Xc + 0.03, Yc],
        ],
      });
    }
  };

  liveModeOn = () => {
    this.setState({
      assetLiveMode: false,
      polylines: [],
      animateDataFinal: [],
    });
    this.props.passRouteData(this.state.assetLiveMode);
  };

  render() {
    let tDistance;
    let SimpleAreaChart;
    let polyLineComponet = [];
    let rotatedMarkerList = [];
    let { position, markers, polylines, totalDistance, showLandMark, bounds } =
      this.state;

    if (totalDistance) {
      tDistance = totalDistance.toFixed(2);
    }

    if (this.state.isAnimationRunning || this.state.isAnimationRunningStop) {
      let chartArr = [],
        speedPara = {};
      // let fuel;
      // let lastFuel = null;
      for (let index = 0; index < polylines.length; index++) {
        speedPara = {
          Speed: polylines[index].speed,
          Time: polylines[index].time,
        };
        // fuel = parseInt(polylines[index].litter);
        // if (fuel) {
        //   speedPara.Fuel = fuel;
        //   lastFuel = fuel;
        // } else {
        //   speedPara.Fuel = lastFuel;
        // }
        chartArr.push(speedPara);
        speedPara = {};
        polyLineComponet.push(
          <Polyline
            key={index}
            color={polylines[index].color}
            weight='4'
            positions={polylines[index].position}
            smoothFactor={1}
          >
            <Tooltip sticky>
              <span>
                Speed: {polylines[index].speed} km/h <br />
                Distance:{' '}
                {polylines[index].totalDistance
                  ? polylines[index].totalDistance.toFixed(2)
                  : 0}{' '}
                km <br />
                {polylines[index].time}
              </span>
            </Tooltip>
          </Polyline>,
        );
      }
    }

    if (this.state.isRouteDrawing) {
      let chartArr = [];
      let speedPara = {};
      // let lastFuel = null;
      let lastTemperature = null;
      // let fuel;
      let temperature;

      polyLineComponet = [];
      rotatedMarkerList = [];

      let arrowIcon = (color) =>
        divIcon({
          html: `
    <svg width="12px" height="16px" viewBox="0 0 39 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="navigation_solid" transform="translate(20.000000, 36.000000) rotate(-45.000000) translate(-20.000000, -36.000000) translate(-6.000000, 10.000000)" fill="${color}" fill-rule="nonzero">
                <path d="M51.353,0.914 C51.058,0.609 50.603,0.524 50.218,0.701 L0.583,23.481 C0.184,23.665 -0.049,24.086 0.009,24.522 C0.067,24.958 0.402,25.304 0.835,25.376 L23.098,29.107 L25.643,50.145 C25.697,50.583 26.032,50.936 26.467,51.01 C26.524,51.02 26.58,51.025 26.636,51.025 C27.011,51.025 27.362,50.814 27.532,50.469 L51.532,2.054 C51.72,1.675 51.648,1.218 51.353,0.914 Z" id="Shape"></path>
            </g>
        </g>
    </svg>`,
          className: 'arrow-direction-icon',
        });

      for (let index = 0; index < polylines.length; index++) {
        speedPara = {
          Speed: polylines[index].speed,
          Time: polylines[index].time,
        };

        // fuel = parseInt(polylines[index].litter);
        // if (fuel) {
        //   speedPara.Fuel = fuel;
        //   lastFuel = fuel;
        // } else {
        //   speedPara.Fuel = lastFuel;
        // }

        temperature = polylines[index].temperature;
        if (temperature) {
          speedPara.Temperature = temperature;
          lastTemperature = temperature;
        } else {
          speedPara.Temperature = lastTemperature;
        }

        chartArr.push(speedPara);
        speedPara = {};

        polyLineComponet.push(
          <Polyline
            key={index}
            color={polylines[index].color}
            weight='4'
            positions={polylines[index].position}
            smoothFactor={1}
          >
            <Tooltip sticky>
              <span>
                Speed: {polylines[index].speed} km/h <br />
                Distance:{' '}
                {polylines[index].totalDistance
                  ? polylines[index].totalDistance.toFixed(2)
                  : 0}{' '}
                km <br />
                {polylines[index].time}
              </span>
            </Tooltip>
          </Polyline>,
        );

        if (index % 3 === 0) {
          rotatedMarkerList.push(
            <RotatedMarker
              key={index}
              position={polylines[index].position[0]}
              icon={arrowIcon(polylines[index].color)}
              rotationAngle={polylines[index].bearing}
              rotationOrigin='center'
            >
              <Tooltip sticky>
                <span>
                  Speed: {polylines[index].speed} km/h <br />
                  Distance: {polylines[index].totalDistance.toFixed(2)} km{' '}
                  <br />
                  {polylines[index].time}
                </span>
              </Tooltip>
            </RotatedMarker>,
          );
        }
      }

      SimpleAreaChart = (
        <ResponsiveContainer
          // margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
          height={180}
        >
          <ComposedChart data={chartArr}>
            <CartesianGrid stroke='#ced4da' />
            <XAxis dataKey='Time' />
            <YAxis />
            <TooltipChart />
            <Legend />
            <Area
              type='monotone'
              dataKey='Temperature'
              stroke='#ef5675'
              fill='#ef5675'
              dot={false}
              isAnimationActive={true}
              fillOpacity={1}
            />
            {/* <Area
              type='monotone'
              dataKey='Fuel'
              stroke='#ffa600'
              fill='#ffa600'
              dot={false}
              isAnimationActive={true}
            /> */}
            <Area
              type='monotone'
              dataKey='Speed'
              fill='#2f4b7c'
              stroke='#2f4b7c'
              dot={false}
              isAnimationActive={true}
            />
          </ComposedChart>
        </ResponsiveContainer>
      );
    }

    const icon = (carbearing, asset, speed, acc_connected, engine_sensor) => {
      let iconName = '';
      let colorRing = '';

      let width = 15;
      if (
        asset.substring(0, 3) === 'tru' ||
        asset === 'pickup' ||
        asset.substring(0, 3) === 'cva'
      ) {
        iconName = 'truck';
      } else if (
        asset.substring(0, 3) === 'mic' ||
        asset.substring(0, 3) === 'bus'
      ) {
        iconName = 'bus';
      } else if (asset.substring(0, 3) === 'mot') {
        iconName = 'bike';
      } else if (asset.substring(0, 3) === 'shi') {
        iconName = 'ship';
      } else if (asset.substring(0, 3) === 'cng') {
        iconName = 'cng';
        width = 20;
      } else if (asset === 'car' || asset === 'jeep' || asset === 'microbus') {
        iconName = 'car';
      } else {
        iconName = 'other_navigate';
        width = 20;
      }

      if (acc_connected === 'True' && engine_sensor !== 'True') {
        colorRing = 'gps-ring-border-stop';
      } else {
        if (speed >= 110) {
          colorRing = 'gps-ring-border-110km';
        } else if (speed >= 80) {
          colorRing = 'gps-ring-border-80km';
        } else if (speed >= 60) {
          colorRing = 'gps-ring-border-60km';
        } else if (speed >= 25) {
          colorRing = 'gps-ring-border-25km';
        } else if (speed >= 5) {
          colorRing = 'gps-ring-border-5km';
        } else {
          colorRing = 'gps-ring-border-stop';
        }
      }

      let htmlIcon;
      if (this.state.animateOrRoute) {
        htmlIcon = `
        <div class="gps-ring-box" style="transform: rotate(${carbearing}deg);">
          <img src="images/${iconName}.png"  width="${width}px"/>
        </div>
       `;
      } else {
        htmlIcon = `
        <div class="gps-ring-box">
          <img src="images/${iconName}.png"  width="${width}px" style="transform: rotate(${carbearing}deg);"/>
          <div class="gps-ring-animate ${colorRing}"></div>
        </div>
       `;
      }

      let iconObj = {
        className: 'my-div-icon animated-icon',
        iconSize: [10, 10],
        iconAnchor: [10, 25],
        popupAnchor: [10, 12],
        shadowSize: [155, 150],
        html: htmlIcon,
      };
      return divIcon(iconObj);
    };

    const AssetIconMarker = ({
      id,
      map,
      position,
      children,
      bearing,
      asset_type,
      speed,
      name,
      engine_sensor,
      acc_connected,
      crownData,
    }) => {
      const carSpeed = Math.round(speed || children[0].speed || 0);
      const carId = name || children[1].name;
      let color;

      if (isCrown() && crownData.trip_status) {
        const status = crownData.trip_status.toLowerCase();
        switch (status) {
          case 'up':
            color = '#008000';
            break;
          case 'down':
            color = '#ff0000';
            // ffff00 - yellow
            break;
          default:
            break;
        }
      }

      return (
        <div>
          <Marker
            map={map}
            position={position}
            icon={icon(
              bearing,
              asset_type,
              carSpeed,
              acc_connected,
              engine_sensor,
            )}
            onClick={this.markerClick.bind(this, id)}
          >
            <Tooltip
              className='dark-tooltip'
              direction='top'
              permanent={
                this.state.isAnimationRunning ||
                this.state.isAnimationRunningStop ||
                this.state.isRouteDrawing
                  ? false
                  : true
              }
              offset={[0, -20]}
            >
              <div className='center-align-tooltip'>
                {color ? (
                  <div
                    className='crown-dot'
                    style={{ backgroundColor: color }}
                  />
                ) : null}
                <div>{carId}</div>
              </div>
            </Tooltip>
          </Marker>
        </div>
      );
    };

    const AssetIcon = ({ map, markers }) => {
      const items = markers.map(({ key, ...props }, id) => {
        return <AssetIconMarker key={id} id={id} map={map} {...props} />;
      });
      return <div style={{ display: 'none' }}>{items}</div>;
    };

    let startIcon = Leaflet.icon({
      iconUrl: '/images/start_marker.png',
      iconSize: [40, 60],
    });

    let stopIcon = Leaflet.icon({
      iconUrl: '/images/end_marker.png',
      iconSize: [40, 60],
    });

    return (
      <div>
        {this.state.isRouteDrawing && this.state.HideBar ? (
          <div
            style={{
              backgroundColor: 'rgb(236, 236, 236)',
            }}
            className='speedChart'
          >
            <div style={{ position: 'relative' }}>
              <Button
                onClick={() =>
                  this.setState({ showChart: !this.state.showChart })
                }
                size='mini'
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '-30px',
                }}
                icon
              >
                <Icon
                  name={`angle ${this.state.showChart ? 'down' : 'up'} `}
                  style={{ fontSize: 15 }}
                />
              </Button>
              {this.state.showChart ? SimpleAreaChart : null}
            </div>
          </div>
        ) : null}
        <Map
          id='map-box'
          ref='leaflet'
          bounds={bounds}
          center={position}
          // className="markercluster-map"
          animate={true}
        >
          <MapLayers />

          {polyLineComponet}
          {rotatedMarkerList}

          {this.props.currentAsset !== null ? (
            <Circle
              center={this.props.currentAsset.info.position}
              radius={this.props.currentAsset.radius * 1000}
            />
          ) : null}

          <MarkerClusterGroup>
            <AssetIcon markers={markers} />
          </MarkerClusterGroup>

          {showLandMark ? (
            <LandMarkPopUp landmarks={this.props.landmarks} />
          ) : null}

          {typeof this.state.startPoint !== 'undefined' &&
          this.state.startPoint.length > 0 ? (
            <Marker icon={startIcon} position={this.state.startPoint} />
          ) : null}

          {typeof this.state.endPoint !== 'undefined' &&
          this.state.endPoint.length > 0 ? (
            <Marker icon={stopIcon} position={this.state.endPoint} />
          ) : null}

          {this.state.livePolyline.length > 1 && (
            <Polyline color='#38d9a9' positions={this.state.livePolyline} />
          )}

          {this.state.livePolyline.length > 0 && (
            <Marker icon={startIcon} position={this.state.livePolyline[0]} />
          )}
        </Map>
        {(this.state.isAnimationRunning || this.state.isAnimationRunningStop) &&
        this.state.HideBar ? (
          <div className='mapOverLay'>
            {/* <Grid relaxed columns={4}> */}
            <div className='mapOverLay-info'>
              <div>
                <span
                  style={{
                    color: 'rgba(255,255,255,0.9)',
                    fontSize: '16px',
                    margin: '5px 10px',
                  }}
                >
                  Distance: &nbsp;
                  {this.state.markers[0].totalDistance !== undefined
                    ? this.state.markers[0].totalDistance.toFixed(2)
                    : 0}{' '}
                  km
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'rgba(255,255,255,0.9)',
                    fontSize: '16px',
                    margin: '5px 10px',
                  }}
                >
                  Date: &nbsp;
                  {this.state.markers[0].time !== undefined
                    ? this.state.markers[0].time.split(', ')[0]
                    : ''}
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'rgba(255,255,255,0.9)',
                    fontSize: '16px',
                    margin: '5px 10px',
                  }}
                >
                  Time: &nbsp;
                  {this.state.markers[0].time !== undefined
                    ? this.state.markers[0].time.split(', ')[1]
                    : ''}
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'rgba(255,255,255,0.9)',
                    fontSize: '16px',
                    margin: '5px 10px',
                  }}
                >
                  Speed: &nbsp;
                  {this.state.markers[0].speed !== undefined
                    ? `${this.state.markers[0].speed} km/h`
                    : ''}
                </span>
              </div>
              {/* {this.state.markers[0].fuel_connected && (
                <div>
                  <span
                    style={{
                      color: 'rgba(255,255,255,0.9)',
                      fontSize: '16px',
                      margin: '5px 10px'
                    }}
                  >
                    Fuel: &nbsp;
                    {this.state.markers[0].litter !== undefined ||
                    isNaN(this.state.markers[0].litter)
                      ? `${this.state.markers[0].litter} L`
                      : ''}
                  </span>
                </div>
              )} */}
            </div>
            <div style={{ margin: '5px 10px' }}>
              <Button.Group>
                <Button
                  size='tiny'
                  icon={this.state.icon}
                  onClick={this.animationOnOffButton}
                  secondary
                  style={{ position: 'static' }}
                />

                <Button
                  size='tiny'
                  content='Slower'
                  onClick={this.animationSlowerBtn}
                  active={this.state.slowerActive}
                />
                <Button
                  size='tiny'
                  content='Normal'
                  onClick={this.animationNormalBtn}
                  active={this.state.normalActive}
                />

                <Button
                  size='tiny'
                  content='Faster'
                  onClick={this.animationFasterBtn}
                  active={this.state.fasterActive}
                />
                <Button
                  size='tiny'
                  icon='stop'
                  onClick={this.animationSkipToEnd}
                  negative
                />
              </Button.Group>
            </div>
            {/* </Grid> */}
          </div>
        ) : null}
        {this.props.data.length > 0 && (
          <div className='SpeedMeter'>
            <SpeedMeter distance={tDistance} />
          </div>
        )}
      </div>
    );
  }
}

export default Maps;

import axios from 'axios';
import { REGULAR_PATH } from '../../env';

export function refreshToken(url) {
  const postInfo = {
    access_token: localStorage.getItem('user'),
    refresh_token: localStorage.getItem('refresh'),
  };
  return axios
    .post(`${REGULAR_PATH}/auth/refresh_token`, JSON.stringify(postInfo))
    .then((res) => {
      return axios({
        url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('user')}`,
        },
      });
    });
}

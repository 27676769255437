import React from 'react';
import { Button, Loader, Table, Search } from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH, REGULAR_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');

class List extends React.Component {
  state = {
    loading: true,
    data: [],
    search: '',
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${REGULAR_PATH}/staff/org_members?org_oid=5efd6d101d41c855060b1632&access_token=${token()}`,
      )
      .then(({ data }) => {
        const userOids = data.map(({ _id }) => _id.$oid);

        return axios.post(
          `${BASE_PATH}/v1.2/reports/get_runners_employee?access_token=${token()}`,
          JSON.stringify({
            user_oids: userOids,
          }),
        );
      })
      .then((response) => {
        this.setState({
          loading: false,
          data: response.data.filter((item) => item),
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  handleSearch = (_, data) => {
    this.setState({ search: data.value.toLowerCase() });
  };

  render() {
    const { loading, data, search } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div>
        <Search
          onSearchChange={this.handleSearch}
          value={search}
          placeholder='Search Here'
          showNoResults={false}
          style={{ width: '300px' }}
        />

        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell>User ID</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data
              .filter(({ employee_name }) =>
                employee_name.toLowerCase().includes(search),
              )
              .map(
                (
                  {
                    _id,
                    user_oid,
                    employee_name,
                    employee_unit,
                    employee_id,
                    user_id,
                  },
                  index,
                ) => (
                  <Table.Row key={_id.$oid}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{employee_name}</Table.Cell>
                    <Table.Cell>{employee_id}</Table.Cell>
                    <Table.Cell>{employee_unit}</Table.Cell>
                    <Table.Cell>{user_id}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() =>
                          this.props.selectUserIdForEdit(user_oid.$oid)
                        }
                      >
                        Edit
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default List;

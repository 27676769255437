import React from 'react';

const bar = {
  color: '#f3f3f3',
  backgroundColor: '#001529',
  fontSize: '10px',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 5,
  display: 'flex',
  overflowX: 'auto',
};

const barItem = {
  padding: '6px 10px',
  borderRight: '1px solid #777',
  cursor: 'pointer',
  textAlign: 'center',
  letterSpacing: '1px',
};

const scrollIntoId = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView();
  window.scroll(0, window.scrollY - 60);
};

const selectAssetInReport = (props) => {
  return (
    <div style={bar}>
      {props.assetList.map((item) => {
        let itemName;
        if (item.hasOwnProperty('asset_detail')) {
          itemName = item.asset_detail.name;
        } else {
          itemName = item.asset.name;
        }
        return (
          <div
            onClick={() => scrollIntoId(itemName)}
            key={itemName}
            style={barItem}
          >
            {itemName}
          </div>
        );
      })}
    </div>
  );
};

export default selectAssetInReport;

import React from 'react';
import { ToastContainer } from 'react-toastify';

class LoginFlow extends React.Component {
  render() {
    return (
      <div className='container login-flow'>
        <div>
          {this.props.children}
          <ToastContainer
            style={{ bottom: '4rem' }}
            autoClose={3000}
            position='bottom-center'
          />
        </div>
      </div>
    );
  }
}

export default LoginFlow;

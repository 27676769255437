import React from 'react';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { getIdsAndTime, getDuration } from '../helper/helper';
import { digitalInputValueFormat } from '../../../helper/digital-input-value-format';
import { REGULAR_PATH } from '../../../../env';
import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import { reportView, reportDownloadExcel } from '../helper/dataService';

import reportsEnv from '../reportsEnv';
import { getData, handleMultipleRequest } from '../../../helper/dataService';

const { durationLimit, assetLimit } = reportsEnv.digitalInput;

class DigitalIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      digitalInput1: false,
      digitalInput2: false,
      digitalInput3: false,
      assetsWithDigitalInput: [],
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    let promises = [];

    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      let url = `${REGULAR_PATH}/assets/${id}/last_data?access_token=`;

      promises.push(getData(url));
    }

    let lastDataResponse = handleMultipleRequest(promises);

    lastDataResponse
      .then((results) => {
        results.forEach((res) => {
          if (
            res.data.digital_input_sensor_1_name ||
            res.data.digital_input_sensor_2_name ||
            res.data.digital_input_sensor_3_name
          ) {
            const asset = {
              name: res.data.name,
              digitalInput1Name: res.data.digital_input_sensor_1_name || '',
              digitalInput2Name: res.data.digital_input_sensor_2_name || '',
              digitalInput3Name: res.data.digital_input_sensor_3_name || '',
              digitalInput1Reversed:
                res.data.digital_input_sensor_1_reversed === 'True'
                  ? true
                  : false,
              digitalInput2Reversed:
                res.data.digital_input_sensor_2_reversed === 'True'
                  ? true
                  : false,
              digitalInput3Reversed:
                res.data.digital_input_sensor_3_reversed === 'True'
                  ? true
                  : false,
            };

            this.setState((prevState) => ({
              assetsWithDigitalInput: [...prevState, asset],
            }));
          }
        });
      })
      .catch((error) => {});

    reportView(reportsEnv.digitalInput, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        const { data } = response;

        const temp = [...data];

        const { assetsWithDigitalInput } = this.state;

        assetsWithDigitalInput.forEach((asset) => {
          const index = temp.findIndex(
            (d) => d.asset_detail.name === asset.name,
          );
          if (index > -1) {
            temp[index].digitalInput1Name = asset.digitalInput1Name;
            temp[index].digitalInput2Name = asset.digitalInput2Name;
            temp[index].digitalInput3Name = asset.digitalInput3Name;
            temp[index].digitalInput1Reversed = asset.digitalInput1Reversed;
            temp[index].digitalInput2Reversed = asset.digitalInput2Reversed;
            temp[index].digitalInput3Reversed = asset.digitalInput3Reversed;
          }
        });
        this.setState({ assetDataFetched: false });

        data.forEach((asset) => {
          asset.data.forEach((item) => {
            const { digital_input_1, digital_input_2, digital_input_3 } = item;

            if (digital_input_1) {
              this.setState({ digitalInput1: true });
            }
            if (digital_input_2) {
              this.setState({ digitalInput2: true });
            }
            if (digital_input_3) {
              this.setState({ digitalInput3: true });
            }
          });
        });

        this.setState({
          data: temp,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(reportsEnv.digitalInput, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Digital_Indicator_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      digitalInput1,
      digitalInput2,
      digitalInput3,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Digital Indicator [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Digital Indicator</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />

            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h2 className='ui center aligned icon header'>
                          <i className='circular car icon' />
                        </h2>
                        {!item.data.some(
                          (data) =>
                            data.digital_input_1 ||
                            data.digital_input_2 ||
                            data.digital_input_3,
                        ) ? (
                          <h4
                            style={{
                              textAlign: 'center',
                              fontSize: 20,
                            }}
                          >
                            Digital Indicator Sensor Not Connected
                          </h4>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {item.data.some(
                  (data) =>
                    data.digital_input_1 ||
                    data.digital_input_2 ||
                    data.digital_input_3,
                ) ? (
                  <div>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Time</Table.HeaderCell>
                          {digitalInput1 ? (
                            <Table.HeaderCell>
                              {item.digitalInput1Name || 'Digital Indicator 1'}
                            </Table.HeaderCell>
                          ) : null}
                          {digitalInput2 ? (
                            <Table.HeaderCell>
                              {item.digitalInput2Name || 'Digital Indicator 2'}
                            </Table.HeaderCell>
                          ) : null}
                          {digitalInput3 ? (
                            <Table.HeaderCell>
                              {item.digitalInput3Name || 'Digital Indicator 3'}
                            </Table.HeaderCell>
                          ) : null}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {item.data.map((item, id) => {
                          if (
                            item.digital_input_1 ||
                            item.digital_input_2 ||
                            item.digital_input_3
                          ) {
                            return (
                              <Table.Row key={id}>
                                <Table.Cell>
                                  {item.time
                                    ? moment(item.time.$date).format(
                                        'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                      )
                                    : ''}
                                </Table.Cell>
                                {digitalInput1 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      data[i].digitalInput1Name,
                                      item.digital_input_1,
                                      data[i].digitalInput1Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                                {digitalInput2 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      data[i].digitalInput2Name,
                                      item.digital_input_2,
                                      data[i].digitalInput2Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                                {digitalInput3 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      data[i].digitalInput3Name,
                                      item.digital_input_3,
                                      data[i].digitalInput3Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                              </Table.Row>
                            );
                          }

                          return null;
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                ) : null}

                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default DigitalIndicator;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import { Button, Input, Message, Dropdown } from 'semantic-ui-react';
import { postData } from './../../helper/dataService.js';
import 'react-toastify/dist/ReactToastify.min.css';
import { REGULAR_PATH } from '../../../env';

@inject('user')
@observer
class SignUpForm extends React.Component {
  static defaultProps = {
    postInfo: {
      client_id: 'finder_web',
      client_secret: 'f1nd3rw3b',
      grant_type: 'client_credentials',
      scope: '',
      timezone: new Date().getTimezoneOffset(),
    },
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      name: '',
      email: '',
      mobile: '',
      password: '',
      confirm: '',
      color: 'ui message info',
      errorMessage: '',
      loading: false,
      mobileCountryCode: '+880',
      regexForMobile: '^1[3-9][0-9]{8}$',
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  makeToast(item) {
    toast(item + ' have been succesfully registered.', {
      type: toast.TYPE.SUCCESS,
    });
  }

  registerUser = (postInfo, loginProcess) => {
    var response = postData(
      `${REGULAR_PATH}/auth/${loginProcess}`,
      JSON.stringify(postInfo),
    );
    response
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.access_token !== undefined) {
          this.props.user.changeToken(
            res.data.access_token,
            res.data.refresh_token,
            true,
          );
          window.localStorage.setItem('user', res.data.access_token);
          this.props.history.push('/');
        } else {
          if (res.status === 200) {
            this.setState({
              errorMessage: `This ${res.data.status} already, please register with another.`,
              color: 'ui message error',
            });
          } else if (res.status === 201) {
            this.makeToast(this.state.name);
            this.setState({
              email: '',
              name: '',
              mobile: '',
              password: '',
              confirm: '',
            });
            this.props.history.push('/login');
          }
        }
      })
      .catch((e) => {
        // (e);
      });
  };

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.state.email === '' && this.state.mobile === '') {
      this.setState({
        color: 'ui message error',
        errorMessage: 'Please provide either email or mobile no',
        loading: false,
      });
    } else if (this.state.password !== this.state.confirm) {
      this.setState({
        color: 'ui message error',
        errorMessage: 'Password does not match',
        loading: false,
      });
    } else {
      var postInfo = {
        name: this.state.name,
        email: this.state.email,
        mobile: `${this.state.mobileCountryCode}${this.state.mobile}`,
        password: this.state.password,
      };
      this.registerUser(postInfo, 'registration');
    }
  }

  onChangeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  onChangeMobile = (e) => {
    const value = e.target.value;
    this.setState({
      mobile: value,
    });
  };
  onChangeMobileCountryCode = (e, { value }) => {
    const regex = {
      '+880': '^1[3-9][0-9]{8}$',
      '+977': '^9[6-8][0-9]{8}$',
    };
    this.setState({
      mobileCountryCode: value,
      regexForMobile: regex[value],
    });
  };
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  onChangeConfirm = (e) => {
    this.setState({
      confirm: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <div>
          <Link to='/'>
            <img
              className='logo'
              src='/images/Logo-1.png'
              width='300'
              alt='Finder logo'
            />
          </Link>
          <br />
          <br />
        </div>
        <form onSubmit={this.onSubmit} className='ui  large form'>
          <div
            className='ui stacked segment'
            style={{ paddingBottom: '2em', borderRadius: '10px' }}
          >
            <br />
            <h2 style={{ color: 'black' }}>SIGN UP</h2>
            <div className='field'>
              <div className='ui left icon input'>
                <i className='user icon' />
                <input
                  required
                  maxLength={50}
                  value={this.state.name}
                  onChange={this.onChangeName}
                  type='text'
                  name='name'
                  placeholder='Name'
                />
              </div>
            </div>
            <div className='field'>
              <div className='ui left icon input'>
                <i className='mail icon' />
                <input
                  required
                  type='text'
                  pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  name='email'
                  placeholder='Email'
                />
              </div>
            </div>

            <div className='field'>
              <Input
                required
                type='text'
                pattern={this.state.regexForMobile}
                value={this.state.mobile}
                onChange={this.onChangeMobile}
                name='mobile'
                placeholder='Mobile Number'
                label={
                  <Dropdown
                    onChange={this.onChangeMobileCountryCode}
                    value={this.state.mobileCountryCode}
                    options={[
                      { key: 'bd', text: '+880', value: '+880' },
                      { key: 'np', text: '+977', value: '+977' },
                    ]}
                  />
                }
                labelPosition='left'
              />
            </div>

            <div className='field'>
              <div className='ui left icon input'>
                <i className='lock icon' />
                <input
                  required
                  type='password'
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  name='password'
                  minLength={6}
                  placeholder='Password'
                />
              </div>
            </div>

            <div className='field'>
              <div className='ui left icon input'>
                <i className='lock icon' />
                <input
                  required
                  type='password'
                  value={this.state.confirm}
                  onChange={this.onChangeConfirm}
                  name='confirm'
                  placeholder='Confirm Password'
                />
              </div>
            </div>
            <p style={{ color: 'black', margin: '20px 0' }}>
              By signing up, you agree to our{' '}
              <a
                href='https://www.finder-lbs.com/terms'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href='https://www.finder-lbs.com/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>
              .
            </p>

            {this.state.errorMessage !== '' ? (
              <Message negative>{this.state.errorMessage}</Message>
            ) : null}

            <div className='field'>
              <Button
                color='teal'
                fluid
                loading={this.state.loading}
                style={{ fontSize: '16px' }}
              >
                SIGN UP
              </Button>
            </div>

            <br />
            <div style={{ fontSize: '18px', color: 'black' }}>
              <b>Already a user?</b>
              <span>
                <Link
                  to='/login'
                  style={{
                    color: '#07c3c3',
                    marginLeft: '5px',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                  }}
                >
                  Login
                </Link>
              </span>
            </div>
          </div>
        </form>
        <div className='ui grid' />
      </div>
    );
  }
}

export default withRouter(SignUpForm);

import React from 'react';
import {
  Loader,
  Icon,
  Table,
  Button,
  Header,
  Label,
  Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportDownloadExcel,
  reportDownloadPdf,
  reportView,
} from '../helper/dataService';
import { getIdsAndTime, getDuration, getOrg } from '../helper/helper';
import SelectAssetInReport from '../SelectAssetInReport';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.location;

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      data: [],
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      isCoordinates: false,
      isLandmark: false,
      interval: 0,
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { isCoordinates, isLandmark, interval } = this.state;

    reportView(reportsEnv.location, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      org_oid: getOrg(),
      is_coordinates: isCoordinates,
      is_landmark: isLandmark,
      interval: interval,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { isCoordinates, isLandmark, interval } = this.state;

    reportDownloadPdf(reportsEnv.location, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      org_oid: getOrg(),
      is_coordinates: isCoordinates,
      is_landmark: isLandmark,
      interval: interval,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { isCoordinates, isLandmark, interval } = this.state;

    reportDownloadExcel(reportsEnv.location, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      org_oid: getOrg(),
      is_coordinates: isCoordinates,
      is_landmark: isLandmark,
      interval: interval,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleCheck = (e, data) => {
    const { name, checked } = data;
    this.setState({ [name]: checked });
  };

  onIntervalSelect = (e) => {
    this.setState({
      interval: parseInt(e.target.value),
    });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      isCoordinates,
      isLandmark,
      // interval,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Location [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className='center-report'>
            <div>
              <Header as='h2'>{title}</Header>
            </div>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            <div style={{ margin: '10px 0 20px 0' }}>
              {/* <select
                className='select-semantic'
                onChange={this.onIntervalSelect}
                value={interval}
                name='interval'
              >
                <option value='0'>0 Minutes</option>
                <option value='10'>10 Minutes</option>
                <option value='20'>20 Minutes</option>
                <option value='30'>30 Minutes</option>
                <option value='60'>60 Minutes</option>
                <option value='90'>90 Minutes</option>
                <option value='120'>120 Minutes</option>
                <option value='150'>150 Minutes</option>
                <option value='180'>180 Minutes</option>
              </select> */}

              <Checkbox
                label='With Coordinates?'
                onClick={this.handleCheck}
                checked={isCoordinates}
                name='isCoordinates'
                style={{ margin: '0 10px' }}
              />

              <Checkbox
                label='With Landmark?'
                onClick={this.handleCheck}
                checked={isLandmark}
                name='isLandmark'
                style={{ margin: '0 10px' }}
              />
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                {/* <Button
                    color='teal'
                    style={{ width: '200px', fontSize: '16px' }}
                    icon='file pdf outline'
                    content='Download PDF'
                    onClick={this.handleDownloadPdfBtn}
                    disabled={pdfDisableBtn || excelDisableBtn}
                    loading={pdfDisableBtn}
                  /> */}

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Location</Header>
                <p>
                  <span>
                    <i className='arrow right icon' />
                    Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    <i className='arrow right icon' />
                    From:{' '}
                    {moment(startTime).format(
                      'dddd, MMMM Do, YYYY, hh:mm:ss A',
                    )}
                  </span>
                  &nbsp;&nbsp;
                  <span>
                    <i className='arrow right icon' />
                    Till:{' '}
                    {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                  </span>
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />
            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <Header as='h4'>
                  <Icon name='car' />
                  {item.asset_detail.name}
                </Header>

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Location Time</Table.HeaderCell>
                      <Table.HeaderCell>Speed (km/h)</Table.HeaderCell>
                      <Table.HeaderCell>GPS Status</Table.HeaderCell>
                      {isCoordinates ? (
                        <Table.HeaderCell>Coordinates</Table.HeaderCell>
                      ) : null}
                      {isLandmark ? (
                        <Table.HeaderCell>Nearest Landmark</Table.HeaderCell>
                      ) : null}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {item.data.map(
                      (
                        {
                          location_time,
                          speed,
                          gps_status,
                          coordinates = [],
                          nlm = [],
                        },
                        idx,
                      ) => (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            {location_time
                              ? moment(location_time.$date).format(
                                  'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                )
                              : ''}
                          </Table.Cell>
                          <Table.Cell>{speed}</Table.Cell>
                          <Table.Cell>{gps_status}</Table.Cell>
                          {isCoordinates ? (
                            <Table.Cell>
                              {coordinates.length ? (
                                <span>
                                  {coordinates[0]}, {coordinates[1]}
                                </span>
                              ) : null}
                            </Table.Cell>
                          ) : null}
                          {isLandmark ? (
                            <Table.Cell>
                              {nlm.length ? (
                                nlm.map((nlm, x) => (
                                  <p key={x}>
                                    <i className='marker icon' />
                                    {nlm.name}
                                  </p>
                                ))
                              ) : (
                                <p>
                                  <i className='marker icon' />
                                  No Data Found
                                </p>
                              )}
                            </Table.Cell>
                          ) : null}
                        </Table.Row>
                      ),
                    )}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default Location;

import React from 'react';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Area,
  Tooltip as TooltipChart,
} from 'recharts';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import { reportDownloadExcel, reportView } from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.haltA;

class HaltA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      selectedAssets: [],
      data: [],
      filteredEngineData: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      graphList: [],
      withLocation: false,
      timeLimit: 10,
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { withLocation, timeLimit } = this.state;
    reportView(reportsEnv.haltA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      location: withLocation,
      limit: timeLimit * 60,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          graphList: response.data.map((asset) => {
            let filteredList = [];
            asset.halt_data.forEach((item) => {
              filteredList.push({
                awake: Math.ceil(item.in_seconds / 60),
                startAt: item.engine_started_at
                  ? moment(item.engine_started_at.$date).format('DD MMM')
                  : null,
              });
            });
            return filteredList;
          }),
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  // handleDownloadPdfBtn = () => {
  //   this.setState({ pdfDisableBtn: true });
  //   const { ids, startTime, endTime } = getIdsAndTime(this.state);
  //   reportDownload(
  //     reportsEnv.halt.pdfPath,
  //     {
  //       asset_ids: ids,
  //       from: startTime,
  //       to: endTime,
  //       location: this.state.withLocation,
  //     },
  //     reportsEnv.halt.newApi
  //   )
  //     .then((response) => {
  //       FileSaver.saveAs(
  //         response.data,
  //         `Finder_Halt_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.pdf`
  //       );
  //       this.setState({ pdfDisableBtn: false });
  //     })
  //     .catch((error) => {
  //       this.setState({ pdfDisableBtn: false });
  //       alertMessage(`Sorry! Something went wrong`);
  //     });
  // };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { withLocation, timeLimit } = this.state;

    reportDownloadExcel(reportsEnv.haltA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      location: withLocation,
      limit: timeLimit * 60,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Halt_A_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  onIntervalSelect = (e) => {
    this.setState({
      timeLimit: parseInt(e.target.value),
    });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      graphList,
      withLocation,
      timeLimit,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Halt [A] [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div
              style={{
                margin: '20px 0',
              }}
            >
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            <select
              className='select-semantic'
              onChange={this.onIntervalSelect}
              value={timeLimit}
              name='interval'
            >
              <option value='0'>0 Minutes</option>
              <option value='10'>10 Minutes</option>
              <option value='20'>20 Minutes</option>
              <option value='30'>30 Minutes</option>
              <option value='60'>60 Minutes</option>
              <option value='90'>90 Minutes</option>
              <option value='120'>120 Minutes</option>
              <option value='150'>150 Minutes</option>
              <option value='180'>180 Minutes</option>
            </select>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file pdf outline'
                  content='Download PDF'
                  onClick={this.handleDownloadPdfBtn}
                  disabled={pdfDisableBtn || excelDisableBtn}
                  loading={pdfDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Halt [A]</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />
            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <ul className='content'>
                        <div className='ui left aligned icon header'>
                          • Total ignition off{' '}
                          {item.asset_detail.total_number_of_ignition || '0'}{' '}
                          times.
                        </div>

                        <div className='ui left aligned icon header'>
                          • Total ignition off time:{' '}
                          {item.asset_detail.total_engine_off_time ||
                            'Not Available'}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>

                {graphList[i].length > 1 ? (
                  <ResponsiveContainer height={200} width='100%'>
                    <ComposedChart data={graphList[i]}>
                      <CartesianGrid stroke='#ced4da' />
                      <XAxis dataKey='startAt' />
                      <YAxis />
                      <TooltipChart />
                      <Legend />
                      <Area
                        type='monotone'
                        dataKey='awake'
                        name='Sleep Time (Minutes)'
                        stroke='#12b886'
                        fill='#12b886'
                        dot={false}
                        isAnimationActive={true}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : null}

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Ignition Stopped Time</Table.HeaderCell>
                      <Table.HeaderCell>Ignition Started Time</Table.HeaderCell>
                      <Table.HeaderCell>
                        Ignition Sleep Duration
                      </Table.HeaderCell>
                      {withLocation ? (
                        <Table.HeaderCell>Location</Table.HeaderCell>
                      ) : null}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {item.halt_data.map((last, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            {last.engine_stopped_at
                              ? moment(last.engine_stopped_at.$date).format(
                                  'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                )
                              : ''}
                          </Table.Cell>
                          <Table.Cell>
                            {last.engine_started_at
                              ? moment(last.engine_started_at.$date).format(
                                  'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                )
                              : ''}
                          </Table.Cell>
                          <Table.Cell>{last.time_difference}</Table.Cell>
                          {withLocation ? (
                            <Table.Cell>{last.stop_loc}</Table.Cell>
                          ) : null}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default HaltA;

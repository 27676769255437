import React from 'react';
import flv from 'flv.js';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.videoRef = null;
  }

  componentDidMount() {
    this.buildPlayer();
  }

  componentDidUpdate() {
    this.buildPlayer();
  }

  componentWillUnmount() {
    console.log('destroy jc400 player');
    this.player.destroy();
  }

  buildPlayer = () => {
    const url = this.props.url;
    if (this.player || !url) {
      return;
    }

    this.player = flv.createPlayer({
      type: 'flv',
      isLive: true,
      url: url,
    });
    this.player.attachMediaElement(this.videoRef);
    this.player.load();
    this.player.play();
  };

  render() {
    return (
      <div>
        <video
          ref={(element) => (this.videoRef = element)}
          style={{ marginTop: '10px', width: '100%', height: '100%' }}
          controls
          muted
        />
      </div>
    );
  }
}

export default VideoPlayer;

import React from 'react';
import { Menu, Segment } from 'semantic-ui-react';

import Add from './Add';
import List from './List';

class OilPoint extends React.Component {
  state = { activeItem: 'List' };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    if (
      !['5634fa61421aa95a9fac17c4'].includes(
        window.localStorage.getItem('organization'),
      )
    ) {
      return (
        <h3 style={{ textAlign: 'center' }}>
          You are not eligible to browse this link.
        </h3>
      );
    }

    const { activeItem } = this.state;
    return (
      <Segment>
        <Menu pointing secondary>
          <Menu.Item
            name='List'
            active={activeItem === 'List'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Add'
            active={activeItem === 'Add'}
            onClick={this.handleItemClick}
          />
        </Menu>

        {activeItem === 'Add' && <Add />}
        {activeItem === 'List' && <List />}
      </Segment>
    );
  }
}

export default OilPoint;

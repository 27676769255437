import React from 'react';
import { Message, Confirm, Statistic, Button } from 'semantic-ui-react';
import { postData } from '../../vms/dataHelper';

class EngineCommand extends React.Component {
  state = {
    assetId: '',
    command: '',
    sendRequest: false,
    sendRequestTimeLeft: 10,
    openConfirm: false,
    message: '',
    messageStatus: '',
    engineLocked: false,
  };

  componentDidMount() {
    const { assetId, engineLocked } = this.props;
    this.setState({ assetId, engineLocked });
  }

  componentDidUpdate(prevProps) {
    if (this.props.assetId !== prevProps.assetId) {
      this.setState({ assetId: this.props.assetId });
    }
    if (this.props.engineLocked !== prevProps.engineLocked) {
      this.setState({
        engineLocked: this.props.engineLocked,
        command: '',
        message: '',
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.requestTimer);
  }

  handleEngineCommand = () => {
    this.setState({ sendRequest: true, openConfirm: false, message: '' });
    this.startExecution();
  };

  startExecution = () => {
    this.requestTimer = setInterval(() => {
      const currentTime = this.state.sendRequestTimeLeft - 1;
      if (currentTime > 0) {
        this.setState({
          sendRequestTimeLeft: currentTime,
        });
      } else {
        clearInterval(this.requestTimer);
        this.nowExecute();
      }
    }, 1000);
  };

  nowExecute = () => {
    this.setState({
      openConfirm: false,
      sendRequest: false,
      sendRequestTimeLeft: 10,
    });
    const { command, assetId } = this.state;
    postData(`assets/command`, {
      asset_oid: assetId,
      command,
    })
      .then(({ data: { status } }) => {
        if (status === 'success') {
          this.setState({
            messageStatus: status,
            message: 'Request Sent. It may take some time to execute.',
          });
        } else {
          this.setState({
            messageStatus: status,
            command: '',
            message: 'Failed. Please try again later.',
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          messageStatus: 'fail',
          message: 'Sorry! something went wrong.',
        });
      });
  };

  clearExecution = () => {
    clearInterval(this.requestTimer);
    this.setState({
      openConfirm: false,
      sendRequest: false,
      sendRequestTimeLeft: 10,
      command: '',
    });
  };

  render() {
    const {
      sendRequest,
      sendRequestTimeLeft,
      openConfirm,
      command,
      messageStatus,
      message,
      engineLocked,
    } = this.state;
    return (
      <div>
        {sendRequest ? (
          <div style={centerAlign}>
            <div
              style={{ fontSize: '12px', color: 'black', textAlign: 'center' }}
            >
              {command === 'engine_lock'
                ? 'Engine will be locked shortly.'
                : null}
              {command === 'engine_unlock'
                ? 'Engine will be unlocked shortly.'
                : null}
              {command === 'engine_lock' ? (
                <div style={{ fontWeight: 'bold' }}>
                  Please cancel if you do not want to lock engine.
                </div>
              ) : null}
            </div>
            <Statistic
              value={sendRequestTimeLeft}
              label='seconds'
              size='mini'
              color='red'
            />
            <div>
              <Button onClick={this.clearExecution} size='mini'>
                Cancel
              </Button>
            </div>
          </div>
        ) : command ? null : (
          <div style={centerAlign}>
            <div>
              {engineLocked ? (
                <div style={centerAlign}>
                  <Button
                    size='mini'
                    onClick={() =>
                      this.setState({
                        openConfirm: true,
                        command: 'engine_unlock',
                      })
                    }
                  >
                    Unlock Engine
                  </Button>
                </div>
              ) : (
                <div style={centerAlign}>
                  <Button
                    size='mini'
                    onClick={() =>
                      this.setState({
                        openConfirm: true,
                        command: 'engine_lock',
                      })
                    }
                  >
                    Lock Engine
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        <Confirm
          open={openConfirm}
          onCancel={() => this.setState({ openConfirm: false, command: '' })}
          onConfirm={this.handleEngineCommand}
          header='Are you sure?'
          content={
            command === 'engine_lock'
              ? 'Engine will be locked.'
              : command === 'engine_unlock'
                ? 'Engine will be unlocked.'
                : null
          }
        />
        {message ? (
          <Message color={messageStatus === 'success' ? 'green' : 'red'}>
            {message}
          </Message>
        ) : null}
      </div>
    );
  }
}

export default EngineCommand;

const centerAlign = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

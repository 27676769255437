import React from 'react';
import { Segment, Breadcrumb } from 'semantic-ui-react';
import CreateGeofence from './CreateGeofence';
import RouteTimeAndAsset from './RouteTimeAndAsset';

class Geofence extends React.Component {
  state = {
    timeAndAssetSubmitted: false,
    assetId: '',
    assetName: '',
    start: '',
    end: '',
  };

  setValues = (assetId, assetName, start, end) => {
    this.setState({
      timeAndAssetSubmitted: true,
      assetId,
      assetName,
      start,
      end,
    });
  };

  render() {
    const { timeAndAssetSubmitted, assetId, assetName, start, end } =
      this.state;

    return (
      <Segment>
        <Breadcrumb size='large'>
          <Breadcrumb.Section
            link
            onClick={() => this.setState({ timeAndAssetSubmitted: false })}
          >
            Time and Asset Selection
          </Breadcrumb.Section>
          {timeAndAssetSubmitted && (
            <span>
              <Breadcrumb.Divider />
              <Breadcrumb.Section link>Create Geofence</Breadcrumb.Section>
            </span>
          )}
        </Breadcrumb>
        <br />
        <br />
        {!timeAndAssetSubmitted && (
          <RouteTimeAndAsset setValues={this.setValues} />
        )}
        {timeAndAssetSubmitted && (
          <CreateGeofence
            assetId={assetId}
            assetName={assetName}
            start={start}
            end={end}
          />
        )}
      </Segment>
    );
  }
}

export default Geofence;

import React, { Component } from 'react';
import {
  Table,
  Pagination,
  Icon,
  Loader,
  Button,
  Popup,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { getData, deleteData } from '../dataHelper';
import moment from 'moment';
import Message from '../Message';

class List extends Component {
  state = {
    isConfirm: false,
    limit: 20,
    completed: true,
    asset_id: '',
    plateNumberList: this.props.plateNumberList,
    plateNumber: '',
    data: [],
    activePage: 1,
    totalPages: 0,
    messageShow: false,
    messageType: '',
    messageInfo: '',
    spareParts: [],
    particular: '',
  };

  componentDidMount() {
    this.fetchData();
    getData(
      `${this.props.org}_dropdown`,
      `key=particulars&drop_down_name=particular_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    ).then((response) => {
      this.setState({
        spareParts: response.data,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.plateNumber !== this.state.plateNumber) {
      this.fetchData();
    }
    if (prevState.activePage !== this.state.activePage) {
      this.fetchData();
    }

    if (prevState.particular !== this.state.particular) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ data: [], loaderActive: true });
    const { activePage, limit, plateNumber, spareParts, particular } =
      this.state;

    const asset_id = plateNumber ? plateNumber : '';

    const parts = spareParts.find((item) => item.key === particular);

    const { key = '', value = '', status = '' } = parts || {};

    getData(
      `${this.props.org}_spare_parts`,
      `page=${
        activePage - 1
      }&limit=${limit}&asset_id=${asset_id}&key=${key}&value=${value}&status=${status}`,
    )
      .then((response) => {
        const data = response.data;
        this.setState({
          data: data.data || [],
          totalPages: Math.ceil(data.total / data.limit),
          loaderActive: false,
        });
      })
      .catch((error) => error);
  };

  handleDelete = (id) => {
    this.setState({ isConfirm: false, data: [] });
    deleteData(`${this.props.org}_spare_parts`, id)
      .then((response) => {
        const { status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            data: [],
          });
          this.fetchData();
        } else if (status === 'error') {
          this.setState({
            messageShow: true,
          });
        }
        setTimeout(() => this.setState({ messageShow: false }), 3000);
      })
      .catch((error) => console.error(error));
  };

  handleConfirm = (confirm) => {
    this.setState({ isConfirm: confirm });
  };

  pagination = (e, data) => {
    this.setState({ activePage: data.activePage });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      data: [],
      loaderActive: true,
    });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ plateNumber: data.value });
  };

  render() {
    const {
      isConfirm,
      plateNumberList,
      plateNumber,
      data,
      activePage,
      totalPages,
      loaderActive,
      messageShow,
      spareParts,
      particular,
    } = this.state;

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 15px',
            padding: '15px 0',
          }}
        >
          <div>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={plateNumber}
            />
            <Checkbox
              toggle
              style={{ marginLeft: '10px' }}
              label='ALL ASSET'
              checked={plateNumber ? false : true}
              onClick={(e, data) => this.setState({ plateNumber: data.value })}
            />
            <Dropdown
              search
              selection
              options={spareParts.map(({ key, value }) => ({
                key,
                text: `${value.split('_').join(' ')} [${key}]`,
                value: key,
              }))}
              value={particular}
              onChange={(e, data) => this.setState({ particular: data.value })}
              style={{
                textTransform: 'capitalize',
              }}
            />
            <Checkbox
              toggle
              style={{ marginLeft: '10px' }}
              label='ALL Particular'
              onClick={(e, data) => this.setState({ particular: data.value })}
              checked={plateNumber ? false : true}
            />
          </div>

          {data.length > 0 && (
            <div>
              <Pagination
                activePage={activePage}
                pointing
                secondary
                totalPages={totalPages}
                onPageChange={(e, data) => this.pagination(e, data)}
                firstItem={{
                  content: <Icon name='angle double left' />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name='angle double right' />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          )}
        </div>

        {messageShow && <Message type='warning' info='Successfully deleted.' />}

        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Sl No.</Table.HeaderCell>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Registration No.</Table.HeaderCell>
                <Table.HeaderCell>Particulars</Table.HeaderCell>
                <Table.HeaderCell>Spare Parts ID</Table.HeaderCell>
                <Table.HeaderCell>Purchase Date</Table.HeaderCell>
                <Table.HeaderCell>Purchase From</Table.HeaderCell>
                <Table.HeaderCell>Purchase Qty</Table.HeaderCell>
                <Table.HeaderCell>Previous Qty</Table.HeaderCell>
                <Table.HeaderCell>Previous Purchase Date</Table.HeaderCell>
                <Table.HeaderCell>Unit Rate</Table.HeaderCell>
                <Table.HeaderCell>Total Amount</Table.HeaderCell>
                <Table.HeaderCell>Present Meter</Table.HeaderCell>
                <Table.HeaderCell>Previous Meter</Table.HeaderCell>
                <Table.HeaderCell>Running Millage</Table.HeaderCell>
                <Table.HeaderCell>Mode of Payment</Table.HeaderCell>
                <Table.HeaderCell>Branch</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Last Edited</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.length > 0 &&
                data.map((item, index) => (
                  <Table.Row key={item._id.$oid}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{item.bulk_entry_id}</Table.Cell>
                    <Table.Cell>{item.van_car_no}</Table.Cell>
                    <Table.Cell style={{ textTransform: 'capitalize' }}>
                      {item.particulars.value.split('_').join(' ')}
                    </Table.Cell>
                    <Table.Cell>{item.particulars.key}</Table.Cell>
                    <Table.Cell>
                      {moment(item.purchase_date.$date).format(
                        'dddd, MMMM Do, YYYY, hh:mm:ss A',
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.purchase_from}</Table.Cell>
                    <Table.Cell>{item.purchase_qty}</Table.Cell>
                    <Table.Cell>{item.prev_qty}</Table.Cell>
                    <Table.Cell>
                      {item.prev_purchase_date
                        ? moment(item.prev_purchase_date.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                    </Table.Cell>
                    <Table.Cell>{item.unit_rate}</Table.Cell>
                    <Table.Cell>{item.total_amt}</Table.Cell>
                    <Table.Cell>{item.present_meter}</Table.Cell>
                    <Table.Cell>{item.prev_meter}</Table.Cell>
                    <Table.Cell>{item.running_mileage}</Table.Cell>
                    <Table.Cell style={{ textTransform: 'capitalize' }}>
                      {item.payment_mode.split('_').join(' ')}
                    </Table.Cell>
                    <Table.Cell style={{ textTransform: 'capitalize' }}>
                      {item.current_branch.split('_').join(' ')}
                    </Table.Cell>
                    <Table.Cell>{item.remarks}</Table.Cell>
                    <Table.Cell>
                      {item.created_on
                        ? moment(item.created_on.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                      {` by `}
                      {item.created_by_email}
                    </Table.Cell>
                    <Table.Cell>
                      {item.modified_on
                        ? moment(item.modified_on.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                      {` by `}
                      {item.modified_by_email}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        positive
                        onClick={() => this.props.handleEdit(item)}
                        style={{ width: '100%', marginBottom: '10px' }}
                      >
                        Edit
                      </Button>

                      <Popup
                        trigger={
                          <Button
                            onClick={() => this.handleConfirm(item._id.$oid)}
                            style={{ width: '100%', marginBottom: '10px' }}
                          >
                            Delete
                          </Button>
                        }
                        content={
                          <div>
                            <Button
                              negative
                              onClick={() => this.handleDelete(item._id.$oid)}
                            >
                              Yes
                            </Button>
                            <Button onClick={() => this.handleConfirm(false)}>
                              No
                            </Button>
                          </div>
                        }
                        open={isConfirm === `${item._id.$oid}`}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <div style={{ textAlign: 'center' }}>
            <Loader active={loaderActive} />
            {!loaderActive && data.length === 0 && <h3>No Data Found</h3>}
          </div>
        </div>
      </div>
    );
  }
}

export default List;

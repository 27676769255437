import React from 'react';
import {
  Icon,
  Button,
  Header,
  Label,
  Loader,
  Table,
  Statistic,
  Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.js';

import { reportDownloadExcel, reportView } from '../helper/dataService';
import { getIdsAndTime, getActiveAssetList, getOrg } from '../helper/helper';
import reportsEnv from '../reportsEnv';
import { alertMessage } from '../../../helper/alert-message';

class LocationLive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      selectedAssets: [],
      data: [],
      disabledRefresh: true,
      currentDay: 1,
      currentFilter: 'Both',
      generatedOn: moment().format('MMMM Do, YYYY, hh:mm:ss A'),
    };
  }

  componentDidMount() {
    const { startTime, endTime } = JSON.parse(
      window.localStorage.getItem('reports_state'),
    );

    this.setState({
      selectedAssets: getActiveAssetList().map((item) => item.value),
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      disabledRefresh: true,
    });

    const timeInSeconds = 30;
    this.refreshTimeout = setTimeout(() => {
      this.setState({ disabledRefresh: false });
    }, timeInSeconds * 1000);

    const { ids } = getIdsAndTime(this.state);

    reportView(reportsEnv.locationLive, {
      asset_ids: ids,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          active: false,
          generatedOn: moment().format('MMMM Do, YYYY, hh:mm:ss A'),
        });
      })
      .catch(() => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids } = getIdsAndTime(this.state);

    reportDownloadExcel(
      { ...reportsEnv.locationLive, apiVer: 'v1.2' },
      {
        asset_ids: ids,
        org_oid: getOrg(),
      },
    )
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Live_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  downloadExcelJS = (data, day, active, inactive, filter) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Finder GPS Tracking';
    workbook.created = new Date();

    workbook.addWorksheet('Location Live Report');

    const worksheet = workbook.getWorksheet('Location Live Report');

    worksheet.addRow(['Finder GPS Tracking']);
    worksheet.addRow(['Location Live Report']);
    worksheet.addRow([
      `Generated on ${moment().format('MMMM Do, YYYY, hh:mm:ss A')}`,
    ]);

    worksheet.addRow();
    worksheet.addRow([`Time Frame: ${day}`]);
    worksheet.addRow([`Was Active: ${active}`]);
    worksheet.addRow([`Was Inactive: ${inactive}`]);
    worksheet.addRow([`Filter By: ${filter}`]);

    worksheet.addRow();
    worksheet.addRow([
      'ID',
      'Vehicle Name',
      'Last Location Time',
      'Nearest Landmark',
      'Active',
    ]);

    data.forEach(({ name, time, landmark, disable }, idx) =>
      worksheet.addRow([
        idx + 1,
        name,
        moment.unix(time).format('dddd, MMMM Do, YYYY, hh:mm:ss A'),
        landmark.length > 0
          ? landmark.map((item, i) => `${i + 1}. ${item}`).join('. ')
          : 'No Landmark Found',
        disable ? 'No' : 'Yes',
      ]),
    );

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/octet-binary',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Location Live Tracking.xlsx';
      a.click();
      a.remove();
    });
  };

  render() {
    const {
      active,
      data,
      selectedAssets,
      pdfDisableBtn,
      excelDisableBtn,
      disabledRefresh,
      currentDay,
      currentFilter,
      generatedOn,
    } = this.state;

    const days = [1, 2, 3, 4, 5, 6, 7];
    const filters = ['Both', 'Active', 'Inactive'];

    let totalDisable = 0;

    const updatedData = data.map(({ last_data }) => {
      const disable = moment
        .unix(last_data.time)
        .isBefore(moment().subtract(currentDay, 'days'));

      if (disable) totalDisable = totalDisable + 1;

      return { ...last_data, disable };
    });

    const afterFilterData = updatedData.filter((item) => {
      if (currentFilter === 'Active') {
        return !item.disable;
      } else if (currentFilter === 'Inactive') {
        return item.disable;
      }

      return true;
    });

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>
              <Icon name='info' />
              Location Live
            </Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {selectedAssets.length} assets [Default all assets are
                  selected]
                </Label>
              </Label.Group>
            </div>

            {selectedAssets.length > 0 && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>
                  <Icon name='info' />
                  Location Live [{updatedData.length} assets]
                </Header>
                <p>Generated on: {generatedOn}</p>
              </div>
              <div>
                <Button
                  color='teal'
                  onClick={() =>
                    this.downloadExcelJS(
                      afterFilterData,
                      currentDay === 1
                        ? 'Within 24 hours'
                        : `Within ${currentDay} days`,
                      updatedData.length - totalDisable,
                      totalDisable,
                      currentFilter,
                    )
                  }
                  style={{ marginRight: '30px' }}
                >
                  Download
                </Button>
                <Button.Group>
                  <Button
                    onClick={this.downloadReport}
                    disabled={disabledRefresh}
                  >
                    Refresh
                  </Button>
                  <Button primary onClick={this.getBack}>
                    Back
                  </Button>
                </Button.Group>
              </div>
            </div>

            <Segment>
              <div className='left-right'>
                <div>
                  <div>
                    <select
                      className='select-semantic'
                      onChange={(e) =>
                        this.setState({ currentDay: e.target.value })
                      }
                      value={currentDay}
                      style={{ marginBottom: '15px', width: '100%' }}
                    >
                      {days.map((item) => (
                        <option key={item} value={item}>
                          {item === 1
                            ? 'Within 24 hours'
                            : `Within ${item} days`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <Statistic size='mini'>
                      <Statistic.Value>
                        {updatedData.length - totalDisable}
                      </Statistic.Value>
                      <Statistic.Label>Was Active</Statistic.Label>
                    </Statistic>
                    <Statistic size='mini'>
                      <Statistic.Value>{totalDisable}</Statistic.Value>
                      <Statistic.Label>Was Inactive</Statistic.Label>
                    </Statistic>
                  </div>
                </div>
                <div>
                  <p>Filter By</p>
                  <select
                    className='select-semantic'
                    onChange={(e) =>
                      this.setState({ currentFilter: e.target.value })
                    }
                    value={currentFilter}
                  >
                    {filters.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <p style={{ marginTop: '20px' }}>
                    <b>
                      <i>
                        * Active and Inactive count is based on Last Location
                        Time.
                      </i>
                    </b>
                  </p>
                </div>
              </div>
            </Segment>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Vehicle Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Location Time</Table.HeaderCell>
                  <Table.HeaderCell>Nearest Landmark</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {afterFilterData.map(
                  ({ name, time, landmark, disable }, idx) => {
                    return (
                      <Table.Row key={name} active={disable}>
                        <Table.Cell>
                          <Label>{idx + 1}</Label>
                        </Table.Cell>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>
                          {moment
                            .unix(time)
                            .format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                        </Table.Cell>
                        <Table.Cell>
                          {landmark.length > 0 ? (
                            landmark.map((item, index) => (
                              <p key={item + index}>
                                <i className='marker icon' />
                                {item}
                              </p>
                            ))
                          ) : (
                            <p>
                              <i className='marker icon' />
                              No data found
                            </p>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  },
                )}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default LocationLive;

import React from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');

class Edit extends React.Component {
  state = {
    geofence_name: '',
    asset_name: '',
    area: '',
    amount: '',
    submit: true,
  };

  componentDidMount() {
    const { id, geofence_name, asset_name, area, amount } = this.props.data;
    this.setState({
      submit: false,
      id,
      geofence_name,
      asset_name,
      area: area,
      amount,
    });
  }

  saveData = () => {
    const { id, area, amount } = this.state;

    this.setState({ submit: true });

    axios
      .put(
        `${REGULAR_PATH}/syngenta_bill_rate?access_token=${token()}`,
        JSON.stringify({
          bill_oid: id,
          area: Number(area).toFixed(2),
          amount: Math.round(amount),
        }),
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({ status, submit: false });
        this.props.handleEditData();
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleArea = (e) => {
    const area = parseFloat(e.target.value);
    this.setState({ area: isNaN(area) ? 0 : area });
  };

  handleAmount = (e) => {
    const amount = Number(e.target.value);
    this.setState({ amount: isNaN(amount) ? 0 : amount });
  };

  render() {
    const { geofence_name, asset_name, area, amount, status, submit } =
      this.state;

    return (
      <div>
        <Form>
          <Form.Field
            control={Input}
            label='Geofencee Name'
            value={geofence_name}
            disabled
          />
          <Form.Field
            control={Input}
            label='Asset Name'
            value={asset_name}
            disabled
          />

          <Form.Field
            control={Input}
            label='Area [decimal]'
            placeholder='Input Area'
            onChange={this.handleArea}
            value={area}
          />
          <Form.Field
            control={Input}
            label='Amount [tk]'
            placeholder='Input Amount'
            onChange={this.handleAmount}
            value={amount}
          />
        </Form>
        {area ? (
          <Button
            primary
            style={{ marginTop: '15px' }}
            onClick={this.saveData}
            disabled={submit}
          >
            Submit
          </Button>
        ) : null}
        {status && status === 'successfully updated' && (
          <Message positive>Bill updated successfully.</Message>
        )}

        {status && status !== 'successfully updated' && (
          <Message negative>{status || 'Sorry Something Went Wrong'}</Message>
        )}
      </div>
    );
  }
}

export default Edit;

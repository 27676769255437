import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const selectionDays = [
  { key: 'custom', text: 'Custom', value: 'custom', disabled: true },
  { key: 'today', text: 'Today', value: 'today' },
  { key: 'yesterday', text: 'Yesterday', value: 'yesterday' },
  {
    key: 'before_yesterday',
    text: 'Before Yesterday',
    value: 'before_yesterday',
  },
];

@inject('timer')
@observer
class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: this.props.timer.start,
      end: this.props.timer.end,
      selectedDay: 'today',
    };
  }

  componentDidUpdate(_, prevState) {
    const { start, end } = this.state;
    if (start !== prevState.start || end !== prevState.end) {
      this.props.timer.handleStartTime(start);
      this.props.timer.handleEndTime(end);
    }
  }

  startChange = (value) => {
    this.setState({ start: value, selectedDay: 'custom' });
  };

  endChange = (value) => {
    this.setState({ end: value, selectedDay: 'custom' });
  };

  onSelectDay = (e, data) => {
    const setTime = (subtractDay) => {
      const start = moment().subtract(subtractDay, 'day').startOf('day');
      const end = moment().subtract(subtractDay, 'day').endOf('day');
      this.setState({ start, end });
    };

    const { value } = data;
    this.setState({ selectedDay: value });

    switch (value) {
      case 'today':
        this.setState({ start: moment().startOf('day'), end: moment() });
        break;
      case 'yesterday':
        setTime(1);
        break;
      case 'before_yesterday':
        setTime(2);
        break;
      default:
        this.setState({ start: moment().startOf('day'), end: moment() });
        break;
    }
  };

  render() {
    const { start, end, selectedDay } = this.state;

    return (
      <div className='date-time' id='dateTime'>
        <DatePicker
          selected={start}
          onChange={this.startChange}
          showTimeSelect
          maxDate={moment().add(0, 'days')}
          timeIntervals={5}
          dateFormat='LLL'
        />
        {!this.props.visible ? (
          <span
            style={{
              color: 'white',
              margin: '0px 3px',
            }}
          >
            —
          </span>
        ) : null}
        <DatePicker
          selected={end}
          onChange={this.endChange}
          maxDate={moment().add(0, 'days')}
          showTimeSelect
          timeIntervals={5}
          dateFormat='LLL'
        />
        <div id='selected-day'>
          <Dropdown
            style={{
              padding: '5px 7px',
              width: '100%',
              minHeight: '0px',
            }}
            compact
            selection
            value={selectedDay}
            onChange={this.onSelectDay}
            options={selectionDays}
          />
        </div>
      </div>
    );
  }
}

export default DateTime;

import React, { Component } from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import GoogleLayer from 'react-leaflet-google-layer';
import { GOOGLE_MAP_KEY } from '../../env';

export default class MapLayers extends Component {
  render() {
    const isDev = window.location.host === 'localhost:8080';
    const isProd = !isDev;

    return (
      <LayersControl position='topright'>
        {/* <LayersControl.BaseLayer name="Kothai Map">
          <TileLayer url="https://mapserver.kothay.com/v2/raster/normal/?z={z}&y={y}&x={x}&token=a0f31e090f154efbb090ecfab654888df631eab5f0634e2b865a060d163ea573" />
        </LayersControl.BaseLayer> */}
        <LayersControl.BaseLayer name='OpenStreetMap' checked={isDev}>
          <TileLayer
            url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Google Maps Roads' checked={isProd}>
          <GoogleLayer
            googleMapsLoaderConf={{ key: GOOGLE_MAP_KEY }}
            type={'roadmap'}
            maxZoom={22}
            useGoogMapsLoader={false}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Google Streets Satellite'>
          <GoogleLayer
            googleMapsLoaderConf={{ key: GOOGLE_MAP_KEY }}
            type={'satellite'}
            maxZoom={22}
            useGoogMapsLoader={false}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
    );
  }
}

import React, { Component } from 'react';
import { Button, Checkbox, Form, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { postData } from '../dataHelper';
import Message from '../Message';

class Add extends Component {
  state = {
    plateNumberList: this.props.plateNumberList,
    registrationNumber: '',
    lastServicingDate: moment(),
    closingMeter: 0,
    targetDate: moment(),
    targetKM: 0,
    nextKM: '',
    nextDate: '',
    rightTime: true,
    remarks: '',
    messageShow: false,
    messageType: '',
    messageInfo: '',
    submitted: false,
  };

  handleLastServicingDate = (value) => {
    this.setState({
      lastServicingDate: value,
      nextDate: this.state.targetDate.diff(value, 'months'),
    });
  };

  handleTargetDateChange = (value) => {
    this.setState({
      targetDate: value,
      nextDate: value.diff(this.state.lastServicingDate, 'months'),
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { targetKM, closingMeter } = this.state;

    this.setState({ [name]: value });

    if (name === 'targetKM') {
      this.setState({ nextKM: Number(value) - Number(closingMeter) });
    }
    if (name === 'closingMeter') {
      this.setState({ nextKM: Number(targetKM) - Number(value) });
    }
  };

  handleRightTime = (e, data) => {
    this.setState({ rightTime: data.checked });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ messageShow: false, submitted: true });
    const {
      registrationNumber,
      lastServicingDate,
      closingMeter,
      targetDate,
      targetKM,
      nextKM,
      nextDate,
      rightTime,
      remarks,
    } = this.state;

    const data = {
      asset_id: registrationNumber,
      last_servicing_date: lastServicingDate
        ? moment(lastServicingDate).unix()
        : '',
      closing_meter: closingMeter,
      target_date: targetDate ? moment(targetDate).unix() : '',
      target_km: targetKM,
      nxt_servicing_date: {
        km: nextKM,
        month: nextDate,
      },
      is_right_time: rightTime,
      remarks: remarks,
    };

    postData(`${this.props.org}_servicing`, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'positive',
            submitted: false,
            registrationNumber: '',
            lastServicingDate: null,
            closingMeter: '',
            targetDate: null,
            targetKM: '',
            nextKM: '',
            nextDate: '',
            rightTime: true,
            remarks: '',
          });
          setTimeout(() => {
            this.setState({ messageShow: false });
          }, 3000);
        } else if (status === 'error') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'warning',
            submitted: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitted: false });
      });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ registrationNumber: data.value });
  };

  render() {
    const {
      plateNumberList,
      registrationNumber,
      lastServicingDate,
      closingMeter,
      targetDate,
      targetKM,
      nextKM,
      nextDate,
      rightTime,
      remarks,
      messageShow,
      messageType,
      messageInfo,
      submitted,
    } = this.state;

    return (
      <div style={{ margin: '10px auto', width: '70%' }}>
        <Form>
          <Form.Field>
            <label>Registration No.</label>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={registrationNumber}
            />
          </Form.Field>

          <Form.Field>
            <label>Last Servicing Date</label>
            <DatePicker
              selected={lastServicingDate}
              onChange={this.handleLastServicingDate}
              dateFormat='LL'
              maxDate={moment().add(0, 'days')}
            />
          </Form.Field>

          <Form.Field>
            <label name='closingMeter'>Closing Meter No.</label>
            <input
              value={closingMeter}
              onChange={this.handleChange}
              name='closingMeter'
              type='text'
              required
            />
          </Form.Field>

          <Form.Field>
            <label>Target Date</label>
            <DatePicker
              selected={targetDate}
              onChange={this.handleTargetDateChange}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label name='targetKM'>Target K.M.</label>
            <input
              value={targetKM}
              onChange={this.handleChange}
              name='targetKM'
              type='text'
              required
            />
          </Form.Field>

          <Form.Field>
            <label name='NextServicingDate'>Next Servicing Date</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                value={nextKM}
                onChange={this.handleChange}
                name='nextKM'
                type='text'
                required
                style={{ width: '120px', margin: '0 5px' }}
              />
              km OR
              <input
                value={nextDate}
                onChange={this.handleChange}
                name='nextDate'
                type='text'
                required
                style={{ width: '100px', margin: '0 5px' }}
              />
              month whichever come first
            </div>
          </Form.Field>

          <Form.Field>
            <Checkbox
              label='is it right time?'
              toggle
              checked={rightTime}
              onClick={this.handleRightTime}
              name='rightTime'
            />
          </Form.Field>

          <Form.Field>
            <label name='remarks'>Remarks</label>
            <textarea
              placeholder='Remarks'
              value={remarks}
              onChange={this.handleChange}
              name='remarks'
              type='text'
            />
          </Form.Field>
        </Form>

        <br />
        {messageShow && <Message type={messageType} info={messageInfo} />}

        <Button
          primary
          disabled={submitted}
          loading={submitted}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default Add;

import React from 'react';
import { Input, Button, Table } from 'semantic-ui-react';
import { getData, editData, postData } from '../dataHelper';
import Message from '../Message';

class Particular extends React.Component {
  state = {
    titles: [],
    value: '',
    showMessage: false,
    message: '',
    messageType: 'positive',
    selectedId: '',
    selectedValue: '',
  };

  componentDidMount() {
    getData(
      `${this.props.org}_dropdown`,
      `key=particulars&drop_down_name=particular_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    ).then((response) => {
      this.setState({
        titles: response.data,
      });
    });
  }

  handleItem = (e, data) => {
    this.setState({ value: data.value });
  };

  handleSubmit = () => {
    const { value, titles } = this.state;
    const data = {
      key: String(
        Number(
          titles.sort((a, b) => Number(a.key) - Number(b.key))[
            titles.length - 1
          ].key,
        ) + 1,
      ),
      value: value.toLowerCase().split(' ').join('_'),
      status: 'active',
    };
    postData(`${this.props.org}_dropdown`, {
      update_type: 'particulars',
      value: data,
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'particular_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: [...prevState.titles, data],
            value: '',
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  enableEdit = (selectedId, selectedValue) => {
    this.setState({
      selectedId,
      selectedValue,
      newValue: selectedValue.split('_').join(' '),
    });
  };

  handleEditItem = (e, data) => {
    this.setState({ newValue: data.value });
  };

  handleUpdate = () => {
    const { selectedId, selectedValue, newValue } = this.state;
    editData(`${this.props.org}_dropdown`, {
      update_type: 'particulars',
      prev_value: {
        key: selectedId,
        value: selectedValue.toLowerCase(),
        status: 'active',
      },
      value: {
        key: selectedId,
        value: newValue.toLowerCase().split(' ').join('_'),
        status: 'active',
      },
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'particular_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: [
              ...prevState.titles.filter(
                (item) => item.value !== selectedValue,
              ),
              {
                key: selectedId,
                value: newValue.split(' ').join('_'),
                status: 'active',
              },
            ],
            selectedValue: '',
            newValue: '',
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  handleDelete = (value) => {
    editData(`${this.props.org}_dropdown`, {
      update_type: 'particulars',
      prev_value: value,
      value: {
        ...value,
        status: value.status === 'active' ? 'inactive' : 'active',
      },
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'particular_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: [
              ...prevState.titles.filter((item) => item.key !== value.key),
              {
                ...value,
                status: value.status === 'active' ? 'inactive' : 'active',
              },
            ],
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  showMessage = (message, messageType) => {
    this.setState({ showMessage: true, message, messageType });
    setTimeout(() => {
      this.setState({ showMessage: false });
    }, 3000);
  };

  render() {
    const {
      titles,
      value,
      selectedValue,
      newValue,
      showMessage,
      message,
      messageType,
    } = this.state;
    return (
      <div>
        <div>
          <Input
            onChange={this.handleItem}
            value={value}
            placeholder='Add here'
          />
          <Button color='green' onClick={this.handleSubmit}>
            Add
          </Button>
        </div>

        {showMessage && <Message type={messageType} info={message} />}

        {titles.length > 0 && (
          <Table celled textAlign='center'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {titles
                .sort((a, b) => Number(a.key) - Number(b.key))
                .map(({ key, value, status }) => (
                  <Table.Row key={key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell style={{ textTransform: 'capitalize' }}>
                      {selectedValue === value ? (
                        <Input
                          onChange={this.handleEditItem}
                          placeholder='Add here'
                          value={newValue}
                        />
                      ) : (
                        value.split('_').join(' ')
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {selectedValue === value ? (
                        <span>
                          <Button color='green' onClick={this.handleUpdate}>
                            Update
                          </Button>
                          <Button onClick={() => this.enableEdit('', '')}>
                            Cancel
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <Button
                            color='green'
                            onClick={() => this.enableEdit(key, value)}
                            disabled={status !== 'active'}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() =>
                              this.handleDelete({
                                key: String(key),
                                value,
                                status,
                              })
                            }
                          >
                            {status === 'active' ? 'Deactivate' : 'Activate'}
                          </Button>
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}

export default Particular;

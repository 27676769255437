import React from 'react';
import { Button } from 'semantic-ui-react';
import flv from 'flv.js';
import { getData } from '../../../vms/dataHelper';

class JC120Stream extends React.Component {
  constructor(props) {
    super(props);

    this.videoRef = null;

    this.state = {
      fetching: false,
      url: '',
      message: '',
    };
  }

  componentDidMount() {
    this.buildPlayer();
  }

  componentDidUpdate() {
    this.buildPlayer();
  }

  componentWillUnmount() {
    console.log('destroy jc120 player');
    this.player.destroy();
  }

  fetchStreamUrl = () => {
    this.setState({ fetching: true });
    getData(`assets/${this.props.assetId}/video_stream`)
      .then(({ data }) => {
        const status = data.status;

        if (status === 'success') {
          const { stream_url } = data.data;
          this.setState({ url: stream_url, message: '' });
        } else if (status === 'error') {
          const message = data.error;
          this.setState({ message, url: '' });
        }

        this.setState({ fetching: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
  };

  buildPlayer = () => {
    if (this.player || !this.state.url) {
      return;
    }

    this.player = flv.createPlayer({
      type: 'flv',
      isLive: true,
      url: this.state.url,
    });
    this.player.attachMediaElement(this.videoRef);
    this.player.load();
    this.player.play();
  };

  render() {
    const { fetching, url, message } = this.state;

    return (
      <div>
        {url ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <video
              ref={(element) => (this.videoRef = element)}
              style={{ width: '100%', height: '100%' }}
              controls
              muted
            />
            <Button
              style={{ margin: 0, marginTop: 8, borderRadius: 0 }}
              color='red'
              onClick={() => this.setState({ url: '' })}
            >
              Close
            </Button>
          </div>
        ) : (
          <Button
            fluid
            onClick={this.fetchStreamUrl}
            disabled={fetching}
            loading={fetching}
          >
            Live
          </Button>
        )}

        {message ? (
          <p style={{ margin: '10px 0', fontSize: '12px' }}>{message}</p>
        ) : null}
      </div>
    );
  }
}

export default JC120Stream;

import React from 'react';
import { Dropdown, Button, Checkbox } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import FileSaver from 'file-saver';
import { BASE_PATH } from '../../../../env';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelDisableBtn: false,
      assetsList: this.props.plateNumberList,
      selectedAssets: [],
    };
  }

  handleAssetSelect = (e, data) => {
    this.setState({ selectedAssets: data.value });
  };

  handleAllAssetSelect = () => {
    this.setState((prevState) => ({
      selectedAssets:
        prevState.selectedAssets.length === prevState.assetsList.length
          ? []
          : this.state.assetsList.map((item) => item.value),
    }));
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });
    const { selectedAssets: ids } = this.state;

    const user = window.localStorage.getItem('user');

    axios({
      method: 'post',
      url: `${BASE_PATH}/v1/reports/${this.props.org}_delivery_van_report?access_token=${user}`,
      data: {
        asset_ids: ids,
      },
      responseType: 'blob',
      headers: {
        'content-type': 'text/plain',
      },
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Delivery_Van_Information_Report_${moment().format(
            'DD-MMMM-YYYY_HH:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { assetsList, selectedAssets, excelDisableBtn } = this.state;

    return (
      <div>
        <div className='center-of-page'>
          <div>
            <Dropdown
              placeholder='Select Assets'
              fluid
              multiple
              search
              selection
              options={assetsList}
              onChange={this.handleAssetSelect}
              value={selectedAssets}
            />
          </div>
          <br />
          <Checkbox
            toggle
            label={`${
              assetsList.length === selectedAssets.length
                ? 'Remove All'
                : 'Select All'
            }`}
            onChange={this.handleAllAssetSelect}
            style={{ textAlign: 'left' }}
          />

          {selectedAssets.length > 0 && (
            <Button.Group vertical>
              <Button
                color='teal'
                style={{ marginTop: '20px', fontSize: '16px' }}
                icon='file excel outline'
                content='Download Excel'
                onClick={this.handleDownloadExcelBtn}
                disabled={excelDisableBtn}
                loading={excelDisableBtn}
              />
            </Button.Group>
          )}
        </div>
      </div>
    );
  }
}

export default Download;

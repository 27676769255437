import React, { Component } from 'react';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Grid,
  Button,
} from 'semantic-ui-react';
import { REGULAR_PATH } from '../../../env';
import ClickOutside from '../../helper/click-outside';
import { getData, handleMultipleRequest } from '../../helper/dataService';
import TemperatureGauge from '../dashboard/details/TemperatureGauge';
import moment from 'moment';
import { refreshToken } from '../../helper/refresh-token';

export default class Temperature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      selectAll: false,
      selected: [],
      search: '',
      openDropdown: false,
      temperatureData: [],
    };
  }

  componentDidMount() {
    const assetList = JSON.parse(window.localStorage.getItem('assetList'));
    const assets = assetList.map((item) => ({
      id: item.value,
      value: item.value,
      label: item.label,
      checked: false,
    }));

    this.setState({ assets });

    this.IntervalID = setInterval(() => {
      if (this.state.temperatureData.length) {
        const selected = this.state.temperatureData.map((data) => data.id);
        this.getTemperatureData(selected);
      }
    }, 60000);
  }

  openDropdown = () => {
    this.setState({ openDropdown: true });
  };

  closeDropdown = () => {
    this.setState({ openDropdown: false });
  };

  toggleDropdown = () =>
    this.setState({ openDropdown: !this.state.openDropdown });

  handleCheckboxChange = (id) => {
    const options = this.state.assets.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: item.checked ? false : true,
        };
      } else {
        return item;
      }
    });

    const selected = options
      .filter((option) => option.checked)
      .map((option) => option.id);

    this.setState({ assets: options, selected });
  };

  getTemperatureData = (selected) => {
    let promises = [];

    for (let i = 0; i < selected.length; i++) {
      let id = selected[i];
      let url = `${REGULAR_PATH}/assets/${id}/last_data?access_token=`;

      promises.push(getData(url));
    }

    let lastDataResponse = handleMultipleRequest(promises);
    const data = [];
    lastDataResponse
      .then((results) => {
        results.forEach((res) => {
          const temp = {
            id: res.data.asset_id,
            time: res.data.time,
            // name
            name: res.data.name,
            // temperature1
            temperature_1_enabled:
              res.data.temperature_sensor === 'True' ? true : false,
            temperature_1: res.data.temperature,
            temperature_1_name: res.data.temperature_sensor_name,
            // temperature2
            temperature_2_enabled:
              res.data.temperature_sensor_2 === 'True' ? true : false,
            temperature_2: res.data.temperature_2,
            temperature_2_name: res.data.temperature_sensor_2_name,
            // temperature3
            temperature_3_enabled:
              res.data.temperature_sensor_3 === 'True' ? true : false,
            temperature_3: res.data.temperature_3,
            temperature_3_name: res.data.temperature_sensor_3_name,
            // temperature4
            temperature_4_enabled:
              res.data.temperature_sensor_4 === 'True' ? true : false,
            temperature_4: res.data.temperature_4,
            temperature_4_name: res.data.temperature_sensor_4_name,
          };
          data.push(temp);
        });

        this.setState({ temperatureData: data });
      })
      .catch((error) => {
        if (error.response.data.error === 'expired token') {
          let extendedToken = refreshToken(
            `${REGULAR_PATH}/assets/${
              selected[0]
            }/last_data?access_token=${window.localStorage.getItem('user')}`,
          );
          extendedToken.then((res) => {
            this.generateLocationForMarker(selected);
          });
        }
      });
  };

  render() {
    const { assets, selected, openDropdown, temperatureData } = this.state;
    const totalSelected = selected.length;

    return (
      <div style={{ padding: 10 }}>
        <div
          style={{
            display: 'flex',
            paddingLeft: 20,
            gap: 10,
            marginBottom: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            <ClickOutside handleEvent={this.closeDropdown}>
              <div
                style={{
                  border: '1px solid gray',
                  padding: '5px 10px',
                  borderRadius: 5,
                }}
              >
                <Dropdown
                  trigger={
                    <span
                      onClick={this.toggleDropdown}
                      style={{ fontWeight: 600 }}
                    >
                      {totalSelected > 0
                        ? `${totalSelected} SELECTED`
                        : 'SELECT ASSET'}
                    </span>
                  }
                  open={openDropdown}
                >
                  <DropdownMenu>
                    {assets.map(({ id, label, checked }) => (
                      <DropdownItem
                        key={id}
                        value={id}
                        content={
                          <label>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                              }}
                            >
                              <input
                                type='checkbox'
                                checked={checked}
                                onChange={() => this.handleCheckboxChange(id)}
                              />
                              {label}
                            </div>
                          </label>
                        }
                      />
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </ClickOutside>
            <Button
              color='teal'
              size='mini'
              onClick={() => this.getTemperatureData(this.state.selected)}
              disabled={this.state.assets.length === 0}
            >
              Submit
            </Button>
          </div>
          <div>
            <h3>Temperature</h3>
          </div>
          <div></div>
        </div>

        {temperatureData.map((data) => (
          <div
            key={data.name}
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                background: 'white',
                marginBottom: 20,
                paddingTop: 10,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                }}
              >
                <h4 style={{ marginTop: 5 }}>
                  <span style={{ fontWeight: 'bold' }}>{data.name}</span>
                </h4>
                <h4>
                  <span style={{ fontWeight: 'bold' }}>Updated at: </span>
                  {moment(data.time)
                    .utc(true)
                    .local()
                    .format('DD-MM-YYYY, hh:mm A')}
                </h4>
              </div>

              <hr style={{ borderTop: '1px solid #F5F5F5' }} />
              {!data.temperature_1 &&
              !data.temperature_2 &&
              !data.temperature_3 &&
              !data.temperature_4 ? (
                <div style={{ padding: 20 }}>No Data Found</div>
              ) : (
                <Grid columns={4} style={{ width: '100%' }}>
                  {data.temperature_1_enabled ? (
                    <Grid.Column>
                      <div>
                        <TemperatureGauge
                          name={
                            data.temperature_1_name
                              ? data.temperature_1_name
                              : 'Temperature 1'
                          }
                          value={data.temperature_1}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}
                  {data.temperature_2_enabled ? (
                    <Grid.Column>
                      <div>
                        <TemperatureGauge
                          name={
                            data.temperature_2_name
                              ? data.temperature_2_name
                              : 'Temperature 2'
                          }
                          value={data.temperature_2}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}
                  {data.temperature_3_enabled ? (
                    <Grid.Column>
                      <div>
                        <TemperatureGauge
                          name={
                            data.temperature_3_name
                              ? data.temperature_3_name
                              : 'Temperature 3'
                          }
                          value={data.temperature_3}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}
                  {data.temperature_4_enabled ? (
                    <Grid.Column>
                      <div>
                        <TemperatureGauge
                          name={
                            data.temperature_4_name
                              ? data.temperature_4_name
                              : 'Temperature 4'
                          }
                          value={data.temperature_4}
                        />
                      </div>
                    </Grid.Column>
                  ) : null}
                </Grid>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

import React from 'react';
import { Modal, Form, Button, Message, Dropdown } from 'semantic-ui-react';
import { getData } from '../helper/dataService';
import { REGULAR_PATH } from '../../env';

class MobileVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: '',
      message: '',
      smsCode: '',
      time: 0,
      success: '',
      change: false,
      mobileCountryCode: '+880',
      regexForMobile: '^1[3-9][0-9]{8}$',
    };
    this.access_token = window.localStorage.getItem('user');
  }

  componentDidMount() {
    this.timerInterval = setInterval(() => this.timer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  timer = () => {
    this.setState((prevState) => ({
      time: prevState.time !== 0 ? prevState.time - 1 : 0,
    }));
  };

  successMessage = () => {
    this.setState({ success: true });
    setTimeout(() => {
      this.setState({ success: false });
    }, 2500);
  };

  sendCode = (resend) => {
    window.localStorage.removeItem('mobile_verified');
    getData(
      `${REGULAR_PATH}/auth/send_code?${
        resend ? 'resend=ok' : ''
      }&access_token=`,
    )
      .then((res) => res)
      .catch((err) => err);
    this.setState({
      message: '',
      time: 60,
    });
  };

  smsVerification = () => {
    getData(
      `${REGULAR_PATH}/auth/mobile_verify?code=${this.state.smsCode}&access_token=`,
    ).then((res) => {
      if (res.data.status === 'code mismatch') {
        this.setState({
          message: 'Mobile verification code is not correct.',
        });
      } else if (res.data.status === 'code expired') {
        this.setState({
          message: 'Mobile verification code expired.',
        });
      } else if (res.data.status === 'mobile verified') {
        window.localStorage.setItem('mobile_verified', true);
        this.successMessage();
      }
    });
  };

  mobileVerification = () => {
    getData(
      `${REGULAR_PATH}/user/mobile?number=${this.state.mobileCountryCode}${this.state.mobile}&platform='web'&access_token=`,
    )
      .then((result) => {
        const status = result.data.status;
        if (status === 'number exists') {
          this.setState({
            message: 'This number already exist. Please input another number.',
          });
        } else if (status === 'mobile number successfully updated') {
          window.localStorage.setItem('mobile', this.state.mobile);
          this.sendCode();
        }
      })
      .catch((e) => e);
  };

  wrongNumber = () => {
    this.setState({ change: true });
  };

  handleWrongNumber = () => {
    window.localStorage.removeItem('mobile');
  };

  handleMobileChange = (event) => {
    this.setState({
      mobile: event.target.value,
    });
  };

  handleSmsCodeChange = (event) => {
    this.setState({ smsCode: event.target.value });
  };

  handleMobileSubmit = (event) => {
    event.preventDefault();
    this.mobileVerification();
  };

  handleSmsCodeSubmit = (event) => {
    event.preventDefault();
    this.smsVerification();
  };

  onChangeMobileCountryCode = (e, { value }) => {
    const regex = {
      '+880': '^1[3-9][0-9]{8}$',
      '+977': '^9[6-8][0-9]{8}$',
    };
    this.setState({
      mobileCountryCode: value,
      regexForMobile: regex[value],
    });
  };

  verificationCheck = () => {
    const mobileLocal = window.localStorage.getItem('mobile');
    const mobileVerifiedLocal = window.localStorage.getItem('mobile_verified');

    const Skip = (
      <Button
        onClick={() => this.props.handleModal(false)}
        basic
        style={{ float: 'right' }}
      >
        Skip
      </Button>
    );

    const WrongNumber = (
      <Button onClick={this.handleWrongNumber} style={{ float: 'right' }}>
        Submitted wrong number?
      </Button>
    );

    const MessageShow = this.state.message && (
      <Message warning>
        <p>{this.state.message}</p>
      </Message>
    );

    if (mobileLocal && mobileVerifiedLocal === 'true') {
      return null;
    } else {
      if (!mobileLocal) {
        return (
          <Modal open={true} size='mini'>
            <br />
            <Modal.Header>Please submit your mobile number</Modal.Header>
            <Modal.Content>
              <p style={{ fontSize: '16px' }}>
                You will need to confirm that you have the access to that
                number.
                <b> It is important for your account security.</b>
              </p>
              <br />
              <Dropdown
                onChange={this.onChangeMobileCountryCode}
                value={this.state.mobileCountryCode}
                options={[
                  { key: 'bd', text: '+880', value: '+880' },
                  { key: 'np', text: '+977', value: '+977' },
                ]}
              />
              <br />
              <br />
              <Form onSubmit={this.handleMobileSubmit}>
                <Form.Input
                  required
                  type='text'
                  value={this.state.mobile}
                  onChange={this.handleMobileChange}
                  pattern={this.state.regexForMobile}
                  placeholder='Enter your mobile number'
                  style={{ width: '100%' }}
                />

                <Button positive type='submit'>
                  Submit
                </Button>
                {Skip}
              </Form>
              <br />
              <br />
              {MessageShow}
            </Modal.Content>
          </Modal>
        );
      } else if (mobileLocal && mobileVerifiedLocal === 'false') {
        if (this.state.change) {
          return (
            <Modal open={true} size='mini'>
              <br />
              <Modal.Header>Please submit your mobile number</Modal.Header>
              <Modal.Content>
                <p style={{ fontSize: '16px' }}>
                  You will need to confirm that you have the access to that
                  number.
                  <b> It is important for your account security.</b>
                </p>
                <br />
                <Dropdown
                  onChange={this.onChangeMobileCountryCode}
                  value={this.state.mobileCountryCode}
                  options={[
                    { key: 'bd', text: '+880', value: '+880' },
                    { key: 'np', text: '+977', value: '+977' },
                  ]}
                />
                <br />
                <br />
                <Form onSubmit={this.handleMobileSubmit}>
                  <Form.Input
                    required
                    type='text'
                    value={this.state.mobile}
                    onChange={this.handleMobileChange}
                    pattern={this.state.regexForMobile}
                    placeholder='Enter your mobile number'
                  />

                  <Button positive type='submit'>
                    Submit
                  </Button>
                  {Skip}
                </Form>
                <br />
                <br />
                {MessageShow}
              </Modal.Content>
            </Modal>
          );
        } else {
          return (
            <Modal open={true} size='mini'>
              <Modal.Header>Please confirm your mobile number</Modal.Header>
              <Modal.Content>
                <p style={{ fontSize: '16px' }}>
                  You will need to confirm that you still have access to your
                  phone number before login.
                  <b> It is important for your account security.</b>
                </p>
                <h3>Is this your mobile number?</h3>
                <h3>{mobileLocal}</h3>
                <br />
                <br />
                <Button positive onClick={() => this.sendCode('resend')}>
                  Yes
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button primary onClick={this.wrongNumber}>
                  No
                </Button>
                {Skip}
              </Modal.Content>
            </Modal>
          );
        }
      } else if (mobileLocal) {
        return (
          <Modal open={true} size='mini'>
            <Modal.Header>
              <p>
                A code has been sent to {mobileLocal}. Please enter the code
                below. It might take several minutes to get your code.
              </p>
            </Modal.Header>
            <Modal.Content>
              <Form onSubmit={this.handleSmsCodeSubmit}>
                <Form.Input
                  required
                  type='text'
                  onChange={this.handleSmsCodeChange}
                  value={this.state.smsCode}
                  placeholder='Enter your code'
                />
                <Button type='submit' positive>
                  Submit
                </Button>
                {!this.state.time ? (
                  <Button onClick={() => this.sendCode('resend')} secondary>
                    Resend Code
                  </Button>
                ) : (
                  <Button secondary>
                    {`Resend Code in ${Math.floor(this.state.time / 60)} : ${
                      this.state.time % 60
                    }`}
                  </Button>
                )}
                {WrongNumber}
              </Form>
              <br />
              <br />
              {MessageShow}
            </Modal.Content>
          </Modal>
        );
      }
    }
  };

  render() {
    return (
      <div>
        {this.props.modalOpen && this.verificationCheck()}
        {this.state.success && (
          <Modal open={true} size='mini'>
            <Modal.Content>
              <Message
                success
                header='Your mobile number is successfully verified.'
                content='Thanks for staying with us.'
              />
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }
}

export default MobileVerification;

import React from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Dropdown,
  Loader,
} from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH, REGULAR_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class Add extends React.Component {
  state = {
    userListLoading: true,
    userList: [],
    userId: '',
    name: '',
    unit: '',
    id: '',
    submit: false,
    status: '',
  };

  componentDidMount() {
    axios
      .get(
        `${REGULAR_PATH}/staff/org_members?org_oid=5efd6d101d41c855060b1632&access_token=${token()}`,
      )
      .then(({ data }) => {
        const userList = data.map(({ name, user_id, _id }) => ({
          key: user_id,
          value: user_id,
          text: `${name} [${user_id}]`,
          name: name,
          id: _id.$oid,
        }));
        this.setState({ userList, userListLoading: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ userList: [], userListLoading: false });
      });
  }

  saveData = () => {
    const { userList, userId, name, id, unit } = this.state;

    const data = {
      org_oid: org(),
      user_oid: userList.find((item) => item.value === userId).id,
      user_id: userId,
      employee_name: name,
      employee_id: id,
      employee_unit: unit,
    };

    this.setState({ submit: true, status: '' });

    axios
      .post(
        `${BASE_PATH}/v1.2/reports/runners_employee?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const message = response.data.message;
        this.setState({ status: message, submit: false });
        if (message === 'Success') {
          this.setState({
            status: message,
            submit: false,
            userId: '',
            name: '',
            id: '',
            unit: '',
          });
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const {
      userListLoading,
      userList,
      userId,
      name,
      unit,
      id,
      submit,
      status,
    } = this.state;

    if (userListLoading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Form>
          <Form.Field
            control={Dropdown}
            fluid
            search
            selection
            options={userList}
            label='Employee'
            placeholder='Select Employee'
            value={userId}
            onChange={this.handleInput}
            name='userId'
          />
        </Form>
        <br />
        {userId ? (
          <div>
            <Form>
              <Form.Field
                control={Input}
                label='Name'
                onChange={this.handleInput}
                value={name}
                name='name'
              />
              <Form.Field
                control={Input}
                label='ID'
                onChange={this.handleInput}
                value={id}
                name='id'
              />
              <Form.Field
                control={Input}
                label='Unit'
                onChange={this.handleInput}
                value={unit}
                name='unit'
              />
            </Form>
          </div>
        ) : null}

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          disabled={submit}
        >
          Submit
        </Button>

        {status === 'Success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'Success' && (
          <Message negative> {status}</Message>
        )}
      </div>
    );
  }
}

export default Add;

import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

import List from './List';
import Add from './Add';
import Edit from './Edit';
import Download from './Download';

class ServicingInfo extends Component {
  state = {
    activeItem: 'List',
    data: '',
  };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, data: '' });

  handleEdit = (data) => {
    this.setState({ data: data, activeItem: data ? 'Edit' : 'List' });
  };

  render() {
    const { activeItem, data } = this.state;
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name='List'
            active={activeItem === 'List'}
            onClick={this.handleItemClick}
          />
          {!data && (
            <Menu.Item
              name='Add'
              active={activeItem === 'Add'}
              onClick={this.handleItemClick}
            />
          )}
          {data && (
            <Menu.Item
              name='Edit'
              active={activeItem === 'Edit'}
              onClick={this.handleItemClick}
            />
          )}
          <Menu.Item
            name='Download'
            active={activeItem === 'Download'}
            onClick={this.handleItemClick}
          />
        </Menu>
        {activeItem === 'List' && (
          <List
            plateNumberList={this.props.plateNumberList}
            handleEdit={this.handleEdit}
            org={this.props.org}
          />
        )}
        {activeItem === 'Add' && (
          <Add
            plateNumberList={this.props.plateNumberList}
            org={this.props.org}
          />
        )}
        {activeItem === 'Edit' && (
          <Edit
            plateNumberList={this.props.plateNumberList}
            data={data}
            handleEdit={this.handleEdit}
            org={this.props.org}
          />
        )}
        {activeItem === 'Download' && (
          <Download
            plateNumberList={this.props.plateNumberList}
            org={this.props.org}
          />
        )}
      </div>
    );
  }
}

export default ServicingInfo;

import React from 'react';
import { Segment, Menu } from 'semantic-ui-react';
import Add from './Add';
import List from './List';
import Edit from './Edit';
import BillList from './BillList';

class Bill extends React.Component {
  state = {
    activeItem: 'Bills',
    editId: '',
    editData: {},
  };

  handleEditData = (data) => {
    if (data) {
      this.setState({ activeItem: 'Edit', editId: data.id, editData: data });
    } else {
      this.setState({ activeItem: 'List', editId: '', editData: {} });
    }
  };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, editId: '' });

  render() {
    const { activeItem, editId, editData } = this.state;

    if (
      !['5e646e731d41c8396eabec03'].includes(
        window.localStorage.getItem('organization'),
      )
    ) {
      return <h2>You are not eligible</h2>;
    }

    return (
      <Segment>
        <Menu pointing>
          <Menu.Item
            name='Bills'
            active={activeItem === 'Bills'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='List'
            active={activeItem === 'List'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Add'
            active={activeItem === 'Add'}
            onClick={this.handleItemClick}
          />
          {activeItem === 'Edit' && <Menu.Item name='Edit' active={true} />}
        </Menu>
        {activeItem === 'Bills' && <BillList />}
        {activeItem === 'List' && <List handleEditData={this.handleEditData} />}
        {activeItem === 'Add' && !editId && <Add />}
        {activeItem === 'Edit' && editId && (
          <Edit data={editData} handleEditData={this.handleEditData} />
        )}
      </Segment>
    );
  }
}

export default Bill;

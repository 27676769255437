import React from 'react';
import {
  Segment,
  Dropdown,
  Button,
  Input,
  Header,
  Table,
  Label,
  Message,
  Popup,
  Loader,
  Icon,
  Search,
} from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import { REGULAR_PATH, LIVE_SHARE_URL } from '../../env';
import * as ExcelJS from 'exceljs/dist/exceljs.js';

class ShareView extends React.Component {
  state = {
    loading: '',
    assetsList: [],
    asset_id: '',
    timeCount: 1,
    timeType: 'hours',
    links: [],
    link: '',
    message: '',
    filterBy: {
      name: false,
      code: false,
    },
    sortBy: {
      type: '',
    },
  };

  componentDidMount() {
    const assetList = JSON.parse(window.localStorage.getItem('assetList'));
    this.setState({
      assetsList: assetList
        .filter((item) => item.active)
        .map((item) => ({
          key: item.key,
          value: item.value,
          text: item.label,
        })),
      plateNumberLoaded: true,
    });
    this.fetchLinks();

    axios
      .get(
        `${REGULAR_PATH}/user/assets?access_token=${window.localStorage.getItem(
          'user',
        )}`,
      )
      .then((response) => {
        const { assets } = response.data;
        const assetWithChesis = assets.map((asset) => ({
          chasis_number: asset.chasis_number,
          name: asset.name,
        }));

        const links = [...this.state.links];

        const data = links.map((link) => {
          const index = assetWithChesis.findIndex(
            (asset) => asset.name === link.asset_name,
          );

          if (index > -1) {
            return {
              ...link,
              chesis_number: assetWithChesis[index].chasis_number,
            };
          } else {
            return { ...link };
          }
        });

        this.setState({ links: data });
      })
      .catch((error) => {});
  }

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  fetchLinks = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${REGULAR_PATH}/tracker_handler?access_token=${window.localStorage.getItem(
          'user',
        )}`,
      )
      .then(({ data }) => {
        this.setState({
          loading: false,
          links: data,
          message: '',
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  createLink = () => {
    this.setState({ link: '' });
    const { asset_id, timeCount, timeType } = this.state;
    let time = 10;
    if (timeType === 'days') {
      time = timeCount * 24 * 60;
    } else if (timeType === 'hours') {
      time = timeCount * 60;
    }
    const data = JSON.stringify({
      asset_id,
      time,
    });

    axios
      .post(
        `${REGULAR_PATH}/tracker_handler?access_token=${window.localStorage.getItem(
          'user',
        )}`,
        data,
      )
      .then(({ data: { status, data, message = '' } }) => {
        if (status === 'success') {
          this.setState({ link: data, message });
          this.fetchLinks();
        } else if (status === 'failed') {
          this.setState({ message });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  deleteLink = (link) => {
    axios
      .delete(
        `${REGULAR_PATH}/tracker_handler/${link}?access_token=${window.localStorage.getItem(
          'user',
        )}`,
      )
      .then(({ data: { status, message } }) => {
        this.setState({ message, link: '' });
        this.fetchLinks();
      });
  };

  handleLinksFilter = (column, action) => {
    if (column === 'name') {
      this.setState({ filterBy: { name: action, code: false } });
    } else if (column === 'code') {
      this.setState({ filterBy: { name: false, code: action } });
    }
  };

  handleDateSort = (action) => {
    this.setState({ sortBy: { type: action } });
  };

  downloadExcelJS = (links) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Finder GPS Tracking';
    workbook.created = new Date();

    workbook.addWorksheet('Live Share Report');

    const worksheet = workbook.getWorksheet('Live Share Report');

    worksheet.addRow(['Finder GPS Tracking']);
    worksheet.addRow(['Live Share']);
    worksheet.addRow([
      `Generated on ${moment().format('MMMM Do, YYYY, hh:mm:ss A')}`,
    ]);

    worksheet.addRow();

    worksheet.addRow();

    worksheet.columns = [
      { width: 10 },
      { width: 30 },
      { width: 50 },
      { width: 20 },
      { width: 30 },
    ];

    if (
      window.localStorage.getItem('organization') === '6020bcbf1d41c84da5bcb6a3'
    ) {
      worksheet.addRow([
        'No',
        'Name',
        'Chassis Number',
        'Link',
        'Code',
        'Expire',
      ]);
    } else {
      worksheet.addRow(['No', 'Name', 'Link', 'Code', 'Expire']);
    }

    if (
      window.localStorage.getItem('organization') === '6020bcbf1d41c84da5bcb6a3'
    ) {
      links.forEach(
        ({ asset_name, chesis_number, asset_code, expires_at }, idx) =>
          worksheet.addRow([
            idx + 1,
            asset_name,
            chesis_number,
            `${LIVE_SHARE_URL}/${asset_code}`,
            asset_code,
            moment(expires_at.$date).format('MMMM Do, YYYY, hh:mm:ss A'),
          ]),
      );
    } else {
      links.forEach(({ asset_name, asset_code, expires_at }, idx) =>
        worksheet.addRow([
          idx + 1,
          asset_name,
          `${LIVE_SHARE_URL}/${asset_code}`,
          asset_code,
          moment(expires_at.$date).format('MMMM Do, YYYY, hh:mm:ss A'),
        ]),
      );
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/octet-binary',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Live_Share_${moment().format(
        'MMMM Do, YYYY, hh:mm:ss A',
      )}.xlsx`;
      a.click();
      a.remove();
    });
  };

  render() {
    const {
      assetsList,
      asset_id,
      timeCount,
      timeType,
      links,
      link,
      message,
      loading,
      filterBy,
      sortBy,
    } = this.state;

    if (window.localStorage.getItem('org_admin') === 'true') {
      return (
        <Segment>
          <Segment>
            <Header as='h2'>Generate Link</Header>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Dropdown
                placeholder='Select Asset'
                search
                selection
                options={assetsList}
                onChange={this.handleInput}
                value={asset_id}
                name='asset_id'
                style={{ width: '50%' }}
              />
              &nbsp; &nbsp; &nbsp;
              <div>
                <Input
                  label={
                    <Dropdown
                      options={[
                        { key: 'hours', text: 'hours', value: 'hours' },
                        { key: 'days', text: 'days', value: 'days' },
                      ]}
                      onChange={this.handleInput}
                      name='timeType'
                      value={timeType}
                    />
                  }
                  labelPosition='right'
                  placeholder='Input Minute'
                  type='Number'
                  min='1'
                  style={{ width: 200 }}
                  max={timeType === 'days' ? 7 : 23}
                  name='timeCount'
                  value={timeCount}
                  onChange={this.handleInput}
                />
                <Button
                  content='Generate'
                  primary
                  onClick={this.createLink}
                  disabled={!asset_id || !parseInt(timeCount)}
                  style={{ marginLeft: '30px' }}
                />
              </div>
              &nbsp; &nbsp; &nbsp;
              <Button
                color='teal'
                onClick={() => this.downloadExcelJS(links)}
                disabled={links.length === 0}
              >
                Download
              </Button>
            </div>

            {message && <Message header={message} negative />}
          </Segment>

          {link && (
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  margin: '20px 10px',
                  fontSize: '20px',
                }}
              >
                <Label>
                  <Header as='h4'>
                    Generated Link:
                    <a
                      href={`${LIVE_SHARE_URL}/${link}`}
                      style={{ margin: '10px 10px 10px 20px' }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >{`${LIVE_SHARE_URL}/${link}`}</a>
                  </Header>
                </Label>
              </div>
            </Segment>
          )}

          <Segment>
            <Header as='h2'>Generated Links List</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {filterBy.name ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Search
                          onSearchChange={(e, data) =>
                            this.handleLinksFilter('name', data.value)
                          }
                          value={
                            typeof filterBy.name === 'string'
                              ? filterBy.name
                              : ''
                          }
                          placeholder='Search Here...'
                          showNoResults={false}
                          style={{ width: '200px' }}
                        />
                        <Button
                          icon
                          onClick={() => this.handleLinksFilter('name', false)}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                        >
                          <Icon name='close' />
                        </Button>
                      </div>
                    ) : (
                      <span>
                        Name
                        <Icon
                          name='search'
                          onClick={() => this.handleLinksFilter('name', true)}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                        />
                      </span>
                    )}
                  </Table.HeaderCell>
                  {window.localStorage.getItem('organization') ===
                  '6020bcbf1d41c84da5bcb6a3' ? (
                    <Table.HeaderCell>Chassis Number</Table.HeaderCell>
                  ) : null}
                  <Table.HeaderCell>Link</Table.HeaderCell>
                  <Table.HeaderCell>
                    {filterBy.code ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Search
                          onSearchChange={(e, data) =>
                            this.handleLinksFilter('code', data.value)
                          }
                          value={
                            typeof filterBy.code === 'string'
                              ? filterBy.code
                              : ''
                          }
                          placeholder='Search Here...'
                          showNoResults={false}
                          style={{ width: '200px' }}
                        />
                        <Button
                          icon
                          onClick={() => this.handleLinksFilter('code', false)}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                        >
                          <Icon name='close' />
                        </Button>
                      </div>
                    ) : (
                      <span>
                        Code
                        <Icon
                          name='search'
                          onClick={() => this.handleLinksFilter('code', true)}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                        />
                      </span>
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Expire
                    <Icon
                      name={
                        sortBy.type === 'asc'
                          ? 'sort ascending'
                          : sortBy.type === 'dsc'
                          ? 'sort descending'
                          : 'sort'
                      }
                      onClick={() =>
                        this.handleDateSort(
                          sortBy.type === 'asc' ? 'dsc' : 'asc',
                        )
                      }
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    />
                  </Table.HeaderCell>

                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {links
                  .filter(({ asset_name, asset_code }) => {
                    if (typeof filterBy.name === 'string' && filterBy.name) {
                      return asset_name
                        .toLowerCase()
                        .includes(filterBy.name.toLowerCase());
                    } else if (
                      typeof filterBy.code === 'string' &&
                      filterBy.code
                    ) {
                      return asset_code
                        .toLowerCase()
                        .includes(filterBy.code.toLowerCase());
                    } else {
                      return true;
                    }
                  })
                  .sort((a, b) => {
                    const { type } = sortBy;
                    if (type === 'asc') {
                      return a.expires_at.$date - b.expires_at.$date;
                    } else if (type === 'dsc') {
                      return b.expires_at.$date - a.expires_at.$date;
                    }
                  })
                  .map((link) => (
                    <Table.Row key={link.asset_code}>
                      <Table.Cell>{link.asset_name}</Table.Cell>
                      {window.localStorage.getItem('organization') ===
                      '6020bcbf1d41c84da5bcb6a3' ? (
                        <Table.Cell>{link.chesis_number}</Table.Cell>
                      ) : null}
                      <Table.Cell>
                        <a
                          href={`${LIVE_SHARE_URL}/${link.asset_code}`}
                          disabled={moment(link.expires_at.$date).isBefore(
                            moment(),
                          )}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {`${LIVE_SHARE_URL}/${link.asset_code}`}
                        </a>
                      </Table.Cell>
                      <Table.Cell>{link.asset_code}</Table.Cell>
                      <Table.Cell>
                        <span
                          style={{
                            color: moment(link.expires_at.$date).isBefore(
                              moment(),
                            )
                              ? '#fa5252'
                              : '#212121',
                          }}
                        >
                          {moment(link.expires_at.$date).format(
                            'ddd MMM Do YYYY, h:mm a',
                          )}
                        </span>
                      </Table.Cell>

                      <Table.Cell>
                        <Popup
                          trigger={
                            <Button basic negative>
                              Delete
                            </Button>
                          }
                          content={
                            <Button
                              onClick={() => this.deleteLink(link.asset_code)}
                              negative
                            >
                              Yes
                            </Button>
                          }
                          on='click'
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {loading && (
              <Table.Row>
                <Loader active />
              </Table.Row>
            )}
          </Segment>
        </Segment>
      );
    } else {
      return <h2>You are not authorized person.</h2>;
    }
  }
}

export default ShareView;

import React from 'react';
import './speedIcon.css';

const SpeedMeter = (props) => {
  return (
    <div className='legend'>
      {props.distance && (
        <div>
          <div>Total Distance </div>
          <div style={style.distance}>
            <b>{props.distance} km</b>
          </div>
        </div>
      )}

      <div className='outline'>
        <i className='icon' style={{ background: '#6d6f6f' }} />
        <span className='info-label'>Stop</span>
      </div>

      <div className='outline'>
        <i className='icon' style={{ background: '#fcb66f' }} />
        <span className='info-label'>5+ km/h</span>
      </div>

      <div className='outline'>
        <i className='icon' style={{ background: '#6db6fe' }} />
        <span className='info-label'>25+ km/h</span>
      </div>

      <div className='outline'>
        <i className='icon' style={{ background: '#4db64c' }} />
        <span className='info-label'>60+ km/h</span>
      </div>

      <div className='outline'>
        <i className='icon' style={{ background: '#b74dfe' }} />
        <span className='info-label'>80+ km/h</span>
      </div>

      <div className='outline'>
        <i className='icon' style={{ background: '#db4d4c' }} />
        <span className='info-label'>110+ km/h</span>
      </div>
    </div>
  );
};

const style = {
  distance: {
    textAlign: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    paddingBottom: '4px',
    marginBottom: '7px',
    fontWeight: 'bold',
  },
};

export default SpeedMeter;

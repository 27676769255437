import React from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import Message from '../Message.js';
import moment from 'moment';
import { postData } from '../dataHelper';

class Add extends React.Component {
  state = {
    plateNumberList: this.props.plateNumberList,
    registrationNumber: '',
    startDate: moment(),
    place: '',
    damageItems: this.props.titles,
    damageItem: this.props.titles[0],
    particulars: '',
    branches: this.props.branches,
    branch: this.props.branches[0],
    remarks: '',
    cost: '',
    submitted: false,
  };

  handleStartDateChange = (value) => {
    this.setState({
      startDate: value,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ registrationNumber: data.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ messageShow: false, submitted: true });
    const {
      registrationNumber,
      startDate,
      place,
      damageItem,
      particulars,
      branch,
      remarks,
      cost,
    } = this.state;

    const data = {
      asset_id: registrationNumber,
      org_id: window.localStorage.getItem('organization'),
      time: moment(startDate).unix(),
      place,
      damage_item: damageItem,
      particulars,
      current_branch: branch,
      remarks,
      cost: cost ? cost : 0,
    };

    postData(`${this.props.org}_incident`, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            messageType: 'positive',
            messageInfo: message,
            submitted: false,
            startDate: null,
            place: '',
            damageItem: '',
            particulars: '',
            branch: '',
            remarks: '',
            cost: '',
          });
          setTimeout(() => {
            this.setState({ messageShow: false });
          }, 3000);
        } else if (status === 'failed') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'warning',
            submitted: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitted: false });
      });
  };

  render() {
    const {
      plateNumberList,
      startDate,
      messageShow,
      messageType,
      messageInfo,
      place,
      damageItem,
      particulars,
      branch,
      registrationNumber,
      remarks,
      damageItems,
      branches,
      cost,
      submitted,
    } = this.state;

    return (
      <div style={{ margin: '10px auto', width: '70%' }}>
        <Form>
          <Form.Field>
            <label>Date</label>
            <DatePicker
              selected={startDate}
              onChange={this.handleStartDateChange}
              dateFormat='LLL'
              showTimeSelect
            />
          </Form.Field>

          <Form.Field>
            <label>Registration No.</label>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={registrationNumber}
            />
          </Form.Field>

          <Form.Field>
            <label name='place'>Place</label>
            <input
              value={place}
              onChange={this.handleChange}
              name='place'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='damageItem'>Damage Item</label>
            <select
              placeholder='Select Damage Item'
              name='damageItem'
              value={damageItem}
              onChange={this.handleChange}
              style={{ textTransform: 'capitalize' }}
            >
              {damageItems.map((item) => (
                <option value={item} key={item}>
                  {item.split('_').join(' ')}
                </option>
              ))}
            </select>
          </Form.Field>

          <Form.Field>
            <label name='particulars'>Particulars</label>
            <input
              value={particulars}
              onChange={this.handleChange}
              name='particulars'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='branch'>Branch</label>
            <select
              name='branch'
              value={branch}
              onChange={this.handleChange}
              style={{ textTransform: 'capitalize' }}
            >
              {branches.map((item) => (
                <option value={item} key={item}>
                  {item.split('_').join(' ')}
                </option>
              ))}
            </select>
          </Form.Field>

          <Form.Field>
            <label name='remarks'>Remarks</label>
            <textarea
              value={remarks}
              onChange={this.handleChange}
              name='remarks'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='cost'>Cost</label>
            <input
              value={cost}
              onChange={this.handleChange}
              name='cost'
              type='text'
            />
          </Form.Field>
        </Form>

        <br />
        {messageShow && <Message type={messageType} info={messageInfo} />}

        <Button
          primary
          disabled={submitted}
          loading={submitted}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default Add;

import React from 'react';
import {
  Icon,
  Button,
  Header,
  Loader,
  Label,
  Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { PieChart, Pie, Cell } from 'recharts';

import { alertMessage } from '../../../helper/alert-message';
import { reportDownloadExcel, reportView } from '../helper/dataService';
import {
  getIdsAndTime,
  getActiveAssetList,
  getDuration,
  getOrg,
} from '../helper/helper';

import reportsEnv from '../reportsEnv';

const { durationLimit } = reportsEnv.locationLiveB;

class LocationLiveB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      excelDisableBtn: false,
      selectedAssets: [],
      data: {},
      graphData: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      withDistance: false,
    };
  }

  componentDidMount() {
    const { startTime, endTime } = JSON.parse(
      window.localStorage.getItem('reports_state'),
    );

    this.setState({
      selectedAssets: getActiveAssetList().map((item) => item.value),
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      graphData: [],
      data: {},
    });
  };

  downloadReport = () => {
    this.setState({ active: true });
    const { ids } = getIdsAndTime(this.state);

    reportView(reportsEnv.locationLiveB, {
      asset_ids: ids,
      org_oid: getOrg(),
    })
      .then((response) => {
        const data = response.data;
        this.setState({
          data: data,
          graphData: Object.keys(data).map((item) => ({
            name: item,
            value: data[item],
          })),
          active: false,
        });
      })
      .catch(() => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportDownloadExcel(reportsEnv.locationLiveB, {
      asset_ids: ids,
      org_oid: getOrg(),
      from: startTime,
      to: endTime,
      with_distance: this.state.withDistance,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Live_B_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDistanceSelect = (e, data) => {
    this.setState({ withDistance: data.checked });
  };

  render() {
    const {
      active,
      selectedAssets,
      excelDisableBtn,
      data,
      graphData,
      startTime,
      endTime,
      withDistance,
    } = this.state;

    const COLORS = {
      active: '#00C49F',
      inactive: '#FFBB28',
      na: '#FF8042',
    };

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    }

    const title = `Location Live [B] [${durationLimit} days]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;

    return (
      <div>
        {!active && graphData.length <= 0 && (
          <div className='center-report'>
            <Header as='h2'>{title}</Header>
            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets [Default all assets are selected]
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            <div style={{ margin: '5px 0 25px 0' }}>
              <Checkbox
                label='With Distance?'
                onClick={this.handleDistanceSelect}
                checked={withDistance}
              />
            </div>

            {assetsQuantity > 0 && isValidDuration && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='SUMMARY'
                  disabled={excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && graphData.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Location Live [B]</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>

              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>
            <Header as='h3'>Device Status</Header>
            <div
              className='left-right'
              style={{ marginTop: '30px', justifyContent: 'flex-start' }}
            >
              <div>
                <Header as='h4'>
                  <span
                    className='custom-dot'
                    style={{ backgroundColor: COLORS.active }}
                  />
                  Active - {data.active}
                </Header>
                <Header as='h4'>
                  <span
                    className='custom-dot'
                    style={{ backgroundColor: COLORS.inactive }}
                  />
                  Inactive - {data.inactive}
                </Header>
                <Header as='h4'>
                  <span
                    className='custom-dot'
                    style={{ backgroundColor: COLORS.na }}
                  />
                  Not Available - {data.na}
                </Header>
              </div>
              <PieChart width={730} height={250}>
                <Pie
                  data={graphData}
                  dataKey='value'
                  nameKey='name'
                  cx='50%'
                  cy='50%'
                  outerRadius={100}
                  label
                >
                  {graphData.map(({ name }) => (
                    <Cell key={name} fill={COLORS[name]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default LocationLiveB;

import React from 'react';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportDownloadExcel,
  reportDownloadPdf,
  reportView,
} from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.rawData;

class RawData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      interval: 10,
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  onChangeView = (e) => {
    this.setState({
      interval: parseInt(e.target.value),
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });
    const { interval } = this.state;
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportView(reportsEnv.rawData, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      interval,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { interval } = this.state;
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadPdf(reportsEnv.rawData, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      interval,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Raw_Data_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });
    const { interval } = this.state;

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(reportsEnv.rawData, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      interval,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Raw_Data_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      interval,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Raw Data [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            <div style={{ margin: '10px 0 20px 0' }}>
              <select
                className='select-semantic'
                onChange={this.onChangeView}
                value={this.state.interval}
              >
                <option value='0'>0 Minutes</option>
                <option value='10'>10 Minutes</option>
                <option value='20'>20 Minutes</option>
                <option value='30'>30 Minutes</option>
                <option value='60'>60 Minutes</option>
                <option value='90'>90 Minutes</option>
                <option value='120'>120 Minutes</option>
                <option value='150'>150 Minutes</option>
                <option value='180'>180 Minutes</option>
              </select>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file pdf outline'
                  content='Download PDF'
                  onClick={this.handleDownloadPdfBtn}
                  disabled={pdfDisableBtn || excelDisableBtn}
                  loading={pdfDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Raw Data [{interval} Minutes]</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />

            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h2 className='ui center aligned icon header'>
                          <i className='circular car icon' />
                          Distance: {parseInt(item.distance)} km
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Time Of Arrival</Table.HeaderCell>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.HeaderCell>Nearest Landmark</Table.HeaderCell>
                      <Table.HeaderCell>Bearing</Table.HeaderCell>
                      <Table.HeaderCell>Speed</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {item.last_data.map((last, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell>
                          {last.data.time
                            ? moment(last.data.time.$date).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )
                            : ''}
                        </Table.Cell>
                        <Table.Cell>
                          {last.data.loc.coordinates[0]}
                          <br />
                          {last.data.loc.coordinates[1]}
                        </Table.Cell>
                        <Table.Cell>
                          {last.nlm.map((res, index) => (
                            <p key={index}>
                              <i className='marker icon' />
                              {res.name}
                            </p>
                          ))}
                        </Table.Cell>
                        <Table.Cell>{last.data.bearing}</Table.Cell>
                        <Table.Cell>{last.data.speed} km/h</Table.Cell>
                        <Table.Cell>{last.data.status}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default RawData;

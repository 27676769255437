import React from 'react';
import {
  Table,
  Popup,
  Button,
  Pagination,
  Icon,
  Loader,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { getData, deleteData } from '../dataHelper';
import moment from 'moment';
import MessageInfo from '../Message';

class FutureTasks extends React.Component {
  state = {
    isConfirm: false,
    limit: 10,
    completed: false,
    asset_id: '',
    plateNumberList: this.props.plateNumberList,
    plateNumber: '',
    data: [],
    activePage: 1,
    totalPages: 1,
    messageShow: false,
    loaderActive: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.plateNumber !== this.state.plateNumber) {
      this.fetchData();
    }
    if (prevState.activePage !== this.state.activePage) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ data: [], loaderActive: true });

    const { activePage, limit, completed, plateNumber } = this.state;

    const asset_id = plateNumber ? plateNumber : '';

    getData(
      `${this.props.org}_incident`,
      `page=${activePage}&limit=${limit}&completed=${completed}&asset_id=${asset_id}`,
    ).then((response) => {
      const data = response.data;
      this.setState({
        data: data.data,
        totalPages: Math.ceil(data.total / data.limit),
        loaderActive: false,
      });
    });
  };

  handleDelete = (id) => {
    this.setState({ isConfirm: false, data: [] });
    deleteData(`${this.props.org}_incident`, id)
      .then((response) => {
        this.setState({ messageShow: true, data: [] });
        this.fetchData();
        setTimeout(() => this.setState({ messageShow: false }), 3000);
      })
      .catch((error) => error);
  };

  pagination = (e, data) => {
    this.setState({ activePage: data.activePage });
  };

  handleConfirm = (confirm) => {
    this.setState({ isConfirm: confirm });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ plateNumber: data.value });
  };

  handleAll = () => {
    this.setState({ plateNumber: '' });
  };

  render() {
    const {
      isConfirm,
      plateNumberList,
      plateNumber,
      data,
      activePage,
      totalPages,
      messageShow,
      loaderActive,
    } = this.state;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 15px',
            padding: '15px 0',
          }}
        >
          <div>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={plateNumber}
            />
            <Checkbox
              toggle
              onClick={this.handleAll}
              style={{ marginLeft: '10px' }}
              label='All'
              checked={plateNumber ? false : true}
            />
          </div>
          {data.length > 0 && (
            <div>
              <Pagination
                activePage={activePage}
                pointing
                secondary
                totalPages={totalPages}
                onPageChange={(e, data) => this.pagination(e, data)}
                firstItem={{
                  content: <Icon name='angle double left' />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name='angle double right' />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          )}
        </div>

        {messageShow && (
          <MessageInfo type='warning' info='Successfully deleted.' />
        )}

        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sl No.</Table.HeaderCell>
              <Table.HeaderCell>Registration Number</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Place</Table.HeaderCell>
              <Table.HeaderCell>Damage Item</Table.HeaderCell>
              <Table.HeaderCell>Particulars</Table.HeaderCell>
              <Table.HeaderCell>Branch</Table.HeaderCell>
              <Table.HeaderCell>Remarks</Table.HeaderCell>
              <Table.HeaderCell>Cost</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Last Edited</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.length > 0 &&
              data.map((item, id) => (
                <Table.Row key={item._id.$oid}>
                  <Table.Cell>{id + 1}</Table.Cell>
                  <Table.Cell>{item.asset_name}</Table.Cell>
                  <Table.Cell>
                    {item.time
                      ? moment(item.time.$date).format(
                          'dddd, MMMM Do, YYYY, hh:mm:ss A',
                        )
                      : ''}
                  </Table.Cell>
                  <Table.Cell>{item.place}</Table.Cell>
                  <Table.Cell style={{ textTransform: 'capitalize' }}>
                    {item.damage_item.split('_').join(' ')}
                  </Table.Cell>
                  <Table.Cell>{item.particulars}</Table.Cell>
                  <Table.Cell style={{ textTransform: 'capitalize' }}>
                    {item.current_branch.split('_').join(' ')}
                  </Table.Cell>
                  <Table.Cell>{item.remarks}</Table.Cell>
                  <Table.Cell>{item.cost}</Table.Cell>
                  <Table.Cell>
                    {item.created_on
                      ? moment(item.created_on.$date).format(
                          'dddd, MMMM Do, YYYY, hh:mm:ss A',
                        )
                      : ''}
                    {` by `}
                    {item.created_by_email}
                  </Table.Cell>
                  <Table.Cell>
                    {item.modified_on
                      ? moment(item.modified_on.$date).format(
                          'dddd, MMMM Do, YYYY, hh:mm:ss A',
                        )
                      : ''}
                    {` by `}
                    {item.modified_by_email}
                  </Table.Cell>

                  <Table.Cell>
                    <Button
                      positive
                      onClick={() => this.props.handleEdit(true, item, 'Edit')}
                      style={{ width: '100%', marginBottom: '10px' }}
                    >
                      Edit
                    </Button>
                    <Popup
                      trigger={
                        <Button
                          onClick={() => this.handleConfirm(item._id.$oid)}
                          style={{ width: '100%', marginBottom: '10px' }}
                        >
                          Delete
                        </Button>
                      }
                      content={
                        <div>
                          <Button
                            negative
                            onClick={() => this.handleDelete(item._id.$oid)}
                          >
                            Yes
                          </Button>
                          <Button onClick={() => this.handleConfirm(false)}>
                            No
                          </Button>
                        </div>
                      }
                      open={isConfirm === `${item._id.$oid}`}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <div style={{ textAlign: 'center' }}>
          <Loader active={loaderActive} />
          {!loaderActive && data.length === 0 && <h3>No Data Found</h3>}
        </div>
      </div>
    );
  }
}

export default FutureTasks;

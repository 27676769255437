import React from 'react';
import { Icon, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import { alertMessage } from '../../../helper/alert-message';
import { reportDownloadExcel } from '../helper/dataService';
import {
  getIdsAndTime,
  getDuration,
  getActiveAssetList,
  getOrg,
} from '../helper/helper';

import reportsEnv from '../reportsEnv';

const { durationLimit } = reportsEnv.distanceB;

class DistanceB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
    };
  }

  componentDidMount() {
    const { startTime, endTime } = JSON.parse(
      window.localStorage.getItem('reports_state'),
    );

    this.setState({
      selectedAssets: getActiveAssetList().map((item) => item.value),
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportDownloadExcel(reportsEnv.distanceB, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      org_oid: getOrg(),
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Distance_B_Report${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    }

    const title = `Distance [B] [${durationLimit} days]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;

    return (
      <div className='center-report'>
        <Header as='h2'>{title}</Header>

        <div style={{ margin: '20px 0' }}>
          <Label.Group size='large'>
            <Label>
              <Icon name='car' />
              {assetsQuantity} assets [Default all assets are selected]
            </Label>
            <Label>
              <Icon name='calendar' />
              {duration} days
            </Label>
            <Label>
              <Icon name='play' />
              {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
            </Label>
            <Label>
              <Icon name='stop' />
              {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
            </Label>
          </Label.Group>
        </div>

        {assetsQuantity > 0 && isValidDuration && (
          <Button.Group>
            <Button
              color='teal'
              style={{ width: '200px', fontSize: '16px' }}
              icon='file excel outline'
              content='Download Excel'
              onClick={this.handleDownloadExcelBtn}
              loading={excelDisableBtn}
              disabled={excelDisableBtn || pdfDisableBtn}
            />
          </Button.Group>
        )}
      </div>
    );
  }
}

export default DistanceB;

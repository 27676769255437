import React from 'react';
import SignUpForm from './sign-up-form';

class SignUp extends React.Component {
  render() {
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <SignUpForm />
        </div>
      </div>
    );
  }
}

export default SignUp;

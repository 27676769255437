import React from 'react';
import { Button, Loader, Table, Search, Popup } from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../../env';
import moment from 'moment';
const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class BillList extends React.Component {
  state = { submit: false, loading: true, data: [], search: '' };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${REGULAR_PATH}/syngenta_bills?org_oid=${org()}&access_token=${token()}`,
      )
      .then((response) => {
        this.setState({ loading: false, data: response.data });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  changePaidStatus = (id) => {
    this.setState({ submit: true });
    axios
      .put(
        `${REGULAR_PATH}/syngenta_bills?access_token=${token()}`,
        JSON.stringify({
          bill_oid: id,
          paid: true,
        }),
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({ status, submit: false });
        if (status === 'status updated') {
          this.fetchList();
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleSearch = (event, data) => {
    this.setState({ search: data.value.toLowerCase() });
  };

  handleDelete = (id) => {
    this.setState({ submit: true });
    axios
      .delete(
        `${REGULAR_PATH}/syngenta_bills?bill_oid=${id}&access_token=${token()}`,
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({ status, submit: false });
        if (status === 'Bill deleted') {
          this.fetchList();
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  render() {
    const { loading, data, search, submit } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div>
        <Search
          onSearchChange={this.handleSearch}
          value={search}
          placeholder='Search Here'
          showNoResults={false}
          style={{ width: '300px  ' }}
        />

        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Geofence</Table.HeaderCell>
              <Table.HeaderCell>Asset</Table.HeaderCell>
              <Table.HeaderCell>Area [Decimal]</Table.HeaderCell>
              <Table.HeaderCell>Amount [tk]</Table.HeaderCell>
              <Table.HeaderCell>Bill Date</Table.HeaderCell>
              <Table.HeaderCell>Paid</Table.HeaderCell>
              <Table.HeaderCell>Paid Date</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data
              .sort((a, b) => {
                if (Number(a.bill_date.$date) < Number(b.bill_date.$date))
                  return 1;

                if (Number(a.bill_date.$date) > Number(b.bill_date.$date))
                  return -1;

                return 0;
              })
              .map(
                ({
                  _id,
                  geofence_name,
                  asset_name,
                  area,
                  amount,
                  bill_date,
                  paid,
                  paid_on,
                }) => ({
                  id: _id.$oid,
                  geofence_name,
                  asset_name,
                  area,
                  amount,
                  bill_date: bill_date
                    ? moment(bill_date.$date).format('dddd, MMMM Do, YYYY')
                    : '',
                  paid,
                  paid_on: paid_on
                    ? moment(paid_on.$date).format('dddd, MMMM Do, YYYY')
                    : '',
                }),
              )
              .filter(
                ({ geofence_name, asset_name, bill_date, paid_on, paid }) =>
                  geofence_name.toLowerCase().includes(search) ||
                  asset_name.toLowerCase().includes(search) ||
                  bill_date.toLowerCase().includes(search) ||
                  (paid ? 'yes' : 'no').includes(search) ||
                  paid_on.toLowerCase().includes(search),
              )
              .map(
                ({
                  id,
                  geofence_name,
                  asset_name,
                  area,
                  amount,
                  bill_date,
                  paid,
                  paid_on,
                }) => (
                  <Table.Row key={id}>
                    <Table.Cell>{geofence_name}</Table.Cell>
                    <Table.Cell>{asset_name}</Table.Cell>
                    <Table.Cell>
                      {area ? Number(area).toFixed(2) : 0}
                    </Table.Cell>
                    <Table.Cell>
                      {amount ? Number(amount).toFixed(2) : 0}
                    </Table.Cell>
                    <Table.Cell>{bill_date}</Table.Cell>
                    <Table.Cell>{paid ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{paid_on}</Table.Cell>
                    <Table.Cell>
                      {paid ? null : (
                        <Button
                          onClick={() => this.changePaidStatus(id)}
                          disabled={submit}
                        >
                          Paid ?
                        </Button>
                      )}
                      {paid ? null : (
                        <Popup
                          on='click'
                          trigger={<Button disabled={submit}>Delete</Button>}
                        >
                          <Button onClick={() => this.handleDelete(id)}>
                            Yes
                          </Button>
                        </Popup>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default BillList;

import React from 'react';
import { Message } from 'semantic-ui-react';

class MessageInfo extends React.Component {
  render() {
    const { type, info } = this.props;
    const color = type === 'positive' ? 'green' : 'red';
    return (
      <Message style={{ textTransform: 'capitalize' }} color={color}>
        <Message.Header>{info}</Message.Header>
      </Message>
    );
  }
}

export default MessageInfo;

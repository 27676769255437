import React from 'react';
import { Icon, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import { alertMessage } from '../../../helper/alert-message';
import { reportDownloadExcel } from '../helper/dataService';
import { getIdsAndTime, getActiveAssetList, getOrg } from '../helper/helper';

import reportsEnv from '../reportsEnv';

class LocationLiveD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      excelDisableBtn: false,
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
    };
  }

  componentDidMount() {
    const { startTime, endTime } = JSON.parse(
      window.localStorage.getItem('reports_state'),
    );

    this.setState({
      selectedAssets: getActiveAssetList().map((item) => item.value),
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportDownloadExcel(reportsEnv.locationLiveB, {
      asset_ids: ids,
      org_oid: getOrg(),
      from: startTime,
      to: endTime,
      with_distance: false,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Live_D_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch(() => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const { selectedAssets, excelDisableBtn } = this.state;

    const assetsQuantity = selectedAssets.length;

    return (
      <div className='center-report'>
        <Header as='h2'>Location Live [D]</Header>
        <div style={{ margin: '20px 0' }}>
          <Label.Group size='large'>
            <Label>
              <Icon name='car' />
              {assetsQuantity} assets [Default all assets are selected]
            </Label>
          </Label.Group>
        </div>

        {assetsQuantity > 0 && (
          <Button.Group>
            <Button
              color='teal'
              style={{ width: '200px', fontSize: '16px' }}
              icon='file excel outline'
              content='Download Excel'
              onClick={this.handleDownloadExcelBtn}
              disabled={excelDisableBtn}
              loading={excelDisableBtn}
            />
          </Button.Group>
        )}
      </div>
    );
  }
}

export default LocationLiveD;

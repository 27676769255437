import React from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Header,
  Dropdown,
} from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH } from '../../../../env';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getActiveAssetList } from '../../reports/helper/helper';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class Add extends React.Component {
  state = {
    assetList: [],
    assetOid: '',
    deliveryDate: '',
    cin: '',
    name: '',
    unit: '',
    mobile: '',
    place: '',
    modelNumber: '',
    submit: false,
    status: '',
  };

  componentDidMount() {
    this.setState({
      assetList: getActiveAssetList(),
    });
  }

  saveData = () => {
    const {
      assetOid,
      deliveryDate,
      cin,
      name,
      mobile,
      unit,
      place,
      assetList,
      modelNumber,
    } = this.state;

    const data = {
      org_oid: org(),
      asset_oid: assetOid,
      asset_name: assetList.find((item) => item.key === assetOid).name,
      delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
      cin: cin,
      customer_name: name,
      unit,
      mobile_no: mobile,
      region: place,
      model_number: modelNumber,
    };

    this.setState({ submit: true, status: '' });

    axios
      .post(
        `${BASE_PATH}/v1.2/reports/runners_asset?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const message = response.data.message;
        this.setState({ status: message, submit: false });
        if (message === 'Success') {
          this.setState({
            assetOid: '',
            deliveryDate: '',
            cin: '',
            name: '',
            mobile: '',
            unit: '',
            place: '',
            modelNumber: '',
          });
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  handleDeliveryDate = (value) => {
    this.setState({
      deliveryDate: value,
    });
  };

  render() {
    const {
      assetList,
      assetOid,
      deliveryDate,
      cin,
      name,
      unit,
      mobile,
      place,
      modelNumber,
      submit,
      status,
    } = this.state;

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Form>
          <Form.Field
            control={Dropdown}
            fluid
            search
            selection
            options={assetList}
            label='Asset'
            placeholder='Select Asset'
            value={assetOid}
            onChange={this.handleInput}
            name='assetOid'
          />
        </Form>
        <br />
        {assetOid ? (
          <div>
            <Form>
              <Form.Field
                control={Input}
                label='CIN'
                onChange={this.handleInput}
                value={cin}
                name='cin'
              />
              <Form.Field
                control={Input}
                label='Customer Name'
                onChange={this.handleInput}
                value={name}
                name='name'
              />
              <Form.Field
                control={Input}
                label='Mobile Number'
                onChange={this.handleInput}
                value={mobile}
                name='mobile'
              />
              <Form.Field
                control={Input}
                label='Unit'
                onChange={this.handleInput}
                value={unit}
                name='unit'
              />
              <Form.Field
                control={Input}
                label='Model'
                onChange={this.handleInput}
                value={modelNumber}
                name='modelNumber'
              />
            </Form>
            <Header as='h5'>Vehicle Delivery Date</Header>
            <div className='date-time-report'>
              <DatePicker
                selected={deliveryDate}
                onChange={this.handleDeliveryDate}
                dateFormat='LL'
              />
            </div>
            <Form>
              <Form.Field
                control={Input}
                label='Region/Place'
                onChange={this.handleInput}
                value={place}
                name='place'
              />
            </Form>
          </div>
        ) : null}

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          disabled={submit}
        >
          Submit
        </Button>

        {status === 'Success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'Success' && (
          <Message negative> {status}</Message>
        )}
      </div>
    );
  }
}

export default Add;

import React from 'react';
import {
  Segment,
  Table,
  Dropdown,
  Header,
  Icon,
  Button,
  Divider,
  Loader,
  Confirm,
  Dimmer,
  Message,
  Input,
} from 'semantic-ui-react';
import { Map, Polygon } from 'react-leaflet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';
import MapLayers from '../MapLayers';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class Geofence extends React.Component {
  state = {
    geofenceList: [],
    geofenceLoading: true,
    currentGeofence: null,
    currentGeofenceName: null,
    assets: [],
    polygon: [],
    position: [],
    geofenceWithAssets: {},
    deleteGeofenceId: '',
    message: false,
    negativeMessage: false,
    submitted: false,
    editNameForm: false,
    editName: '',
  };

  componentDidMount() {
    setTimeout(() => {
      this.fetchGeoList();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevSate) {
    if (
      this.state.currentGeofence &&
      prevSate.currentGeofence !== this.state.currentGeofence
    ) {
      this.zoomToPolygon();
    }
  }

  fetchGeoList = () => {
    this.setState({ geofenceLoading: true });
    axios
      .get(`${REGULAR_PATH}/geofence?org_id=${org()}&access_token=${token()}`)
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            id: item._id.$oid,
            name: item.name.trim(),
            assetList: item.enforced_assets.map((asset) => asset._id.$oid),
            coordinates: item.polygon.geometry.coordinates[0],
          };
        });

        this.setState({
          geofenceLoading: false,
          geofenceList: data,
          assets: JSON.parse(window.localStorage.getItem('assetList'))
            .filter((item) => item.active)
            .map((item, index) => ({
              key: item.label,
              text: item.label,
              value: item.value,
            })),
        });
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  handleAssetSelect = (e, data) => {
    const { name, value } = data;
    const newValue = [...value];

    const { geofenceList, geofenceWithAssets } = this.state;

    const geofenceAssetList = Object.assign({}, geofenceWithAssets);
    delete geofenceAssetList[name];

    const geofence = geofenceList.find((item) => item.id === name);
    const assetList = [...geofence.assetList];

    if (JSON.stringify(assetList.sort()) === JSON.stringify(newValue.sort())) {
      this.setState({ geofenceWithAssets: geofenceAssetList });
    } else {
      geofenceAssetList[name] = value;
      this.setState({ geofenceWithAssets: geofenceAssetList });
    }
  };

  showPolygon = (id) => {
    const { geofenceList, currentGeofence } = this.state;

    if (id !== currentGeofence) {
      const geofence = geofenceList.find((item) => item.id === id);
      const polygon = JSON.parse(JSON.stringify(geofence.coordinates)).map(
        (item) => item.reverse(),
      );

      this.setState({
        currentGeofence: id,
        polygon: polygon,
        currentGeofenceName: geofence.name,
      });
    }
  };

  zoomToPolygon = () => {
    this.refs.leaflet.leafletElement.fitBounds(
      this.refs.leafletPolygon.leafletElement.getBounds(),
    );
  };

  showList = () => {
    this.setState({
      polygon: [],
      currentGeofence: null,
      currentGeofenceName: null,
    });
  };

  updateAssetList = (id) => {
    this.setState({ submitted: true });
    const { geofenceWithAssets, geofenceList } = this.state;

    axios
      .put(
        `${REGULAR_PATH}/geofence?geo_id=${id}&access_token=${token()}`,
        JSON.stringify({
          enforced_assets: geofenceWithAssets[id],
        }),
      )
      .then(() => {
        const newGeofenceWithAssets = Object.assign({}, geofenceWithAssets);
        const newGeofenceList = geofenceList.map((geo) => {
          if (geo.id === id) {
            geo.assetList = geofenceWithAssets[id];
            return geo;
          }
          return geo;
        });

        delete newGeofenceWithAssets[id];

        this.setState({
          geofenceWithAssets: newGeofenceWithAssets,
          geofenceList: newGeofenceList,
          submitted: false,
        });
        this.showMessage('Successfully Asset List Updated');
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  updateGeofenceName = (id) => {
    this.setState({ submitted: true });
    const { geofenceList, editName } = this.state;

    axios
      .put(
        `${REGULAR_PATH}/geofence?geo_id=${id}&access_token=${token()}`,
        JSON.stringify({
          name: editName,
        }),
      )
      .then(() => {
        this.setState({
          geofenceList: geofenceList.map((geo) => {
            if (geo.id === id) {
              geo.name = editName;
              return geo;
            }
            return geo;
          }),
          submitted: false,
          editNameForm: false,
        });
        this.showMessage('Successfully Asset Name Updated');
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  handleDeleteConfirm = (id) => {
    this.setState({ deleteGeofenceId: id });
  };

  deleteGeofence = () => {
    this.setState({ submitted: true, deleteGeofenceId: false });
    const { geofenceWithAssets, geofenceList, deleteGeofenceId } = this.state;

    axios
      .delete(
        `${REGULAR_PATH}/geofence?geo_id=${deleteGeofenceId}&access_token=${token()}`,
      )
      .then(() => {
        const newGeofenceWithAssets = Object.assign({}, geofenceWithAssets);
        const newGeofenceList = geofenceList.filter(
          (geo) => geo.id !== deleteGeofenceId,
        );

        delete newGeofenceWithAssets[deleteGeofenceId];

        this.setState({
          geofenceWithAssets: newGeofenceWithAssets,
          geofenceList: newGeofenceList,
        });

        this.showMessage('Geofence Successfully Deleted');
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  showMessage = (message) => {
    this.setState({
      message,
      submitted: false,
    });
    const hideMessage = setTimeout(() => {
      this.setState({
        message: false,
      });
      clearTimeout(hideMessage);
    }, 2000);
  };

  showNegMessage = () => {
    this.setState({
      negativeMessage: true,
      submitted: false,
    });
    const hideNegMessage = setTimeout(() => {
      this.setState({
        negativeMessage: false,
      });
      clearTimeout(hideNegMessage);
    }, 2000);
  };

  render() {
    const {
      geofenceList,
      assets,
      polygon,
      currentGeofenceName,
      currentGeofence,
      geofenceWithAssets,
      deleteGeofenceId,
      geofenceLoading,
      message,
      negativeMessage,
      submitted,
      editNameForm,
      editName,
    } = this.state;

    if (geofenceLoading) {
      return (
        <Dimmer active>
          <Loader size='large'>Loading...</Loader>
        </Dimmer>
      );
    }

    return (
      <div>
        {/* ...LIST... */}
        <Segment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0 20px 0',
              fontSize: '18px',
              fontWeight: '400',
            }}
          >
            <Header as='h3'>
              <Icon name='life ring' />
              Geofence List
            </Header>
            <Link to={`/geofence/draw`}>
              <Button
                content='Create Geofence'
                icon='pencil'
                labelPosition='right'
                primary
                size='small'
              />
            </Link>
          </div>

          {message && <Message icon='check' header={message} color='green' />}
          {negativeMessage && (
            <Message
              icon='check'
              header={'Sorry. Something went wrong.'}
              color='red'
            />
          )}

          <div
            style={{
              display: `${
                currentGeofenceName && currentGeofence ? 'none' : 'block'
              }`,
            }}
          >
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width='3'>Name</Table.HeaderCell>
                  <Table.HeaderCell width='10'>Asset List</Table.HeaderCell>
                  <Table.HeaderCell width='3'>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {geofenceList.length > 0 &&
                  geofenceList.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        {editNameForm ? (
                          <div>
                            <Input
                              type='text'
                              value={editName}
                              onChange={(e) => {
                                this.setState({ editName: e.target.value });
                              }}
                            />

                            <Button
                              onClick={() => this.updateGeofenceName(item.id)}
                              basic
                              color='violet'
                              size='mini'
                              disabled={submitted}
                            >
                              Update
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({
                                  editNameForm: false,
                                  editName: '',
                                })
                              }
                              basic
                              color='red'
                              size='mini'
                              disabled={submitted}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <div>
                            {item.name}{' '}
                            <Icon
                              name='edit'
                              onClick={() =>
                                this.setState({
                                  editNameForm: true,
                                  editName: item.name,
                                })
                              }
                              disabled={submitted}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          fluid
                          multiple
                          search
                          selection
                          defaultValue={item.assetList}
                          options={assets}
                          onChange={this.handleAssetSelect}
                          name={item.id}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Button.Group>
                          {geofenceWithAssets.hasOwnProperty(item.id) && (
                            <Button
                              onClick={() => this.updateAssetList(item.id)}
                              basic
                              color='violet'
                              disabled={submitted}
                            >
                              Update
                            </Button>
                          )}

                          {item.assetList.length === 0 &&
                            !geofenceWithAssets.hasOwnProperty(item.id) && (
                              <Button
                                onClick={() => this.updateList(item._id.$oid)}
                                basic
                                color='violet'
                              >
                                Add
                              </Button>
                            )}
                          <Button
                            onClick={() => this.handleDeleteConfirm(item.id)}
                            basic
                            color='red'
                            disabled={submitted}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() => this.showPolygon(item.id)}
                            basic
                            color='blue'
                            disabled={submitted}
                          >
                            Show
                          </Button>
                        </Button.Group>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {geofenceList.length === 0 && (
              <Header as='h4' textAlign='center'>
                <Icon name='ban' />
                Geofence Empty.
              </Header>
            )}
            <Confirm
              open={deleteGeofenceId ? true : false}
              onCancel={() => this.handleDeleteConfirm(false)}
              onConfirm={this.deleteGeofence}
              size='tiny'
            />
          </div>

          {/* ... show in map... */}
          <div
            style={{
              display: `${
                !currentGeofenceName && !currentGeofence ? 'none' : 'block'
              }`,
            }}
          >
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px 0',
              }}
            >
              <Header as='h4'>
                <Icon name='location arrow' />
                {currentGeofenceName}
              </Header>
              <Button.Group>
                <Link to={`/geofence/draw/${currentGeofence}`}>
                  <Button
                    basic
                    color='blue'
                    onClick={this.showGeofenceEdit}
                    size='small'
                  >
                    EDIT
                  </Button>
                </Link>
                <Button basic color='blue' onClick={this.showList} size='small'>
                  LIST
                </Button>
              </Button.Group>
            </div>

            <Segment>
              <Map id='GeofenceMap' ref='leaflet' animate={true}>
                <MapLayers />
                {polygon.length > 0 ? (
                  <Polygon
                    ref='leafletPolygon'
                    color='red'
                    weight='10'
                    lineCap='round'
                    positions={polygon}
                    smoothFactor={1}
                  />
                ) : null}
              </Map>
            </Segment>
          </div>
        </Segment>
      </div>
    );
  }
}

export default Geofence;

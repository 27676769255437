import axios from 'axios';
import { BASE_PATH, REGULAR_PATH } from '../../../../env';
import logoutApp from '../../../helper/logout-app';

const accessToken = () => window.localStorage.getItem('user');
const refreshToken = () => window.localStorage.getItem('refresh');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error.response;
    if (response) {
      const message = response.data.error;
      const originalRequest = response.config;
      if (message === 'expired token') {
        return axios
          .post(
            `${REGULAR_PATH}/auth/refresh_token`,
            JSON.stringify({
              access_token: accessToken(),
              refresh_token: refreshToken(),
            }),
          )
          .then(() => axios(originalRequest));
      } else if (message === 'Invalid Token') {
        logoutApp();
      }
    } else if (response.status === 500) {
      console.log('Server Error', response);
    }
    return Promise.reject(error);
  },
);

export const reportView = ({ viewPath, apiVer }, data) =>
  axios({
    method: 'post',
    url: `${BASE_PATH}/${apiVer}/reports/${viewPath}?access_token=${accessToken()}`,
    data: JSON.stringify(data),
  });

export const reportDownloadPdf = ({ pdfPath, apiVer }, data) =>
  axios({
    method: 'post',
    url: `${BASE_PATH}/${apiVer}/reports/${pdfPath}?access_token=${accessToken()}`,
    data: JSON.stringify(data),
    responseType: 'blob',
    headers: {
      'content-type': 'text/plain',
    },
  });

export const reportDownloadExcel = ({ excelPath, apiVer }, data) =>
  axios({
    method: 'post',
    url: `${BASE_PATH}/${apiVer}/reports/${excelPath}?access_token=${accessToken()}`,
    data: JSON.stringify(data),
    responseType: 'blob',
    headers: {
      'content-type': 'text/plain',
    },
  });

export const reportGet = (path) =>
  axios({
    method: 'get',
    url: `${BASE_PATH}/${path}?access_token=${accessToken()}`,
  });

export const reportRequest = (path, data) =>
  axios({
    method: 'post',
    url: `${REGULAR_PATH}/${path}?access_token=${accessToken()}`,
    data: JSON.stringify(data),
  });

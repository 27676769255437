import React from 'react';
import {
  Segment,
  Checkbox,
  Input,
  Container,
  Button,
  Message,
  Header,
} from 'semantic-ui-react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { REGULAR_PATH } from '../../env';

const accessToken = () => window.localStorage.getItem('user');

class ConnectAsset extends React.Component {
  state = {
    assets: [],
    search: '',
    isSubmitted: false,
    keyInfo: {},
    copied: false,
  };

  componentDidMount() {
    axios({
      method: 'GET',
      url: `${REGULAR_PATH}/assets/list?access_token=${accessToken()}`,
    })
      .then(({ data }) => {
        const assets = data.map((item) => ({
          key: item._id.$oid,
          value: item._id.$oid,

          label: item.name,
          checked: false,
        }));
        this.setState({ assets });
      })
      .catch((error) => console.error(error));
  }

  onChange = (_, data) => {
    const { value, checked } = data;
    const { assets } = this.state;
    this.setState({
      assets: assets.map((asset) =>
        asset.value === value ? { ...asset, checked } : asset,
      ),
    });
  };

  generateKey = () => {
    this.setState({ isSubmitted: true });

    const asset_list = this.state.assets
      .filter((item) => item.checked)
      .map((item) => item.value);

    axios({
      method: 'POST',
      url: `${REGULAR_PATH}/assets/connect?access_token=${accessToken()}`,
      data: JSON.stringify({ asset_list }),
    })
      .then(({ data }) => {
        this.setState({ keyInfo: data });
        this.setState({ isSubmitted: false });
      })
      .catch((error) => {
        this.setState({ isSubmitted: false });
      });
  };

  render() {
    const { assets, search, isSubmitted, keyInfo, copied } = this.state;
    const selectedAssets = assets.filter((item) => item.checked);

    if (keyInfo.hasOwnProperty('key')) {
      const { key, expires_in, number_of_assets } = keyInfo;
      return (
        <Segment>
          <Container style={{ margin: '50px 0' }}>
            <img
              src='/images/Logo-1.png'
              alt='Finder logo'
              style={{
                height: '60px',
                width: 'auto',
                padding: '5px',
                backgroundColor: '#000',
                marginBottom: '35px',
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ marginRight: '10px' }}>
                <Header
                  as='h4'
                  icon='key'
                  content={key}
                  className='word-text-wrap'
                />
              </div>

              <div>
                <CopyToClipboard
                  text={key}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <Button primary>Copy</Button>
                </CopyToClipboard>
              </div>
            </div>
            {copied ? <Message success>Copied</Message> : null}
            <Message
              header={`This key has been generated for ${number_of_assets} assets.`}
              content={`Key will expire within ${expires_in} minutes.`}
            />
          </Container>
        </Segment>
      );
    }

    return (
      <Segment>
        <Container>
          <img
            src='/images/Logo-1.png'
            alt='Finder logo'
            style={{
              height: '60px',
              width: 'auto',
              padding: '5px',
              backgroundColor: '#000',
            }}
          />
          <Header as='h5' content='Please select assets to connect' />
          <Input
            placeholder='Search asset...'
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div style={{ height: '100%', margin: '20px 10px' }}>
            {assets.length
              ? assets
                  .filter((item) =>
                    item.label.toLowerCase().includes(search.toLowerCase()),
                  )
                  .map(({ key, value, label, checked }) => (
                    <Checkbox
                      key={key}
                      value={value}
                      label={label}
                      checked={checked}
                      style={{ display: 'block', marginTop: '5px' }}
                      onChange={this.onChange}
                    />
                  ))
              : null}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <p>
              Total Assets: {assets.length}&nbsp;&nbsp;&nbsp; Selected Assets:{' '}
              {selectedAssets.length}
            </p>
            <div style={{ marginLeft: '30px' }}>
              {selectedAssets.length ? (
                <Button
                  disabled={isSubmitted}
                  loading={isSubmitted}
                  primary
                  onClick={this.generateKey}
                >
                  Next
                </Button>
              ) : null}
            </div>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default ConnectAsset;

import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Area,
  Tooltip as TooltipChart,
} from 'recharts';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportView,
  reportDownloadPdf,
  reportDownloadExcel,
} from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.speed;

class Speed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      speedLimit: 60,
      graphList: [],
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  onChangeView = (e) => {
    this.setState({
      speedLimit: parseInt(e.target.value),
    });
  };

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { speedLimit } = this.state;
    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportView(reportsEnv.speed, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      speed_limit: speedLimit,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          graphList: response.data.map((asset) => {
            let filteredList = [];
            asset.last_data.forEach((item) => {
              filteredList.push({
                time: moment(item.last_data.time.$date).format('DD MMM'),
                speed: item.last_data.speed,
              });
            });
            return filteredList;
          }),
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { speedLimit } = this.state;
    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportDownloadPdf(reportsEnv.speed, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      speed_limit: speedLimit,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Speed_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });
    const { speedLimit } = this.state;

    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    reportDownloadExcel(
      { ...reportsEnv.speed, apiVer: 'v1.3' },
      {
        asset_ids: ids,
        from: startTime,
        to: endTime,
        speed_limit: speedLimit,
      },
    )
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Speed_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      speedLimit,
      graphList,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Speed [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>
            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            <div style={{ margin: '10px 0 20px 0' }}>
              <select
                className='select-semantic'
                onChange={this.onChangeView}
                value={speedLimit}
              >
                <option value='50'>50 km</option>
                <option value='60'>60 km</option>
                <option value='70'>70 km</option>
                <option value='80'>80 km</option>
                <option value='90'>90 km</option>
                <option value='100'>100 km</option>
                <option value='110'>110 km</option>
                <option value='120'>120 km</option>
                <option value='130'>130 km</option>
                <option value='140'>140 km</option>
              </select>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file pdf outline'
                  content='Download PDF'
                  onClick={this.handleDownloadPdfBtn}
                  disabled={pdfDisableBtn || excelDisableBtn}
                  loading={pdfDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <br />
            <div className='left-right'>
              <div>
                <Header as='h2'>Speed</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>
            <br />

            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h2 className='ui center aligned icon header'>
                          <i className='circular car icon' />
                          {/* Distance: {parseInt(item.distance)} km */}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {graphList[i].length > 1 ? (
                  <ResponsiveContainer height={200} width='100%'>
                    <ComposedChart data={graphList[i]}>
                      <CartesianGrid stroke='#ced4da' />
                      <XAxis dataKey='time' />
                      <YAxis />
                      <TooltipChart />
                      <Legend />
                      <Area
                        type='monotone'
                        dataKey='speed'
                        name='Speed'
                        fill='#2f4b7c'
                        stroke='#2f4b7c'
                        dot={false}
                        isAnimationActive={true}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : null}

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Time of Arrival</Table.HeaderCell>
                      <Table.HeaderCell>Speed</Table.HeaderCell>
                      <Table.HeaderCell>Landmarks</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {item.last_data.map((last, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell>
                          {last.last_data.time
                            ? moment(last.last_data.time.$date).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )
                            : ''}
                        </Table.Cell>
                        <Table.Cell>{last.last_data.speed}</Table.Cell>
                        <Table.Cell>
                          {last.nlm != null ? (
                            last.nlm.map((nlm, x) => (
                              <p key={x}>
                                <i className='marker icon' />
                                {nlm.name}
                              </p>
                            ))
                          ) : (
                            <p>
                              <i className='marker icon' />
                              No Data Found
                            </p>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default Speed;

export const BASE_PATH = process.env.REACT_APP_BASE_API;
export const REGULAR_PATH = `${BASE_PATH}/${process.env.REACT_APP_API_VERSION}`;

export const BKASH_PAYMENT_URL = process.env.REACT_APP_BKASH_PAYMENT_URL;
export const LIVE_SHARE_URL = process.env.REACT_APP_LIVE_SHARE_URL;

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const MONTHLY_BILL_CONFIG = {
  path: process.env.REACT_APP_MONTHLY_BILL_CONFIG_PATH,
  id: process.env.REACT_APP_MONTHLY_BILL_CONFIG_ID,
  secret: process.env.REACT_APP_MONTHLY_BILL_CONFIG_SECRET,
};

export const AUTH_CONFIG = {
  id: process.env.REACT_APP_AUTH_CONFIG_ID,
  secret: process.env.REACT_APP_AUTH_CONFIG_SECRET,
};

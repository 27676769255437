import React from 'react';
// import "./paymentCancel.css";

const PaymentCancel = () => {
  return (
    <div>
      <section className='payment-container'>
        <div className='payment-cancel'>
          <h1 className='payment-header'>Payment Cancelled!</h1>
          <h5 id='paragraph'>
            You seem to have cancelled your payment. If you have any queries,
            feel free to communicate with our Billing Department.
          </h5>
        </div>
      </section>
    </div>
  );
};

export default PaymentCancel;

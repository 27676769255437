import React from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Header,
  Loader,
} from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH } from '../../../../env';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getActiveAssetList } from '../../reports/helper/helper';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class Edit extends React.Component {
  state = {
    assetName: '',
    deliveryDate: moment().startOf('day'),
    cin: '',
    name: '',
    unit: '',
    mobile: '',
    place: '',
    modelNumber: '',
    submit: false,
    status: '',
    loading: true,
  };

  componentDidMount() {
    this.setState({
      assetList: getActiveAssetList(),
    });

    axios
      .post(
        `${BASE_PATH}/v1.2/reports/get_runners_asset?access_token=${token()}`,
        JSON.stringify({
          asset_ids: [this.props.assetIdForEdit],
        }),
      )
      .then((response) => {
        const data = response.data[0];
        const {
          asset_name,
          delivery_date,
          cin,
          customer_name,
          unit,
          mobile_no,
          region,
          model_number,
        } = data;
        let deliveryDate = moment();
        if (delivery_date && moment(delivery_date).isValid()) {
          deliveryDate = moment(delivery_date);
        }
        this.setState({
          loading: false,
          assetName: asset_name,
          deliveryDate,
          cin,
          name: customer_name,
          unit,
          mobile: mobile_no,
          place: region,
          modelNumber: model_number,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  saveData = () => {
    const {
      assetName,
      deliveryDate,
      cin,
      name,
      mobile,
      unit,
      place,
      modelNumber,
    } = this.state;

    const data = {
      org_oid: org(),
      asset_oid: this.props.assetIdForEdit,
      asset_name: assetName,
      delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
      cin: cin,
      customer_name: name,
      unit,
      mobile_no: mobile,
      region: place,
      model_number: modelNumber,
    };

    this.setState({ submit: true, status: '' });

    axios
      .put(
        `${BASE_PATH}/v1.2/reports/runners_asset?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const message = response.data.message;
        this.setState({ status: message, submit: false });
        if (message === 'Success') {
          this.props.editComplete();
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleAssetSelection = (e, data) => {
    this.setState({
      assetOid: data.value,
    });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  handleDeliveryDate = (value) => {
    this.setState({
      deliveryDate: value,
    });
  };

  render() {
    const {
      deliveryDate,
      cin,
      name,
      unit,
      mobile,
      place,
      modelNumber,
      submit,
      status,
      loading,
    } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Form>
          <Form.Field
            control={Input}
            label='CIN'
            onChange={this.handleInput}
            value={cin}
            name='cin'
          />
          <Form.Field
            control={Input}
            label='Customer Name'
            onChange={this.handleInput}
            value={name}
            name='name'
          />
          <Form.Field
            control={Input}
            label='Mobile Number'
            onChange={this.handleInput}
            value={mobile}
            name='mobile'
          />
          <Form.Field
            control={Input}
            label='Unit'
            onChange={this.handleInput}
            value={unit}
            name='unit'
          />
          <Form.Field
            control={Input}
            label='Model'
            onChange={this.handleInput}
            value={modelNumber}
            name='modelNumber'
          />
        </Form>
        <Header as='h5'>Vehicle Delivery Date</Header>
        <div className='date-time-report'>
          <DatePicker
            selected={deliveryDate}
            onChange={this.handleDeliveryDate}
            dateFormat='LL'
          />
        </div>
        <Form>
          <Form.Field
            control={Input}
            label='Region/Place'
            onChange={this.handleInput}
            value={place}
            name='place'
          />
        </Form>

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          disabled={submit}
        >
          Submit
        </Button>

        {status === 'Success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'Success' && (
          <Message negative> {status}</Message>
        )}
      </div>
    );
  }
}

export default Edit;

import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { divIcon } from 'leaflet';

class LandMarkPopUp extends React.Component {
  render() {
    const LandMarkIcon = () => {
      return divIcon({
        className: 'poi-landmark-icon',
        iconSize: [10, 10],
        iconAnchor: [12, 22],
        popupAnchor: [10, 10],
        shadowSize: [155, 150],
        html: '<img src="/images/pointer.png" style="margin-top: -10px;" width="32"/>',
      });
    };

    return (
      <div>
        {this.props.landmarks.length > 1
          ? this.props.landmarks.map((item, index) => (
              <Marker
                position={item.position}
                icon={LandMarkIcon()}
                key={index}
              >
                <Tooltip sticky>
                  <span>{item.name}</span>
                </Tooltip>
              </Marker>
            ))
          : null}
      </div>
    );
  }
}
export default LandMarkPopUp;

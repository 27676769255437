import React from 'react';
import DashboardContainer from './dashboard/dashboard-container';

class DashBoard extends React.Component {
  fromDashBoardContainer = (value) => {
    this.props.passOrgAssetToApp(value);
  };
  render() {
    return (
      <div>
        <DashboardContainer
          passToDashBoard={this.fromDashBoardContainer}
          selectedIdToContainer={[this.props.selected, this.props.submitted]}
        />
      </div>
    );
  }
}

export default DashBoard;

import axios from 'axios';
import React from 'react';
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Header,
  Icon,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react';
import { BKASH_PAYMENT_URL, REGULAR_PATH } from '../../../env';
import { refreshToken } from './../../helper/refresh-token';

const org = () => window.localStorage.getItem('organization');

const paymentUrl = {
  ssl: {
    name: 'SSL',
    url: 'sslcommerz/initiate_payment',
  },
  upay: {
    name: 'Upay',
    url: 'upay/initiate_payment',
  },
};

class MonthlyBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      smspack: '',
      years: '',
      year: '',
      dataLoaded: false,
      smsPackDataFetchDone: false,
      submitPayment: false,
      latestBill: {},
      showPayButton: false,
    };
    this.token = window.localStorage.getItem('user');
  }

  componentDidMount() {
    this.smsPackData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.smsPackDataFetchDone !== this.state.smsPackDataFetchDone) {
      this.monthlyData();
    }
  }

  smsPackData = () => {
    axios(`${REGULAR_PATH}/smspacks?access_token=${this.token}`)
      .then((response) => {
        this.setState({
          smspack: response.data,
          smsPackDataFetchDone: true,
        });
      })
      .catch((error) => {
        if (error.response.data.error === 'expired token') {
          refreshToken(
            `${REGULAR_PATH}/smspacks?access_token=${this.token}`,
          ).then((response) => {
            this.setState({
              smspack: response.data,
              smsPackDataFetchDone: true,
            });
          });
        }
      });
  };

  monthlyData = () => {
    axios
      .get(`${REGULAR_PATH}/monthly_bill/${org()}?access_token=${this.token}`)
      .then((response) => {
        const data = response.data;
        const years = [...new Set(data.data.map((asset) => asset.year))];
        this.setState({
          data: data,
          years: years,
          year: years[0],
          latestBill: data.data.length ? data.data[0] : false,
          dataLoaded: true,
        });
      })
      .catch((error) => {
        // 'monthly bill error', error.response.data.error;
      });
  };

  handleYear = (year) => {
    this.setState({ year });
  };

  whichMonth = (monthNumber) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[monthNumber - 1];
  };

  handlePayment = (
    billId = null,
    packId = null,
    type = paymentUrl.ssl.name,
  ) => {
    let data = {};

    data.platform = 'web';

    if (billId) {
      data.type = 'monthly_bill';
      data.bill_id = billId;
    }

    if (packId) {
      data.type = 'smspack';
      data.product_id = packId;
    }

    this.setState({ submitPayment: true });

    let url = paymentUrl.ssl.url;

    if (type === paymentUrl.upay.name) {
      url = paymentUrl.upay.url;
    }

    axios
      .post(
        `${REGULAR_PATH}/${url}?access_token=${this.token}`,
        JSON.stringify(data),
      )
      .then(({ data }) => {
        const { payment_url = '', status = '' } = data;
        if (status === 'success') {
          window.location.href = payment_url;
        }
        this.setState({ submitPayment: false });
      })
      .catch(() => this.setState({ submitPayment: false }));
  };

  render() {
    let leftPaidSms,
      leftFreeSms,
      totalPaidSms,
      totalFreeSms,
      data,
      smspack,
      organization,
      sms_balance;

    const latestBill = this.state.latestBill;
    const showPayButton = this.state.showPayButton;

    if (this.state.data) {
      data = this.state.data.data;
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const telephoneNumbers = data[index].org.telephone;
          const telephoneNumber = telephoneNumbers.split(',');
          let assetBalance = data[index].balance;
          assetBalance = assetBalance.toFixed(2);
          data[index].assetBalance = Number(assetBalance);
          data[index].org.phoneNumber = telephoneNumber[0];
        }
      }
    }

    if (this.state.smspack) {
      smspack = this.state.smspack.smspacks;
      organization = this.state.smspack.org;
      sms_balance = this.state.smspack.sms_balance;
      if (organization) {
        totalPaidSms = organization.no_of_paid_sms;
        totalFreeSms = organization.no_of_free_sms;
      } else {
        totalPaidSms = 0;
        totalFreeSms = 0;
      }
      if (sms_balance) {
        leftPaidSms = sms_balance.no_of_paid_sms;
        leftFreeSms = sms_balance.no_of_free_sms;
      }
    }

    if (this.state.dataLoaded) {
      return (
        <div>
          <div style={{ margin: '5px 10px 0px 10px' }}>
            <Header as='h4' className='pay-with-text' textAlign='center'>
              Pay With
            </Header>
            <img
              src='/images/payment/paywith_1.png'
              alt='Pay with 1'
              className='pay-with-img'
            />
            <img
              src='/images/payment/paywith_2.png'
              alt='Pay with 2'
              className='pay-with-img'
            />
          </div>

          <Segment padded className='flex-space-between'>
            <div style={{ marginBottom: '15px' }}>
              <Header as='h3'>
                <Icon name='bell outline' /> SMS Pack Status
              </Header>
              <Card>
                <Card.Content>
                  <Card.Description>
                    <Header as='h4'>
                      <strong>{leftFreeSms || 0}</strong> of {totalFreeSms} free
                      SMS remaining
                    </Header>
                    <Header as='h4'>
                      <strong>{leftPaidSms || 0}</strong> of {totalPaidSms} paid
                      SMS remaining
                    </Header>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>
            <div>
              <Header as='h3'>
                <Icon name='envelope' /> Purchase SMS Package
              </Header>
              <Card.Group>
                {smspack.map((pack, id) => {
                  return (
                    <Card key={id} style={{ width: '160px' }}>
                      <Card.Content>
                        <Card.Description>
                          <Header as='h5' style={{ marginBottom: '10px' }}>
                            {pack.count} SMS - {pack.price}Tk
                          </Header>
                        </Card.Description>

                        <Card.Content extra>
                          <Button
                            size='mini'
                            basic
                            primary
                            onClick={() =>
                              this.handlePayment(null, pack._id.$oid)
                            }
                            disabled={this.state.submitPayment}
                          >
                            Buy Now
                          </Button>
                        </Card.Content>
                      </Card.Content>
                    </Card>
                  );
                })}
              </Card.Group>
            </div>
          </Segment>

          {this.state.data.data.length > 0 ? (
            <Segment>
              <div className='flex-space-between' id='flex-center'>
                <div>
                  <Header as='h2'>{data[0].org.name}</Header>

                  {data[0].org.bill_account_number ? (
                    <p>
                      Bill Account Number: {data[0].org.bill_account_number}
                    </p>
                  ) : null}
                </div>
                {latestBill.has_paid ? (
                  <div>
                    <Header
                      as='h3'
                      style={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon name='payment' />
                      <span style={{ color: '#21ba45' }}>Bill Paid.</span>
                    </Header>
                  </div>
                ) : null}

                {!showPayButton &&
                !latestBill.has_paid &&
                latestBill.balance > 0 ? (
                  <div className='flex-space-between' id='flex-center'>
                    <Header
                      as='h3'
                      style={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icon name='payment' />
                      <span style={{ color: '#f2711c' }}>
                        Due Bill is {Math.round(latestBill.balance)}Tk till{' '}
                        {this.whichMonth(latestBill.month)}, {latestBill.year}
                      </span>
                    </Header>
                    <Button
                      primary
                      size='small'
                      onClick={() => this.setState({ showPayButton: true })}
                      style={{ marginLeft: '15px' }}
                    >
                      Pay Now
                    </Button>
                  </div>
                ) : null}
                {showPayButton ? (
                  <div className='flex-space-between' id='flex-center'>
                    <div
                      className='flex-space-between'
                      id='flex-center'
                      style={{ marginBottom: '15px' }}
                    >
                      <Icon
                        name='arrow left'
                        onClick={() => this.setState({ showPayButton: false })}
                        style={{
                          cursor: 'pointer',
                          fontSize: '24px',
                          lineHeight: '1',
                        }}
                      />
                      <Header
                        as='h5'
                        style={{
                          margin: 0,
                          marginLeft: '20px',
                          marginRight: '5px',
                        }}
                      >
                        Pay Now With
                      </Header>
                    </div>
                    <div
                      className='flex-space-between'
                      id='flex-center'
                      style={{ marginBottom: '15px' }}
                    >
                      <Button
                        size='small'
                        color='teal'
                        onClick={() => this.handlePayment(latestBill._id.$oid)}
                        disabled={this.state.submitPayment}
                      >
                        Cards/Net Banking/Mobile Banking
                      </Button>
                      <a
                        href={`${BKASH_PAYMENT_URL}?mid=${latestBill._id.$oid}&token=${this.token}`}
                      >
                        <Button basic size='small' id='bkashButton'>
                          bKash
                        </Button>
                      </a>
                      <Button
                        size='small'
                        color='yellow'
                        onClick={() =>
                          this.handlePayment(
                            latestBill._id.$oid,
                            null,
                            paymentUrl.upay.name,
                          )
                        }
                        disabled={this.state.submitPayment}
                      >
                        Upay
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>

              <Divider />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                  <span>Select Year: </span>
                  {this.state.years.map((year, index) => (
                    <Button
                      basic
                      size='mini'
                      onClick={() => this.handleYear(year)}
                      color={year === this.state.year ? 'green' : 'grey'}
                      key={index}
                    >
                      {year}
                    </Button>
                  ))}
                </div>
              </div>
              <section>
                <Table celled textAlign='center'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>No.</Table.HeaderCell>
                      <Table.HeaderCell>Bill Year</Table.HeaderCell>
                      <Table.HeaderCell>Bill Month</Table.HeaderCell>
                      <Table.HeaderCell>No. of Assets</Table.HeaderCell>
                      <Table.HeaderCell>
                        Billed this Month (Tk)
                      </Table.HeaderCell>
                      <Table.HeaderCell>Dues (Tk)</Table.HeaderCell>
                      <Table.HeaderCell>Total Payments (Tk)</Table.HeaderCell>
                      <Table.HeaderCell>Total Bill (Tk)</Table.HeaderCell>
                      <Table.HeaderCell>Download</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {data
                      .filter((asset) => asset.year === this.state.year)
                      .sort((a, b) => b.month - a.month)
                      .map((asset, idx) => {
                        return (
                          <Table.Row key={idx}>
                            <Table.Cell>{idx + 1}</Table.Cell>
                            <Table.Cell>{asset.year}</Table.Cell>
                            <Table.Cell>
                              {this.whichMonth(asset.month)}
                            </Table.Cell>
                            <Table.Cell>{asset.items.length}</Table.Cell>
                            <Table.Cell>
                              {Math.round(asset.sum_total)}
                            </Table.Cell>
                            <Table.Cell>{Math.round(asset.dues)}</Table.Cell>
                            <Table.Cell>
                              {Math.round(asset.payments)}
                            </Table.Cell>
                            <Table.Cell>
                              <span style={{ fontWeight: 'bold' }}>
                                {Math.round(asset.balance)}
                              </span>
                            </Table.Cell>

                            <Table.Cell>
                              <a
                                href={`${REGULAR_PATH}/monthly_bill_pdf?bill_id=${asset._id.$oid}&access_token=${this.token}`}
                              >
                                <Button className='ui button'>PDF</Button>
                              </a>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              </section>
            </Segment>
          ) : (
            <div className='no-data'>
              <h1>Sorry no data found</h1>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Dimmer active={true}>
            <Loader indeterminate size='big'>
              {' '}
              Loading...
            </Loader>
          </Dimmer>
        </div>
      );
    }
  }
}

export default MonthlyBill;

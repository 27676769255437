import React from 'react';
import { Modal, Button, Dimmer, Loader, Icon } from 'semantic-ui-react';
import moment from 'moment';
import axios from 'axios';
import { REGULAR_PATH } from '../../env';
import { Map, Marker, Polyline } from 'react-leaflet';
import Leaflet from 'leaflet';
import MapLayers from './MapLayers';

const staticPosition = [24.146754, 90.499477];

class RouteOnMapById extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: [
        [26.765230565697482, 93.17504882812501],
        [22.11617714721062, 86.5667724609375],
      ],
      position: [24.146754, 90.499477],
      viewModal: false,
      loading: false,
      data: [],
    };
  }

  componentDidUpdate(_, prevState) {
    const prevPosition = prevState.position;
    const { viewModal, data, position } = this.state;

    if (prevPosition !== position) {
      if (viewModal && data.length) {
        this.refs.leaflet.leafletElement.setZoom(12);
      }
    }
  }

  fetchRouteData = () => {
    this.setState({ loading: true, viewModal: true });

    const { startTime, endTime, assetId } = this.props;
    const getToken = () => window.localStorage.getItem('user');
    const from = moment(startTime).unix();
    const to = moment(endTime).unix();
    const url = `${REGULAR_PATH}/assets/${assetId}/history_data_fuel?from=${from}&to=${to}&access_token=${getToken()}`;

    axios({
      url: url,
      method: 'get',
    })
      .then((response) => {
        const data = response.data.map(({ speed, loc, bearing }) => {
          const [lng, lat] = loc.coordinates;
          return { speed, bearing, position: [lat, lng] };
        });
        this.setState({ loading: false, data, position: data[0].position });
      })
      .catch(() => {
        this.setState({ loading: false, data: [], position: staticPosition });
      });
  };

  hideModal = () => {
    if (this.state.loading) return;
    this.setState({ viewModal: false, position: staticPosition, data: [] });
  };

  render() {
    const { assetName, totalDistance, startTime, endTime } = this.props;
    const { loading, viewModal, bounds, position, data } = this.state;
    const startIcon = Leaflet.icon({
      iconUrl: '/images/start_marker.png',
      iconSize: [40, 60],
    });
    const endIcon = Leaflet.icon({
      iconUrl: '/images/end_marker.png',
      iconSize: [40, 60],
    });

    return (
      <div>
        <Button
          onClick={this.fetchRouteData}
          loading={loading}
          disabled={loading}
        >
          View Route
        </Button>
        <Modal
          size='fullscreen'
          closeOnEscape={true}
          closeIcon={true}
          open={viewModal}
          onClose={this.hideModal}
        >
          <Modal.Header>{assetName}</Modal.Header>
          <Modal.Content>
            {loading ? (
              <Dimmer active>
                <Loader>Loading</Loader>
              </Dimmer>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '16px',
                margin: '0 0 10px 0',
              }}
            >
              <div>
                <Icon name='play' />
                {moment(startTime).format('DD/MM/YY, HH:mm A')}
              </div>
              <div>
                <Icon name='stop' />
                {moment(endTime).format('DD/MM/YY, HH:mm A')}
              </div>
              <div>
                <Icon name='road' />
                {totalDistance} KM
              </div>
            </div>
            <Map
              ref='leaflet'
              bounds={bounds}
              center={position}
              animate={true}
              id='leaflet-map-container'
            >
              <MapLayers />
              {data.length ? (
                <Polyline positions={data.map(({ position }) => position)} />
              ) : null}
              {data.length ? (
                <Marker icon={startIcon} position={data[0].position} />
              ) : null}
              {data.length ? (
                <Marker
                  icon={endIcon}
                  position={data[data.length - 1].position}
                />
              ) : null}
            </Map>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default RouteOnMapById;

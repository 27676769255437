import React from 'react';
import { Input, Button, Message } from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');

class BullAdd extends React.Component {
  state = {
    file: [],
    submit: false,
    status: '',
    info: {
      new_entry: 0,
      rejected_entry: 0,
      total_entry: 0,
      updated_entry: 0,
    },
  };

  saveData = () => {
    this.setState({ submit: true, status: '' });

    const { file } = this.state;
    const formData = new FormData();
    formData.append('document', file);
    axios({
      method: 'post',
      url: `${REGULAR_PATH}/runners_asset_bulk?access_token=${token()}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        const {
          message,
          new_entry,
          rejected_entry,
          total_entry,
          updated_entry,
        } = data;
        this.setState({ status: message, submit: false });
        if (message === 'Runners Asset Bulk Insertion') {
          this.setState({
            status: 'Success',
            info: { new_entry, rejected_entry, updated_entry, total_entry },
          });
        }
      })
      .catch(() => {
        this.setState({
          submit: false,
          status: 'Sorry! Something went wrong.',
        });
      });
  };

  onFileUpload = (e) => {
    const file = e.target.files[0];
    this.setState({ file });
  };

  render() {
    const { info, status, submit } = this.state;

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Input
          type='file'
          name='bulk'
          accept='.xlsx, .xls'
          onChange={this.onFileUpload}
        />
        <br />
        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          disabled={submit}
          loading={submit}
        >
          Submit
        </Button>

        {status === 'Success' && (
          <Message positive>
            Uploaded successfully.
            <br />
            New: {info.new_entry}, Updated: {info.updated_entry}, Rejected:{' '}
            {info.rejected_entry}, Total: {info.total_entry}
          </Message>
        )}

        {status && status !== 'Success' && (
          <Message negative> {status}</Message>
        )}
      </div>
    );
  }
}

export default BullAdd;

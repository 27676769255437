import axios from 'axios';
const config = {
  'Access-Control-Allow-Origin': '*',
};
export const postData = function (url, body) {
  return axios.post(url, body, config);
};

export const getData = function (url) {
  return axios({
    url: url + window.localStorage.getItem('user'),
    method: 'get',
  });
};

export const sendCode = function (url) {
  return axios({
    url,
    method: 'get',
  });
};

export const handleMultipleRequest = function (promises) {
  return axios.all(promises);
};

export const getReportData = function (url, conf) {
  return axios.get(url + window.localStorage.getItem('user'), conf);
};

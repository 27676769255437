import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import AppRouter from './app/routes';

import { Provider } from 'mobx-react';
import stores from './app/stores';
import ScrollToTop from './app/components/ScrollToTop';

ReactDOM.render(
  <Provider assets={stores.assets} timer={stores.timer} user={stores.user}>
    <BrowserRouter>
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

import React from 'react';
import { Button, Radio, Form, FormField } from 'semantic-ui-react';

class LiveStream extends React.Component {
  render() {
    const { channel, handleChannel, fetchStreamUrl } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Form style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormField>
            <Radio
              label={'Channel 1'}
              value={1}
              checked={channel === 1}
              onChange={handleChannel}
            />
          </FormField>
          <FormField style={{ marginLeft: '10px' }}>
            <Radio
              label={'Channel 2'}
              value={2}
              checked={channel === 2}
              onChange={handleChannel}
            />
          </FormField>
        </Form>

        <Button
          color='teal'
          content='Stream'
          onClick={() => fetchStreamUrl(channel)}
        />
      </div>
    );
  }
}

export default LiveStream;

import React, { Component } from 'react';
import {
  Button,
  Accordion,
  Form,
  Dropdown,
  Divider,
  Icon,
  Confirm,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getData, postData } from '../dataHelper';
import Message from '../Message';

class Add extends Component {
  state = {
    plateNumberList: this.props.plateNumberList,
    particulars_dd: [],
    particulars: [],
    firstTime: false,
    registrationNumber: '',
    sparePartsId: '',
    purchaseDate: [],
    purchaseFrom: [],
    purchaseQty: [],
    previousQty: '',
    previousPurchaseDate: null,
    unitRate: [],
    totalAmount: '',
    presentMeter: [],
    previousMeter: '',
    runningMillage: '',
    payment_dd: [],
    modeOfPayment: [],
    branch_dd: [],
    branch: [],
    remarks: [],
    messageShow: false,
    messageType: '',
    messageInfo: '',
    submitted: false,
    activeIndex: 0,
    formSize: [],
    titles: [],
    particularsPrevInfos: [],
    openConfirm: false,
    openSubmit: false,
  };

  componentDidMount() {
    this.fetchParticulars();
  }

  fetchParticulars = () => {
    getData(
      `${this.props.org}_dropdown`,
      `key=particulars&drop_down_name=particular_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    )
      .then(({ data }) => {
        if (data.length > 0) {
          this.setState({
            particulars_dd: data.filter(({ status }) => status === 'active'),
          });
        }
      })
      .then(() => {
        getData(
          `${this.props.org}_dropdown`,
          `key=current_branch&drop_down_name=branch_dropdown&org_id=${window.localStorage.getItem(
            'organization',
          )}`,
        )
          .then((response) => {
            this.setState({
              branch_dd: response.data,
            });
          })
          .then(() => {
            getData(
              `${this.props.org}_dropdown`,
              `key=payment_mode&drop_down_name=payment_mode_dropdown&org_id=${window.localStorage.getItem(
                'organization',
              )}`,
            ).then((response) => {
              this.setState({
                payment_dd: response.data,
              });
            });
          });
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ messageShow: false, submitted: true });
    const {
      plateNumberList,
      registrationNumber,
      particulars,
      particulars_dd,
      purchaseDate,
      purchaseFrom,
      purchaseQty,
      unitRate,
      presentMeter,
      modeOfPayment,
      branch,
      remarks,
      formSize,
    } = this.state;

    const vanCarNo = plateNumberList.find(
      (item) => item.key === registrationNumber,
    );

    let data = [];

    formSize.forEach((index) => {
      data.push({
        asset_id: registrationNumber,
        van_car_no: vanCarNo.text,
        particulars: particulars_dd.find(
          (item) => item.key === particulars[index],
        ),
        purchase_date: moment(purchaseDate[index]).unix(),
        purchase_from: purchaseFrom[index],
        purchase_qty: Number(purchaseQty[index]),
        unit_rate: Number(unitRate[index]),
        present_meter: Number(presentMeter[index]),
        payment_mode: modeOfPayment[index],
        remarks: remarks[index],
        current_branch: branch[index],
      });
    });

    this.setState({
      openSubmit: false,
    });

    postData(`${this.props.org}_spare_parts`, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'positive',
            submitted: false,
            registrationNumber: '',
            particulars: [],
            purchaseDate: [],
            purchaseFrom: [],
            purchaseQty: [],
            unitRate: [],
            presentMeter: [],
            modeOfPayment: [],
            branch: [],
            remarks: [],
            formSize: [],
            activeIndex: 0,
          });
          setTimeout(() => {
            this.setState({ messageShow: false });
          }, 3000);
        } else if (status === 'failed') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'warning',
            submitted: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          messageShow: true,
          messageInfo: 'All data is not provided',
          messageType: 'warning',
          submitted: false,
        });
        console.error(error);
      });
  };

  handleDropdown = (value, index) => {
    this.setState((prevState) => {
      const data = [...prevState.particulars];
      data[index] = value;
      return { particulars: data };
    });

    getData(
      `${this.props.org}_spare_parts_last_data/${this.state.registrationNumber}/${value}`,
    )
      .then(({ data }) => {
        this.setState((prevState) => {
          const value = [...prevState.particularsPrevInfos];
          value[index] = data.data[0];
          return { particularsPrevInfos: value };
        });
      })
      .catch((error) => console.error(error));
  };

  handlePurchaseDate = (value, index) => {
    this.setState((prevState) => {
      const data = [...prevState.purchaseDate];
      data[index] = value;
      return { purchaseDate: data };
    });
  };

  handleChange = (e, index) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const data = [...prevState[name]];
      data[index] = value;
      return { [name]: data };
    });
  };

  handleAccordion = (e, { index }) => {
    this.setState({ activeIndex: index });
  };

  addNewForm = () => {
    this.setState((prevState) => {
      const index = prevState.formSize.length;
      this.handleDropdown(prevState.particulars_dd[0].key, index);
      if (index === 0) {
        return {
          openConfirm: false,
          formSize: [...prevState.formSize, index],
          activeIndex: index,
          particulars: [
            ...prevState.particulars,
            prevState.particulars_dd[0].key,
          ],
          branch: [...prevState.branch, prevState.branch_dd[0]],
          modeOfPayment: [...prevState.modeOfPayment, prevState.payment_dd[0]],
          purchaseDate: [...prevState.purchaseDate, null],
          purchaseFrom: [...prevState.purchaseFrom, null],
          purchaseQty: [...prevState.purchaseQty, null],
          unitRate: [...prevState.unitRate, null],
          presentMeter: [...prevState.presentMeter, null],
          remarks: [...prevState.remarks, null],
        };
      } else {
        return {
          openConfirm: false,
          formSize: [...prevState.formSize, index],
          activeIndex: index,
          particulars: [
            ...prevState.particulars,
            prevState.particulars[index - 1],
          ],
          branch: [...prevState.branch, prevState.branch[index - 1]],
          modeOfPayment: [
            ...prevState.modeOfPayment,
            prevState.modeOfPayment[index - 1],
          ],
          purchaseDate: [
            ...prevState.purchaseDate,
            prevState.purchaseDate[index - 1],
          ],
          purchaseFrom: [
            ...prevState.purchaseFrom,
            prevState.purchaseFrom[index - 1],
          ],
          purchaseQty: [
            ...prevState.purchaseQty,
            prevState.purchaseQty[index - 1],
          ],
          unitRate: [...prevState.unitRate, prevState.unitRate[index - 1]],
          presentMeter: [
            ...prevState.presentMeter,
            prevState.presentMeter[index - 1],
          ],
          remarks: [...prevState.remarks, prevState.remarks[index - 1]],
        };
      }
    });
  };

  render() {
    const {
      plateNumberList,
      particulars_dd,
      registrationNumber,
      particulars,
      purchaseDate,
      purchaseFrom,
      purchaseQty,
      unitRate,
      presentMeter,
      payment_dd,
      modeOfPayment,
      branch_dd,
      branch,
      remarks,
      messageShow,
      messageType,
      messageInfo,
      submitted,
      activeIndex,
      formSize,
      particularsPrevInfos,
      openConfirm,
      openSubmit,
    } = this.state;

    return (
      <div style={{ margin: '10px auto', width: '70%' }}>
        <Form>
          <Form.Field>
            <label>Registration No.</label>
            <Dropdown
              search
              selection
              name='registrationNumber'
              options={plateNumberList}
              onChange={(e, data) =>
                this.setState({ registrationNumber: data.value })
              }
              value={registrationNumber}
              required
            />
          </Form.Field>

          <Accordion styled fluid>
            {formSize.map((index) => (
              <div key={index}>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={this.handleAccordion}
                  color='blue'
                >
                  <Icon name='dropdown' />
                  {particulars_dd
                    .find((item) => item.key === particulars[index])
                    .value.split('_')
                    .join(' ')
                    .toUpperCase()}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  <Divider horizontal />
                  {particularsPrevInfos[index] && (
                    <h4>
                      <span>
                        Qty: {particularsPrevInfos[index].purchase_qty},
                      </span>
                      &nbsp; &nbsp;
                      <span>
                        Meter: {particularsPrevInfos[index].present_meter},
                      </span>
                      &nbsp; &nbsp;
                      <span>
                        Date:{' '}
                        {moment(
                          particularsPrevInfos[index].purchase_date.$date,
                        ).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      <br />
                    </h4>
                  )}
                  <Form.Field>
                    <label>Particulars</label>
                    <Dropdown
                      name='particulars'
                      search
                      selection
                      options={particulars_dd.map(({ key, value }) => ({
                        key,
                        text: `${value.split('_').join(' ')} [${key}]`,
                        value: key,
                      }))}
                      value={particulars[index]}
                      onChange={(e, data) =>
                        this.handleDropdown(data.value, index)
                      }
                      style={{
                        textTransform: 'capitalize',
                      }}
                      required
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Purchase Date</label>
                    <DatePicker
                      name='purchaseDate'
                      selected={purchaseDate[index]}
                      onChange={(value) =>
                        this.handlePurchaseDate(value, index)
                      }
                      dateFormat='LL'
                      maxDate={moment().add(0, 'days')}
                      required
                    />
                  </Form.Field>

                  <Form.Field>
                    <label name='purchaseFrom'>Purchase From </label>
                    <input
                      name='purchaseFrom'
                      value={purchaseFrom[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      type='text'
                      required
                    />
                  </Form.Field>

                  <Form.Field>
                    <label name='purchaseQty'>Purchase Qty</label>
                    <input
                      name='purchaseQty'
                      value={purchaseQty[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      type='text'
                      required
                    />
                  </Form.Field>

                  <Form.Field>
                    <label name='unitRate'>Unit Rate </label>
                    <input
                      name='unitRate'
                      value={unitRate[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      type='text'
                      required
                    />
                  </Form.Field>

                  {unitRate[index] * purchaseQty[index] ? (
                    <Form.Field>
                      <label>
                        Total: {unitRate[index] * purchaseQty[index]}
                      </label>
                    </Form.Field>
                  ) : null}

                  <Form.Field>
                    <label name='presentMeter'>Present Meter</label>
                    <input
                      name='presentMeter'
                      value={presentMeter[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      type='text'
                      required
                    />
                  </Form.Field>

                  <Form.Field>
                    <label name='modeOfPayment'>Payment </label>
                    <select
                      name='modeOfPayment'
                      value={modeOfPayment[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      style={{
                        textTransform: 'capitalize',
                      }}
                      required
                    >
                      {payment_dd.map((item) => (
                        <option value={item} key={item}>
                          {item.split('_').join(' ')}
                        </option>
                      ))}
                    </select>
                  </Form.Field>

                  <Form.Field>
                    <label name='branch'>Branch</label>
                    <select
                      name='branch'
                      value={branch[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      style={{
                        textTransform: 'capitalize',
                      }}
                      required
                    >
                      {branch_dd.map((item) => (
                        <option value={item} key={item}>
                          {item.split('_').join(' ')}
                        </option>
                      ))}
                    </select>
                  </Form.Field>

                  <Form.Field>
                    <label name='remarks'>Remarks</label>
                    <input
                      name='remarks'
                      value={remarks[index]}
                      onChange={(e) => this.handleChange(e, index)}
                      type='text'
                      required
                    />
                  </Form.Field>
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
          <br />
        </Form>

        {registrationNumber && (
          <Button
            onClick={() => this.setState({ openConfirm: true })}
            icon='plus'
            label={`${formSize.length} spare part(s) added.`}
          />
        )}
        <Confirm
          open={openConfirm}
          onCancel={() => this.setState({ openConfirm: false })}
          onConfirm={this.addNewForm}
          header='New Form'
          content='Confirm if you have available spare parts information to insert.'
        />

        <br />
        {messageShow && <Message type={messageType} info={messageInfo} />}
        <br />

        {formSize.length > 0 && (
          <Button
            primary
            disabled={submitted}
            loading={submitted}
            onClick={() => this.setState({ openSubmit: true })}
            fluid
          >
            Submit
          </Button>
        )}
        <Confirm
          open={openSubmit}
          onCancel={() => this.setState({ openSubmit: false })}
          onConfirm={this.handleSubmit}
          header='Final Submission'
          content='Confirm if you want to submit all spare parts information.'
        />
      </div>
    );
  }
}

export default Add;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const PaymentFail = () => {
  return (
    <section className='payment-container'>
      <div className='payment-header-error'>
        <img
          src='payment/attention.png'
          className='payment-header-icon'
          alt='Payment failed'
        />
        Payment Failed!
      </div>
      <div className='payment-subheader'>
        Sorry, the payment was not successful.
      </div>
      <Button size='large'>
        <Link href='/'>OK</Link>
      </Button>
    </section>
  );
};

export default PaymentFail;

import React from 'react';
import {
  Segment,
  Header,
  Icon,
  Button,
  Loader,
  Dimmer,
  Message,
  Modal,
  Input,
} from 'semantic-ui-react';
import { Map, Polygon, FeatureGroup, Marker } from 'react-leaflet';
import Leaflet from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';
import MapLayers from '../MapLayers';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class DrawGeofence extends React.Component {
  state = {
    geofenceList: [],
    geofenceLoading: true,
    currentGeofence: null,
    currentGeofenceName: null,
    polygon: [],
    position: [],
    negativeMessage: false,
    message: false,
    submitted: false,
    geofenceLatLng: [],
    geofenceName: '',
    openNameModal: false,
    edit: true,
    centerPosition: [],
  };

  componentDidMount() {
    this.fetchGeoList();

    const params = new URLSearchParams(this.props.location.search);

    if (params.size > 0) {
      const lat = params.get('lat') || '';
      const lng = params.get('lng') || '';

      if (lat && lng) {
        this.setState({ centerPosition: [lat, lng] });
      }
    }
  }

  componentDidUpdate(prevProps, prevSate) {
    if (
      this.state.currentGeofence &&
      prevSate.currentGeofence !== this.state.currentGeofence
    ) {
      setTimeout(() => {
        this.zoomToPolygon();
      }, 1000);
    }
  }

  fetchGeoList = () => {
    this.setState({ geofenceLoading: true });
    axios
      .get(`${REGULAR_PATH}/geofence?org_id=${org()}&access_token=${token()}`)
      .then((response) => {
        if (response.data.status) {
          this.showNegMessage(response.data.status);
          this.setState({ geofenceLoading: false });
        } else {
          const data = response.data.map((item) => {
            return {
              id: item._id.$oid,
              name: item.name.trim(),
              assetList: item.enforced_assets.map((asset) => asset._id.$oid),
              coordinates: item.polygon.geometry.coordinates[0],
            };
          });

          const { id = '' } = this.props.match.params;

          if (id) {
            const geofence = data.find((item) => item.id === id);
            const polygon = JSON.parse(
              JSON.stringify(geofence.coordinates),
            ).map((item) => item.reverse());

            this.setState({
              geofenceLoading: false,
              geofenceList: data,
              polygon,
              currentGeofence: id,
              edit: false,
            });
          } else {
            this.setState({
              edit: true,
              geofenceLoading: false,
              geofenceList: data,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  showNegMessage = (message = 'Sorry. Something went wrong.') => {
    this.setState({
      negativeMessage: message,
      submitted: false,
    });
    const hideNegMessage = setTimeout(() => {
      this.setState({
        negativeMessage: false,
      });
      clearTimeout(hideNegMessage);
    }, 2000);
  };

  showMessage = (message) => {
    this.setState({
      message,
      submitted: false,
    });
    const hideMessage = setTimeout(() => {
      this.setState({
        message: false,
      });
      this.props.history.push('/geofence');
      clearTimeout(hideMessage);
    }, 2000);
  };

  handleNameModal = () => {
    this.setState((prevState) => ({
      openNameModal: !prevState.openNameModal,
    }));
  };

  handleName = (e) => {
    this.setState({ geofenceName: e.target.value });
  };

  onGeofenceCreate = (e) => {
    this.setState({
      geofenceLatLng: e.layer._latlngs,
      openDrawModal: false,
      openNameModal: true,
    });
  };

  addGeofence = () => {
    const { geofenceLatLng, geofenceName } = this.state;
    this.setState({ submitted: true });
    axios
      .post(
        `${REGULAR_PATH}/geofence?access_token=${token()}`,
        JSON.stringify({
          coordinates: geofenceLatLng[0].map((item) => [item.lng, item.lat]),
          name: geofenceName,
          org_id: org(),
        }),
      )
      .then(() => {
        this.setState({
          openNameModal: false,
          geofenceLatLng: [],
          geofenceName: '',
        });
        this.showMessage('Geofence created successfully');
      })
      .catch(() => {
        this.setState({
          openNameModal: false,
        });
        this.showNegMessage();
      });
  };

  zoomToPolygon = () => {
    if (this.refs.leaflet) {
      this.refs.leaflet.leafletElement.fitBounds(
        this.refs.leafletPolygon.leafletElement.getBounds(),
      );
    }
  };

  onGeofenceEdit = (e) => {
    let data;
    const layers = e.layers._layers;
    if (Object.keys(layers).length > 0) {
      Object.keys(layers).forEach((layer) => {
        data = layers[layer]._latlngs;
      });
    }

    axios
      .put(
        `${REGULAR_PATH}/geofence?geo_id=${
          this.state.currentGeofence
        }&access_token=${token()}`,
        JSON.stringify({
          coordinates: data[0].map((item) => [item.lng, item.lat]),
        }),
      )
      .then(() => {
        this.showMessage('Geofence updated successfully');
      })
      .catch((error) => {
        console.error(error);
        this.showNegMessage();
      });
  };

  render() {
    const {
      polygon,
      geofenceLoading,
      negativeMessage,
      submitted,
      openNameModal,
      geofenceName,
      edit,
      message,
      centerPosition,
    } = this.state;

    if (geofenceLoading) {
      return (
        <Dimmer active>
          <Loader size='large'>Loading...</Loader>
        </Dimmer>
      );
    }

    const MarkerIcon = Leaflet.icon({
      iconUrl: '/images/marker.png',
      iconSize: [20, 30],
    });

    return (
      <Segment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 0 20px 0',
            fontSize: '18px',
            fontWeight: '400',
          }}
        >
          <Header as='h3'>
            <Icon name='life ring' />
            Geofence List
          </Header>
          <Link to={`/geofence`}>
            <Button
              primary
              content='Back'
              icon='arrow left'
              labelPosition='left'
              size='small'
            />
          </Link>
        </div>

        {message && <Message icon='check' header={message} color='green' />}
        {negativeMessage && (
          <Message icon='check' header={negativeMessage} color='red' />
        )}

        <Map
          id='drawgeomap'
          ref='leaflet'
          animate={true}
          bounds={[
            [30.543338954230222, 94.65820312500001],
            [21.309846141087203, 79.13452148437501],
          ]}
        >
          <MapLayers />

          {centerPosition.length > 0 && (
            <Marker icon={MarkerIcon} position={centerPosition} />
          )}

          <FeatureGroup>
            <EditControl
              position='topright'
              onCreated={this.onGeofenceCreate}
              onEdited={this.onGeofenceEdit}
              draw={{
                polygon: edit,
                polyline: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{
                remove: false,
              }}
            />
            {polygon.length > 0 ? (
              <Polygon
                ref='leafletPolygon'
                positions={polygon}
                smoothFactor={1}
              />
            ) : null}
          </FeatureGroup>
        </Map>

        <Modal
          closeOnDimmerClick={false}
          closeIcon
          onClose={this.handleNameModal}
          open={openNameModal}
        >
          <Modal.Header>Geofence Name</Modal.Header>
          <Modal.Content>
            <Input fluid value={geofenceName} onChange={this.handleName} />
            <br />
            {this.state.geofenceList.some(
              (item) =>
                item.name.toLowerCase() === geofenceName.toLowerCase().trim(),
            ) ? (
              <Message negative>This name already exist</Message>
            ) : (
              <Button
                color='violet'
                fluid
                onClick={this.addGeofence}
                disabled={submitted}
              >
                Submit
              </Button>
            )}
          </Modal.Content>
        </Modal>
      </Segment>
    );
  }
}

export default DrawGeofence;

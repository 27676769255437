import React from 'react';
import { Label, Table, Button } from 'semantic-ui-react';
import FileSaver from 'file-saver';
import moment from 'moment';
import { reportDownloadExcel } from '../reports/helper/dataService';

class LocationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      visibleTable: false,
      asset_ids: [],
      downloading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.length > 0 && prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        asset_ids: this.props.data.map((item) => item.assetId),
      });
    }
  }

  handleDownload = () => {
    this.setState({ downloading: true });

    reportDownloadExcel(
      { excelPath: 'location_live_excel', apiVer: 'v1' },
      {
        asset_ids: this.state.asset_ids,
        org_oid: window.localStorage.getItem('organization'),
      },
    )
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Location_Live_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ downloading: false });
      })
      .catch((error) => {
        this.setState({ downloading: false });
        console.error(error);
      });
  };

  render() {
    const { data, downloading } = this.state;
    if (data.length > 0) {
      return (
        <div style={{ margin: '2%' }} id='live-report'>
          <div style={{ position: 'relative' }}>
            <h1 style={{ float: 'left' }}>
              <i className='location arrow icon' />
              Location Table
            </h1>
            <div style={{ float: 'right' }}>
              <Button
                color='teal'
                onClick={this.handleDownload}
                disabled={downloading}
                loading={downloading}
              >
                Location Live Report
              </Button>
            </div>
          </div>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Vehicle Name</Table.HeaderCell>
                <Table.HeaderCell>Last Seen on</Table.HeaderCell>
                <Table.HeaderCell>Speed ( km/h )</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((item, idx) => {
                let accConnected = item.acc_connected,
                  engineStatus;
                if (item.engine_sensor === 'True') {
                  engineStatus = true;
                } else {
                  engineStatus = false;
                }
                if (accConnected === 'True') {
                  accConnected = true;
                } else {
                  accConnected = false;
                }

                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      <Label>{idx + 1}</Label>
                    </Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.children[2].time}</Table.Cell>
                    <Table.Cell>
                      {Math.round(
                        accConnected
                          ? engineStatus
                            ? item.children[0].speed
                            : 0
                          : item.children[0].speed || 0,
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {accConnected ? (
                        <div>
                          Ignition:{' '}
                          {engineStatus ? (
                            <span style={{ verticalAlign: 'top' }}>
                              <span className='green-circle' />
                              On {item.children[0].speed > 0 ? null : `[Idle]`}
                            </span>
                          ) : (
                            <span style={{ verticalAlign: 'top' }}>
                              <span className='red-circle' />
                              Off
                            </span>
                          )}
                        </div>
                      ) : (
                        <span>N/A</span>
                      )}
                      {item.fuel_connected && item.fuel && (
                        <div>Fuel: {item.fuel} L</div>
                      )}
                      {item.temperature_sensor && (
                        <div>
                          Temperature: {Number(item.temperature).toFixed(1)} °C
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell
                      onClick={this.props.handleClickMarker.bind(
                        this,
                        idx,
                        true,
                      )}
                    >
                      <Button>Click Here</Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      );
    } else {
      return <span />;
    }
  }
}

export default LocationTable;

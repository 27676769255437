export const digitalInputValueFormat = (sensorType, hasValue, isReversed) => {
  const sensor = sensorType.toLowerCase();

  if (sensor === 'door') {
    if (isReversed) {
      return hasValue ? 'Closed' : 'Open';
    }
    return hasValue ? 'Open' : 'Closed';
  }

  if (isReversed) {
    return hasValue ? 'Off' : 'On';
  }

  return hasValue ? 'On' : 'Off';
};

import React from 'react';
import {
  Form,
  Select,
  Input,
  Loader,
  Button,
  Message,
} from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../../env';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

const toDecimal = 40.46860338724812;

class Add extends React.Component {
  state = {
    geofenceLoading: true,
    geofenceList: [],
    currentGeoId: '',
    currentAssetId: '',
    area: 0,
    rate: 0,
    amount: 0,
    status: '',
    submit: false,
  };

  componentDidMount() {
    this.fetchGeoList();
  }

  fetchGeoList = () => {
    this.setState({ geofenceLoading: true });
    axios
      .get(`${REGULAR_PATH}/geofence?org_id=${org()}&access_token=${token()}`)
      .then((response) => {
        const data = response.data.map((item) => ({
          id: item._id.$oid,
          name: item.name.trim(),
          area: item.area,
          assets: item.enforced_assets.map(({ _id, name }) => ({
            id: _id.$oid,
            name: name,
          })),
        }));

        this.setState({
          geofenceLoading: false,
          geofenceList: data,
        });
        if (data.length) {
          const area = (data[0].area / toDecimal).toFixed(2);
          this.setState({
            currentGeoId: data[0].id,
            area,
            amount: Math.round(area * this.state.rate),
          });
        }
        if (
          data.length &&
          data[0].hasOwnProperty('assets') &&
          data[0].assets.length
        ) {
          this.setState({
            currentAssetId: data[0].assets[0].id,
          });
        }
      })
      .catch((error) => {
        this.setState({ geofenceLoading: false });
      });
  };

  saveData = () => {
    const { geofenceList, currentGeoId, currentAssetId, area, amount } =
      this.state;
    const geofence = geofenceList.find(({ id }) => id === currentGeoId);
    this.setState({ submit: true, status: '' });
    axios
      .post(
        `${REGULAR_PATH}/syngenta_bill_rate?access_token=${token()}`,
        JSON.stringify({
          org_oid: org(),
          geo_oid: currentGeoId,
          geo_name: geofence.name,
          asset_oid: currentAssetId,
          asset_name: geofence.assets.find(({ id }) => id === currentAssetId)
            .name,
          area: Number(area).toFixed(2),
          amount: Math.round(amount),
        }),
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({ status, submit: false });
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleGeofenceSelection = (e, data) => {
    const geofenceList = this.state.geofenceList;
    this.setState({ currentGeoId: data.value });

    const geoData = geofenceList.filter(({ id }) => id === data.value);
    if (geoData[0].hasOwnProperty('assets') && geoData[0].assets.length) {
      this.setState({
        currentAssetId: geoData[0].assets[0].id,
      });
    } else {
      this.setState({ currentAssetId: '' });
    }
    const area = geoData[0].area ? (geoData[0].area / toDecimal).toFixed(2) : 0;
    this.setState({
      status: '',
      area,
      amount: Math.round(area * this.state.rate),
    });
  };

  handleAssetSelection = (e, data) => {
    this.setState({
      currentAssetId: data.value,
    });
  };

  handleArea = (e) => {
    let area = parseFloat(e.target.value);
    area = isNaN(area) ? 0 : area;
    this.setState({
      area,
      amount: Math.round(area * this.state.rate),
    });
  };

  handleRate = (e) => {
    let rate = parseInt(e.target.value);
    rate = isNaN(rate) ? 0 : rate;
    this.setState({
      rate,
      amount: Math.round(this.state.area * rate),
    });
  };

  handleAmount = (e) => {
    const amount = Number(e.target.value);
    this.setState({ amount: isNaN(amount) ? 0 : amount });
  };

  render() {
    const {
      currentGeoId,
      currentAssetId,
      geofenceList,
      geofenceLoading,
      area,
      rate,
      amount,
      status,
      submit,
    } = this.state;

    let currentAssetList = [];
    const currentGeofence = geofenceList.find(({ id }) => id === currentGeoId);

    if (
      currentGeofence &&
      currentGeofence.hasOwnProperty('assets') &&
      currentGeofence.assets.length
    ) {
      currentAssetList = currentGeofence.assets;
    }
    if (geofenceLoading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    if (!geofenceList.length) {
      return <h2>Geofence Empty</h2>;
    }

    return (
      <div>
        <Form>
          <Form.Field
            control={Select}
            options={geofenceList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            label='Geofences'
            placeholder='Select Geofence'
            value={currentGeoId}
            onChange={this.handleGeofenceSelection}
          />
          {currentGeoId && currentAssetList.length ? (
            <Form.Field
              control={Select}
              options={currentAssetList.map(({ id, name }) => ({
                key: id,
                value: id,
                text: name,
              }))}
              label='Assets'
              placeholder='Select Asset'
              value={currentAssetId}
              onChange={this.handleAssetSelection}
            />
          ) : null}
          {currentAssetId ? (
            <div>
              <Form.Field
                control={Input}
                label='Area [decimal]'
                placeholder='Input Area'
                onChange={this.handleArea}
                value={area}
              />
              <Form.Field
                control={Input}
                label='Rate per Decimal'
                placeholder='Input Rate'
                onChange={this.handleRate}
                value={rate}
              />
              <Form.Field
                control={Input}
                label='Amount [tk]'
                placeholder='Input Amount'
                onChange={this.handleAmount}
                value={amount}
              />
            </div>
          ) : null}
        </Form>
        {currentGeoId && currentAssetId && area ? (
          <Button
            primary
            style={{ marginTop: '15px' }}
            onClick={this.saveData}
            disabled={submit}
          >
            Submit
          </Button>
        ) : null}
        {status && status === 'Bill created.' && (
          <Message positive>
            Bill created successfully. Bill amount is {amount}.
          </Message>
        )}

        {status && status !== 'Bill created.' && (
          <Message negative> {status || 'Sorry Something Went Wrong'}</Message>
        )}
      </div>
    );
  }
}

export default Add;

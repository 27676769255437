import React, { Component } from 'react';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getData, editData } from '../dataHelper';
import Message from '../Message';

class Edit extends Component {
  state = {
    plateNumberList: this.props.plateNumberList,
    particulars_dd: [],
    particulars: '',
    firstTime: false,
    registrationNumber: '',
    sparePartsId: '',
    purchaseDate: null,
    purchaseFrom: '',
    purchaseQty: '',
    previousQty: '',
    previousPurchaseDate: null,
    unitRate: '',
    totalAmount: '',
    presentMeter: '',
    previousMeter: '',
    runningMillage: '',
    modeOfPayment: '',
    branch: '',
    remarks: '',
    messageShow: false,
    messageType: '',
    messageInfo: '',
    submitted: false,
    entryId: '',
    branch_dd: [],
    payment_dd: [],
  };

  componentDidMount() {
    this.fetchParticulars();

    const {
      asset_id,
      particulars,
      purchase_date,
      purchase_from,
      purchase_qty,
      unit_rate,
      present_meter,
      payment_mode,
      current_branch,
      remarks,
      _id,
    } = this.props.data;

    this.setState({
      registrationNumber: asset_id,
      particulars: particulars.key,
      purchaseDate: purchase_date.$date
        ? moment(purchase_date.$date)
        : moment(),
      purchaseFrom: purchase_from,
      purchaseQty: purchase_qty,
      unitRate: unit_rate,
      presentMeter: present_meter,
      modeOfPayment: payment_mode,
      branch: current_branch,
      remarks,
      entryId: _id.$oid,
    });
  }

  fetchParticulars = () => {
    getData(
      `${this.props.org}_dropdown`,
      `key=particulars&drop_down_name=particular_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    )
      .then(({ data }) => {
        if (data.length > 0) {
          this.setState({
            particulars_dd: data.filter(({ status }) => status === 'active'),
          });
        }
      })
      .then(() => {
        getData(
          `${this.props.org}_dropdown`,
          `key=current_branch&drop_down_name=branch_dropdown&org_id=${window.localStorage.getItem(
            'organization',
          )}`,
        )
          .then((response) => {
            this.setState({
              branch_dd: response.data,
            });
          })
          .then(() => {
            getData(
              `${this.props.org}_dropdown`,
              `key=payment_mode&drop_down_name=payment_mode_dropdown&org_id=${window.localStorage.getItem(
                'organization',
              )}`,
            ).then((response) => {
              this.setState({
                payment_dd: response.data,
              });
            });
          });
      })
      .catch((error) => console.error(error));
  };

  handlePurchaseDate = (value) => {
    this.setState({
      purchaseDate: value,
    });
  };

  handlePreviousPurchaseDate = (value) => {
    this.setState({
      previousPurchaseDate: value,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ messageShow: false, submitted: true });
    const {
      purchaseFrom,
      unitRate,
      modeOfPayment,
      branch,
      remarks,
      entryId,
      purchaseQty,
      purchaseDate,

      particulars_dd,
      particulars,
    } = this.state;

    const data = {
      entry_id: entryId,
      particulars: particulars_dd.find((item) => item.key === particulars),
      purchase_date: moment(purchaseDate).unix(),
      purchase_from: purchaseFrom,
      purchase_qty: purchaseQty,
      unit_rate: unitRate,
      payment_mode: modeOfPayment,
      current_branch: branch,
      remarks,
    };

    editData(`${this.props.org}_spare_parts`, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'positive',
            submitted: false,
          });
          setTimeout(() => {
            this.setState({ messageShow: false });
          }, 3000);
        } else if (status === 'failed') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'warning',
            submitted: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitted: false });
      });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ registrationNumber: data.value });
  };

  render() {
    const {
      particulars_dd,

      particulars,

      purchaseDate,
      purchaseFrom,

      purchaseQty,
      unitRate,

      modeOfPayment,
      branch,
      remarks,
      messageShow,
      messageType,
      messageInfo,
      submitted,
      branch_dd,
      payment_dd,
    } = this.state;

    return (
      <div style={{ margin: '10px auto', width: '70%' }}>
        <Form>
          <Form.Field>
            <label>Particulars</label>
            <Dropdown
              name='particulars'
              search
              selection
              options={particulars_dd.map(({ key, value }) => ({
                key,
                text: `${value.split('_').join(' ')} [${key}]`,
                value: key,
              }))}
              value={particulars}
              onChange={(e, data) => this.setState({ particulars: data.value })}
              style={{
                textTransform: 'capitalize',
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>Purchase Date</label>
            <DatePicker
              selected={purchaseDate}
              onChange={this.handlePurchaseDate}
              dateFormat='LL'
              maxDate={moment().add(0, 'days')}
            />
          </Form.Field>

          <Form.Field>
            <label name='purchaseFrom'>Purchase From </label>
            <input
              value={purchaseFrom}
              onChange={this.handleChange}
              name='purchaseFrom'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='purchaseQty'>Purchase Qty</label>
            <input
              value={purchaseQty}
              onChange={this.handleChange}
              name='purchaseQty'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='unitRate'>Unit Rate </label>
            <input
              value={unitRate}
              onChange={this.handleChange}
              name='unitRate'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='modeOfPayment'>Payment </label>
            <select
              name='modeOfPayment'
              value={modeOfPayment}
              onChange={this.handleChange}
              style={{
                textTransform: 'capitalize',
              }}
            >
              {payment_dd.map((item) => (
                <option value={item} key={item}>
                  {item.split('_').join(' ')}
                </option>
              ))}
            </select>
          </Form.Field>

          <Form.Field>
            <label name='branch'>Branch</label>
            <select
              name='branch'
              value={branch}
              onChange={this.handleChange}
              style={{
                textTransform: 'capitalize',
              }}
            >
              {branch_dd.map((item) => (
                <option value={item} key={item}>
                  {item.split('_').join(' ')}
                </option>
              ))}
            </select>
          </Form.Field>

          <Form.Field>
            <label name='remarks'>Remarks</label>
            <textarea
              placeholder='Remarks'
              value={remarks}
              onChange={this.handleChange}
              name='remarks'
              type='text'
            />
          </Form.Field>
        </Form>

        <br />
        {messageShow && <Message type={messageType} info={messageInfo} />}

        <Button
          primary
          disabled={submitted}
          loading={submitted}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default Edit;

import React, { Component } from 'react';
import { Segment, Button, Input, Header, Table } from 'semantic-ui-react';
import { REGULAR_PATH } from '../../env';
import axios from 'axios';
import { alertMessage } from '../helper/alert-message';

export default class TrackByCode extends Component {
  state = {
    loading: '',
    codes: [],
    message: '',
    code: '',
  };
  componentDidMount() {
    this.fetchLinks();
  }

  fetchLinks = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${REGULAR_PATH}/assets/shared_assets/list?access_token=${window.localStorage.getItem(
          'user',
        )}`,
      )
      .then(({ data }) => {
        this.setState({
          loading: false,
          codes: data,
          message: '',
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    const exists = this.state.codes.some(
      (asset) => asset.asset_shared_code === this.state.code,
    );
    if (exists) {
      alertMessage('This code already exists');
    } else {
      axios
        .post(
          `${REGULAR_PATH}/assets/shared_assets/list?access_token=${window.localStorage.getItem(
            'user',
          )}`,
          JSON.stringify({ code: this.state.code }),
        )
        .then(({ data }) => {
          if (data.status === 'success') {
            alertMessage('Successfully Added');
            this.setState((prevState) => ({
              codes: [
                ...prevState.codes,
                { asset_shared_code: this.state.code },
              ],
            }));
            this.setState({ code: '' });
          } else if (data.error) {
            alertMessage(data.error);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }
  };

  handleDelete = (code) => {
    axios
      .delete(
        `${REGULAR_PATH}/assets/shared_assets/list?code=${code}&access_token=${window.localStorage.getItem(
          'user',
        )}`,
      )
      .then(({ data }) => {
        if (data.staus === 'success') {
          this.setState({
            codes: this.state.codes.filter(
              (asset) => asset.asset_shared_code !== code,
            ),
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { codes, code } = this.state;

    return (
      <Segment style={{ padding: 30 }}>
        <Header as='h2'>Track By Code</Header>
        <div style={{ display: 'flex', gap: 10, margin: '20px 0px' }}>
          <Input
            focus
            placeholder='Enter Code'
            value={code}
            onChange={(e) => this.setState({ code: e.target.value })}
          />
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={!this.state.code}
          >
            Submit
          </Button>
        </div>

        <Segment style={{ marginTop: 30 }}>
          <Table celled>
            <Table.Header>
              <Table.HeaderCell>Serial</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {codes.map((code, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{code.asset_shared_code}</Table.Cell>
                  <Table.Cell>
                    <Button
                      color='red'
                      size='mini'
                      onClick={() => this.handleDelete(code.asset_shared_code)}
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            {codes.length === 0 ? (
              <div style={{ padding: 20 }}>
                <Header as='h5'>No Data</Header>
              </div>
            ) : null}
          </Table>
        </Segment>
      </Segment>
    );
  }
}

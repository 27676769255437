import React from 'react';
import { Loader, Icon, Table, Button, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportView,
  reportDownloadPdf,
  reportDownloadExcel,
} from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.distanceInTimeA;

class DistanceInTimeA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportView(reportsEnv.distanceInTimeA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        this.setState({
          data: response.data,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadPdf(reportsEnv.distanceInTimeA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Distance_In_Time_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(reportsEnv.distanceInTimeA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Distance_In_Time_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Distance in Time [A] [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file pdf outline'
                  content='Download PDF'
                  onClick={this.handleDownloadPdfBtn}
                  disabled={pdfDisableBtn || excelDisableBtn}
                  loading={pdfDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Distance In Time [A]</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />
            {data.map((item, i) => (
              <div key={i} id={item.asset.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h2 className='ui center aligned icon header'>
                          <i className='circular car icon' />
                          Distance: {parseInt(item.distance)} km
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Travelled (km)</Table.HeaderCell>
                      <Table.HeaderCell>Max Speed</Table.HeaderCell>
                      <Table.HeaderCell>Start Time</Table.HeaderCell>
                      <Table.HeaderCell>Start Location</Table.HeaderCell>
                      <Table.HeaderCell>End Time</Table.HeaderCell>
                      <Table.HeaderCell>End Location</Table.HeaderCell>
                      <Table.HeaderCell>Landmarks Crossed</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {Math.round(item.distance * 100) / 100}
                      </Table.Cell>
                      <Table.Cell>{item.maxspeed}</Table.Cell>
                      <Table.Cell>
                        {item.start_time
                          ? moment(item.start_time.$date).format(
                              'dddd, MMMM Do, YYYY, hh:mm:ss A',
                            )
                          : ''}
                      </Table.Cell>
                      <Table.Cell>
                        {item.start_nlm != null ? (
                          item.start_nlm.map((nlm, x) => (
                            <p key={x}>
                              <i className='marker icon' />
                              {nlm.name}
                            </p>
                          ))
                        ) : (
                          <p>
                            <i className='marker icon' />
                            No Data Found
                          </p>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {item.end_time
                          ? moment(item.end_time.$date).format(
                              'dddd, MMMM Do, YYYY, hh:mm:ss A',
                            )
                          : ''}
                      </Table.Cell>
                      <Table.Cell>
                        {item.end_nlm != null ? (
                          item.end_nlm.map((nlm, x) => (
                            <p key={x}>
                              <i className='marker icon' />
                              {nlm.name}
                            </p>
                          ))
                        ) : (
                          <p>
                            <i className='marker icon' />
                            No Data Found
                          </p>
                        )}
                      </Table.Cell>

                      <Table.Cell>
                        {item.landmarks.length ? (
                          item.landmarks.map((landmark, x) => (
                            <p key={x}>
                              <i className='marker icon' />
                              {landmark.name}
                            </p>
                          ))
                        ) : (
                          <p>
                            <i className='marker icon' />
                            No Landmark Found
                          </p>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default DistanceInTimeA;

import React from 'react';
import { Input, Button, Table } from 'semantic-ui-react';
import { getData, editData, postData, deleteOption } from '../dataHelper';
import Message from '../Message';

class DamageItem extends React.Component {
  state = {
    titles: [],
    value: '',
    selectedValue: '',
    newValue: '',
    showMessage: false,
    message: '',
    messageType: 'positive',
  };

  componentDidMount() {
    getData(
      `${this.props.org}_dropdown`,
      `key=damage_item&drop_down_name=damage_item_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    ).then((response) => {
      this.setState({
        titles: response.data,
      });
    });
  }

  handleItem = (e, data) => {
    this.setState({ value: data.value });
  };

  handleSubmit = () => {
    const { value } = this.state;
    postData(`${this.props.org}_dropdown`, {
      update_type: 'damage_item',
      value: value.toLowerCase().split(' ').join('_'),
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'damage_item_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: [...prevState.titles, value],
            value: '',
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  enableEdit = (selectedValue) => {
    this.setState({
      selectedValue,
      newValue: selectedValue.split('_').join(' '),
    });
  };

  handleEditItem = (e, data) => {
    this.setState({ newValue: data.value });
  };

  handleUpdate = () => {
    const { selectedValue, newValue } = this.state;
    editData(`${this.props.org}_dropdown`, {
      update_type: 'damage_item',
      prev_value: selectedValue.toLowerCase(),
      value: newValue.toLowerCase().split(' ').join('_'),
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'damage_item_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: [
              ...prevState.titles.filter((item) => item !== selectedValue),
              newValue,
            ],
            selectedValue: '',
            newValue: '',
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  handleDelete = (value) => {
    value = value.toLowerCase().split(' ').join('_');
    deleteOption(`${this.props.org}_dropdown`, {
      update_type: 'damage_item',
      value,
      org_id: window.localStorage.getItem('organization'),
      drop_down_name: 'damage_item_dropdown',
    })
      .then((response) => {
        const { status, message } = response.data;
        if (status === 'success') {
          this.setState((prevState) => ({
            titles: prevState.titles.filter((item) => item !== value),
          }));
          this.showMessage(message, 'positive');
        } else {
          this.showMessage(message, 'warning');
        }
      })
      .catch((error) => console.error(error));
  };

  showMessage = (message, messageType) => {
    this.setState({ showMessage: true, message, messageType });
    setTimeout(() => {
      this.setState({ showMessage: false });
    }, 3000);
  };

  render() {
    const {
      titles,
      value,
      selectedValue,
      newValue,
      showMessage,
      message,
      messageType,
    } = this.state;
    return (
      <div>
        <div>
          <Input
            onChange={this.handleItem}
            value={value}
            placeholder='Add here'
          />
          <Button color='green' onClick={this.handleSubmit}>
            Add
          </Button>
        </div>

        {showMessage && <Message type={messageType} info={message} />}
        {titles.length > 0 && (
          <Table celled textAlign='center'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Sl No.</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {titles.map((item, index) => (
                <Table.Row key={item}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell style={{ textTransform: 'capitalize' }}>
                    {selectedValue === item ? (
                      <Input
                        onChange={this.handleEditItem}
                        placeholder='Add here'
                        value={newValue}
                      />
                    ) : (
                      item.split('_').join(' ')
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {selectedValue === item ? (
                      <span>
                        <Button color='green' onClick={this.handleUpdate}>
                          Update
                        </Button>
                        <Button onClick={() => this.enableEdit('')}>
                          Cancel
                        </Button>
                      </span>
                    ) : (
                      <span>
                        <Button
                          color='green'
                          onClick={() => this.enableEdit(item)}
                        >
                          Edit
                        </Button>
                        <Button
                          color='red'
                          onClick={() => this.handleDelete(item)}
                        >
                          Remove
                        </Button>
                      </span>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}

export default DamageItem;

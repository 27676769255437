import React from 'react';
import { Menu, Loader } from 'semantic-ui-react';

import Incidents from './Incidents';
import FutureTasks from './FutureTasks';
import Add from './Add';
import Edit from './Edit';
import Download from './Download';
import { getData } from '../dataHelper';

class Incident extends React.Component {
  state = {
    activeItem: 'Incidents',
    editEvent: false,
    plateNumberList: [],
    task: '',
    isListEmpty: true,
    plateNumbers: this.props.plateNumbers,
    branches: [],
    titles: [],
    isLoading: true,
  };

  componentDidMount() {
    this.fetchTitleAndBranch();
  }

  fetchTitleAndBranch = () => {
    getData(
      `${this.props.org}_dropdown`,
      `drop_down_name=branch_dropdown&org_id=${window.localStorage.getItem(
        'organization',
      )}`,
    )
      .then((response) => {
        const { current_branch = [] } = response.data;
        this.setState({ branches: current_branch });
      })
      .then(() => {
        getData(
          `${this.props.org}_dropdown`,
          `drop_down_name=damage_item_dropdown&org_id=${window.localStorage.getItem(
            'organization',
          )}`,
        ).then((response) => {
          const { damage_item = [] } = response.data;
          this.setState({
            titles: damage_item,
            isLoading: false,
          });
        });
      })
      .catch((error) => error);
  };

  handleEdit = (type, task, action) => {
    this.setState({ editEvent: type, task, activeItem: action });
  };

  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, editEvent: false });

  render() {
    const {
      activeItem,
      plateNumbers,
      editEvent,
      task,
      titles,
      branches,
      isLoading,
    } = this.state;
    return !isLoading ? (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name='Incidents'
            active={activeItem === 'Incidents'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Future Task'
            active={activeItem === 'Future Task'}
            onClick={this.handleItemClick}
          />
          {!editEvent && (
            <Menu.Item
              name='Add'
              active={activeItem === 'Add'}
              onClick={this.handleItemClick}
            />
          )}
          {editEvent && (
            <Menu.Item
              name='Edit'
              active={activeItem === 'Edit'}
              onClick={this.handleItemClick}
            />
          )}
          <Menu.Item
            name='Download'
            active={activeItem === 'Download'}
            onClick={this.handleItemClick}
          />
        </Menu>
        {activeItem === 'Incidents' && (
          <Incidents
            plateNumberList={plateNumbers}
            handleEdit={this.handleEdit}
            org={this.props.org}
          />
        )}
        {activeItem === 'Future Task' && (
          <FutureTasks
            plateNumberList={plateNumbers}
            handleEdit={this.handleEdit}
            org={this.props.org}
          />
        )}
        {activeItem === 'Add' && (
          <Add
            plateNumberList={plateNumbers}
            titles={titles}
            branches={branches}
            org={this.props.org}
          />
        )}

        {activeItem === 'Edit' && (
          <Edit
            plateNumberList={plateNumbers}
            handleEdit={this.handleEdit}
            task={task}
            titles={titles}
            branches={branches}
            org={this.props.org}
          />
        )}

        {activeItem === 'Download' && (
          <Download plateNumberList={plateNumbers} org={this.props.org} />
        )}
      </div>
    ) : (
      <Loader active inline='centered' />
    );
  }
}

export default Incident;

import React from 'react';
import { Icon, Button, Header, Label, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { alertMessage } from '../../../helper/alert-message';
import { reportDownloadExcel } from '../helper/dataService';
import { getIdsAndTime, getDuration, getOrg } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const { durationLimit, assetLimit } = reportsEnv.locationA;

class LocationA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelDisableBtn: false,
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      isCoordinates: false,
      isLandmark: false,
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    const { isCoordinates, isLandmark } = this.state;

    reportDownloadExcel(reportsEnv.locationA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
      org_oid: getOrg(),
      is_coordinates: isCoordinates,
      is_landmark: isLandmark,
      interval: reportsEnv.locationA.interval,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_LocationA_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleCheck = (e, data) => {
    const { name, checked } = data;
    this.setState({ [name]: checked });
  };

  render() {
    const {
      selectedAssets,
      excelDisableBtn,
      startTime,
      endTime,
      isCoordinates,
      isLandmark,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Location [${durationLimit} days][${assetLimit} asset]`;

    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div className='center-report'>
        <div>
          <Header as='h2'>{title}</Header>
        </div>

        <div style={{ margin: '20px 0' }}>
          <Label.Group size='large'>
            <Label>
              <Icon name='car' />
              {assetsQuantity} assets
            </Label>
            <Label>
              <Icon name='calendar' />
              {duration} days
            </Label>
            <Label>
              <Icon name='play' />
              {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
            </Label>
            <Label>
              <Icon name='stop' />
              {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
            </Label>
          </Label.Group>
        </div>

        <div style={{ margin: '10px 0 20px 0' }}>
          <Checkbox
            label='With Coordinates?'
            onClick={this.handleCheck}
            checked={isCoordinates}
            name='isCoordinates'
            style={{ margin: '0 10px' }}
          />

          <Checkbox
            label='With Landmark?'
            onClick={this.handleCheck}
            checked={isLandmark}
            name='isLandmark'
            style={{ margin: '0 10px' }}
          />
        </div>

        {isValidDuration && isValidAsset && (
          <Button.Group>
            <Button
              color='teal'
              style={{ width: '200px', fontSize: '16px' }}
              icon='file excel outline'
              content='Download Excel'
              onClick={this.handleDownloadExcelBtn}
              disabled={excelDisableBtn}
              loading={excelDisableBtn}
            />
          </Button.Group>
        )}
      </div>
    );
  }
}

export default LocationA;

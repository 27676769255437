import React from 'react';
import { Dropdown, Button } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import FileSaver from 'file-saver';

import DatePicker from 'react-datepicker';
import { alertMessage } from '../../../helper/alert-message';
import { BASE_PATH } from '../../../../env';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelDisableBtn: false,
      assetsList: this.props.plateNumberList,
      selectedAssets: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
    };
  }

  handleStartTime = (value) => {
    this.setState({
      startTime: value,
    });
  };

  handleEndTime = (value) => {
    const { startTime } = this.state;

    if (value.isAfter(startTime)) {
      this.setState({
        endTime: value,
      });
    } else {
      alertMessage(`End time must be after start time.`);
    }
  };

  handleAssetSelect = (e, data) => {
    this.setState({ selectedAssets: data.value });
  };

  handleAllAssetSelect = () => {
    this.setState((prevState) => ({
      selectedAssets:
        prevState.selectedAssets.length === prevState.assetsList.length
          ? []
          : this.state.assetsList.map((item) => item.value),
    }));
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });
    const { selectedAssets, startTime, endTime } = this.state;

    const startTimeUnix = this.momentToUnix(startTime);
    const endTimeUnix = this.momentToUnix(endTime);
    const user = window.localStorage.getItem('user');

    axios({
      method: 'post',
      url: `${BASE_PATH}/v1/reports/${this.props.org}_spare_parts_report?access_token=${user}`,
      data: {
        asset_ids: [selectedAssets],
        from: startTimeUnix,
        to: endTimeUnix,
      },
      responseType: 'blob',
      headers: {
        'content-type': 'text/plain',
      },
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Spare_Parts_Report_${moment().format('DD-MMMM-YYYY_HH:mm A')}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  momentToUnix = (time) => moment(time).unix();

  render() {
    const { assetsList, selectedAssets, excelDisableBtn, startTime, endTime } =
      this.state;

    const duration = parseInt(endTime.diff(startTime, 'days')) + 1;

    return (
      <div>
        <div className='center-of-page'>
          <div className='date-time-report'>
            <DatePicker
              selected={startTime}
              onChange={this.handleStartTime}
              showTimeSelect
              maxDate={moment().add(0, 'days')}
              timeIntervals={5}
              dateFormat='LLL'
            />
            <span
              style={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              —
            </span>
            <DatePicker
              selected={endTime}
              onChange={this.handleEndTime}
              showTimeSelect
              maxDate={moment().add(0, 'days')}
              timeIntervals={5}
              dateFormat='LLL'
            />
          </div>
          <br />
          <div>
            <Dropdown
              placeholder='Select Assets'
              fluid
              search
              selection
              options={assetsList}
              onChange={this.handleAssetSelect}
              value={selectedAssets}
            />
          </div>
          <br />
          {/* <Checkbox
            toggle
            label={`${
              assetsList.length === selectedAssets.length
                ? 'Remove All'
                : 'Select All'
            }`}
            onChange={this.handleAllAssetSelect}
            style={{ textAlign: 'left' }}
          /> */}

          {selectedAssets.length > 0 && duration <= 31 && (
            <Button.Group vertical>
              <Button
                color='teal'
                style={{ marginTop: '20px', fontSize: '16px' }}
                icon='file excel outline'
                content='Download Excel'
                onClick={this.handleDownloadExcelBtn}
                disabled={excelDisableBtn}
                loading={excelDisableBtn}
              />
            </Button.Group>
          )}
        </div>
      </div>
    );
  }
}

export default Download;

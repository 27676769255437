import React from 'react';
import { Table, Statistic, Header, Label } from 'semantic-ui-react';

class TPMS extends React.Component {
  state = {
    tpmsData: [],
  };

  componentDidMount() {
    this.setState({ tpmsData: this.props.tpmsData });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tpmsData !== this.props.tpmsData) {
      this.setState({ tpmsData: this.props.tpmsData });
    }
  }
  showDetails = (whichTire) => {
    const { tire_temp, tire_voltage, tire_status, tire_pressure } =
      this.state.tpmsData[whichTire];

    const convertVolt = (value) => (value * 0.1).toFixed(2);

    const kpaToPsi = (value) => parseInt(value * 0.145038);

    const tireName = (value) => {
      let name = '';
      switch (value) {
        case 'tire_T1':
          name = 'Front Left';
          break;

        case 'tire_T2':
          name = 'Front Right';
          break;
        case 'tire_T3':
          name = 'Back Left';
          break;
        case 'tire_T4':
          name = 'Back Right';
          break;
        default:
          break;
      }
      return name;
    };

    return (
      <div style={{ textAlign: 'center' }}>
        <Header as='h4'>{tireName(whichTire)}</Header>
        <Statistic label='°c' value={tire_temp} size='mini' />
        <Statistic label='PSI' value={kpaToPsi(tire_pressure)} size='small' />
        <Statistic label='V' value={convertVolt(tire_voltage)} size='mini' />
        <br />
        <Label basic color='teal' size='medium'>
          {tire_status}
        </Label>
      </div>
    );
  };

  kpaToPsi = (value) => parseInt(value * 0.145038);
  render() {
    // (this.state.tpmsData);
    const { tpmsData } = this.state;
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Header as='h2'>Tire Details</Header>
        <Table textAlign='center' celled>
          {/* <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Left</Table.HeaderCell>
              <Table.HeaderCell colSpan="2">Right</Table.HeaderCell>
            </Table.Row>
          </Table.Header> */}
          {Object.keys(tpmsData).length === 4 && (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan='2'>
                  {this.showDetails('tire_T1')}
                </Table.Cell>
                <Table.Cell colSpan='2'>
                  {this.showDetails('tire_T2')}
                </Table.Cell>
              </Table.Row>
              {/* <Table.Row>
                <Table.Cell>{this.showDetails("tire_T1")}</Table.Cell>
                <Table.Cell>{this.showDetails("tire_T2")}</Table.Cell>
                <Table.Cell>{this.showDetails("tire_T3")}</Table.Cell>
                <Table.Cell>{this.showDetails("tire_T4")}</Table.Cell>
              </Table.Row> */}
              <Table.Row>
                <Table.Cell colSpan='2'>
                  {this.showDetails('tire_T3')}
                </Table.Cell>
                <Table.Cell colSpan='2'>
                  {this.showDetails('tire_T4')}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}

export default TPMS;

import React, { Component } from 'react';
import {
  Table,
  Pagination,
  Icon,
  Loader,
  Button,
  Popup,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { getData, deleteData } from '../dataHelper';
import moment from 'moment';
import Message from '../Message';

class List extends Component {
  state = {
    isConfirm: false,
    limit: 20,
    completed: true,
    asset_id: '',
    plateNumberList: this.props.plateNumberList,
    plateNumber: '',
    data: [],
    activePage: 1,
    totalPages: 0,
    messageShow: false,
    messageType: '',
    messageInfo: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.plateNumber !== this.state.plateNumber) {
      this.fetchData();
    }
    if (prevState.activePage !== this.state.activePage) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ data: [], loaderActive: true });
    const { activePage, limit, plateNumber } = this.state;

    const asset_id = plateNumber ? plateNumber : '';

    getData(
      `${this.props.org}_servicing`,
      `page=${activePage - 1}&limit=${limit}&asset_id=${asset_id}`,
    )
      .then((response) => {
        const data = response.data.message;
        this.setState({
          data: data.data,
          totalPages: Math.ceil(data.total / data.limit),
          loaderActive: false,
        });
      })
      .catch((error) => error);
  };

  handleDelete = (id) => {
    this.setState({ isConfirm: false, data: [] });
    deleteData(`${this.props.org}_servicing`, id)
      .then((response) => {
        const { status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            data: [],
          });
          this.fetchData();
        } else if (status === 'error') {
          this.setState({
            messageShow: true,
          });
        }
        setTimeout(() => this.setState({ messageShow: false }), 3000);
      })
      .catch((error) => console.error(error));
  };

  handleConfirm = (confirm) => {
    this.setState({ isConfirm: confirm });
  };

  pagination = (e, data) => {
    this.setState({ activePage: data.activePage });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ plateNumber: data.value });
  };

  handleAll = () => {
    this.setState({ plateNumber: '' });
  };

  render() {
    const {
      isConfirm,
      plateNumberList,
      plateNumber,
      data,
      activePage,
      totalPages,
      loaderActive,
      messageShow,
    } = this.state;

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 15px',
            padding: '15px 0',
          }}
        >
          <div>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={plateNumber}
            />
            <Checkbox
              toggle
              onClick={this.handleAll}
              style={{ marginLeft: '10px' }}
              label='All'
              checked={plateNumber ? false : true}
            />
          </div>

          {data.length > 0 && (
            <div>
              <Pagination
                activePage={activePage}
                pointing
                secondary
                totalPages={totalPages}
                onPageChange={(e, data) => this.pagination(e, data)}
                firstItem={{
                  content: <Icon name='angle double left' />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name='angle double right' />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          )}
        </div>

        {messageShow && <Message type='warning' info='Successfully deleted.' />}

        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <Table celled textAlign='center'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Sl No.</Table.HeaderCell>
                <Table.HeaderCell>Registration No.</Table.HeaderCell>
                <Table.HeaderCell>Last Servicing Date</Table.HeaderCell>
                <Table.HeaderCell>Closing Meter No.</Table.HeaderCell>
                <Table.HeaderCell>Target Date</Table.HeaderCell>
                <Table.HeaderCell>Target K.M.</Table.HeaderCell>
                <Table.HeaderCell>Next Servicing Date</Table.HeaderCell>
                <Table.HeaderCell>Is it right time?</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Last Edited</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.length > 0 &&
                data.map((item, index) => (
                  <Table.Row key={item._id.$oid}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{item.asset_name}</Table.Cell>
                    <Table.Cell>
                      {item.last_servicing_date
                        ? moment(item.last_servicing_date.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                    </Table.Cell>
                    <Table.Cell>{item.closing_meter}</Table.Cell>
                    <Table.Cell
                      style={{
                        color: `${
                          item.target_date
                            ? moment(item.target_date.$date).diff(
                                moment(),
                                'days',
                              ) +
                                1 <=
                                7 && '#fa5252'
                            : 'rgba(0,0,0,.87)'
                        }`,
                      }}
                    >
                      {item.target_date
                        ? moment(item.target_date.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color:
                          Number(item.target_km) -
                            (Number(item.closing_meter) +
                              Number(item.km_ran_till_now)) <=
                          400
                            ? '#fa5252'
                            : 'rgba(0,0,0,.87)',
                      }}
                    >
                      {item.target_km}
                    </Table.Cell>
                    <Table.Cell>
                      {item.next_servicing_date.km}
                      {` OR `}
                      {item.next_servicing_date.next_service_date
                        ? moment(
                            item.next_servicing_date.next_service_date.$date,
                          ).format('dddd, MMMM Do, YYYY, hh:mm:ss A')
                        : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {item.is_it_right_time ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell>{item.remarks}</Table.Cell>
                    <Table.Cell>
                      {item.created_at
                        ? moment(item.created_at.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                      {` by `}
                      {item.created_by_email}
                    </Table.Cell>
                    <Table.Cell>
                      {item.last_modified_at
                        ? moment(item.last_modified_at.$date).format(
                            'dddd, MMMM Do, YYYY, hh:mm:ss A',
                          )
                        : ''}
                      {` by `}
                      {item.modified_by_email}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        positive
                        onClick={() => this.props.handleEdit(item)}
                        style={{ width: '100%', marginBottom: '10px' }}
                      >
                        Edit
                      </Button>

                      <Popup
                        trigger={
                          <Button
                            onClick={() => this.handleConfirm(item._id.$oid)}
                            style={{ width: '100%', marginBottom: '10px' }}
                          >
                            Delete
                          </Button>
                        }
                        content={
                          <div>
                            <Button
                              negative
                              onClick={() => this.handleDelete(item._id.$oid)}
                            >
                              Yes
                            </Button>
                            <Button onClick={() => this.handleConfirm(false)}>
                              No
                            </Button>
                          </div>
                        }
                        open={isConfirm === `${item._id.$oid}`}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <div style={{ textAlign: 'center' }}>
            <Loader active={loaderActive} />
            {!loaderActive && data.length === 0 && <h3>No Data Found</h3>}
          </div>
        </div>
      </div>
    );
  }
}

export default List;

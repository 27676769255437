import React from 'react';
import { Button, Message } from 'semantic-ui-react';

class Direction extends React.Component {
  state = {
    permissionsStatus: '',
  };
  componentDidMount() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      this.setState({ permissionsStatus: result.state });
    });
  }
  getDirection = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const [assetLatitude, assetLongitude] = this.props.assetPosition;
      window.open(
        `https://www.google.com/maps/dir/${latitude},${longitude}/${assetLatitude},${assetLongitude}`,
        '_blank',
      );
    });
  };

  render() {
    const { permissionsStatus } = this.state;
    return (
      <div>
        <Button fluid onClick={this.getDirection}>
          Direction
        </Button>
        {permissionsStatus === 'denied' ? (
          <Message color='red' size='mini'>
            Finder does not have permission to use your location.
          </Message>
        ) : null}
      </div>
    );
  }
}

export default Direction;

import React from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import Geofence from './Geofence/Geofence';
import Bill from './Bill/Bill';

class Syngenta extends React.Component {
  state = { activeItem: 'Bill' };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    return (
      <Segment>
        <Menu pointing secondary>
          <Menu.Item
            name='Bill'
            active={activeItem === 'Bill'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='Geofence'
            active={activeItem === 'Geofence'}
            onClick={this.handleItemClick}
          />
        </Menu>
        {activeItem === 'Geofence' && <Geofence />}
        {activeItem === 'Bill' && <Bill />}
      </Segment>
    );
  }
}

export default Syngenta;

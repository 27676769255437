import React, { Component } from 'react';

class FuelGauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuel: null,
    };
  }

  componentDidMount() {
    this.setState({ fuel: this.props.fuel });
  }

  componentDidUpdate(_, prevState) {
    if (this.props.fuel !== prevState.fuel && this.props.fuel) {
      this.setState({
        fuel: this.props.fuel,
      });
    }
  }

  render() {
    const fuel = this.state.fuel;
    const tankSize = Number(this.props.tankSize);

    return (
      <div>
        <div className='cylinder'>
          <div
            className='water'
            style={{ width: `${(100 * fuel) / tankSize}%` }}
          />
          <div className='fuel-info'>
            {typeof fuel !== 'object' ? (
              <span>
                {fuel} L / {tankSize} L
              </span>
            ) : (
              'Not Available'
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FuelGauge;

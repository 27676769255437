import React from 'react';
import { Form, Input, Button, Message, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import { REGULAR_PATH } from '../../../env';

const token = () => window.localStorage.getItem('user');

const POINT_TYPES = [
  {
    key: 'Dipot',
    value: 'Dipot',
    text: 'Dipot',
    name: 'Dipot',
    id: 'Dipot',
  },
  {
    key: 'Distribution',
    value: 'Distribution',
    text: 'Distribution',
    name: 'Distribution',
    id: 'Distribution',
  },
];

class Add extends React.Component {
  state = {
    name: '',
    type: '',
    submit: false,
    status: '',
    message: '',
  };

  saveData = () => {
    const { name, type } = this.state;

    this.setState({ submit: true, status: '' });

    const data = {
      point_name: name,
      point_type: type,
    };

    axios
      .post(
        `${REGULAR_PATH}/dpoints?access_token=${token()}`,
        JSON.stringify(data),
      )
      .then((response) => {
        const { status, message } = response.data;

        this.setState({ status: status, message: message, submit: false });

        if (status === 'success') {
          this.setState({
            name: '',
            type: '',
          });
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleInput = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  render() {
    const { name, type, submit, status, message } = this.state;

    return (
      <div style={{ maxWidth: '600px', margin: '10px 10%' }}>
        <Form>
          <Form.Field
            control={Input}
            label='Point Name'
            onChange={this.handleInput}
            value={name}
            name='name'
          />
        </Form>

        <br />

        <Form>
          <Form.Field
            control={Dropdown}
            fluid
            selection
            options={POINT_TYPES}
            label='Point Type'
            placeholder='Select Point Type'
            value={type}
            onChange={this.handleInput}
            name='type'
          />
        </Form>

        <Button
          primary
          style={{ marginTop: '15px' }}
          onClick={this.saveData}
          loading={submit}
          disabled={submit || !name || !type}
        >
          Submit
        </Button>

        {status === 'success' && (
          <Message positive>Saved successfully.</Message>
        )}

        {status && status !== 'success' && (
          <Message negative> {message}</Message>
        )}
      </div>
    );
  }
}

export default Add;

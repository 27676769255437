import React from 'react';
import { withRouter } from 'react-router-dom';
import { postData } from '../../helper/dataService.js';
import { REGULAR_PATH, AUTH_CONFIG } from '../../../env';
import { GoogleLogin } from 'react-google-login';

class LoginGoogle extends React.Component {
  static defaultProps = {
    postInfo: {
      client_id: AUTH_CONFIG.id,
      client_secret: AUTH_CONFIG.secret,
      grant_type: 'client_credentials',
      scope: '',
      timezone: new Date().getTimezoneOffset(),
    },
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      input: '',
      password: '',
      accessToken: '',
      refreshToken: '',
      registered: '',
      color: 'ui message info',
      loading: false,
    };
  }

  loginUser = (postInfo) => {
    let loginResponse = postData(
      `${REGULAR_PATH}/auth/login`,
      JSON.stringify(postInfo),
    );
    loginResponse
      .then(({ data }) => {
        const {
          email,
          _id,
          mobile,
          organization,
          staff,
          mobile_verified,
          name,
          super_user,
          allow_access,
          org_admin,
        } = data.user;

        const ploc =
          this.props.location && this.props.location.state
            ? this.props.location.state.pathname
            : '';

        window.localStorage.setItem('user', data.access_token);
        window.localStorage.setItem('refresh', data.refresh_token);
        window.localStorage.setItem('email', email);
        window.localStorage.setItem('user_id', _id);
        window.localStorage.setItem('org_admin', org_admin);
        window.localStorage.setItem('organization', organization);
        window.localStorage.setItem('real_org', organization);
        if (/(01)(3|5|6|7|8|9)\d{8}$/.test(mobile)) {
          window.localStorage.setItem('mobile', mobile);
        }

        window.localStorage.setItem('mobile_verified', mobile_verified);

        if (staff) {
          window.localStorage.setItem('staff', JSON.parse(staff));
        }
        window.localStorage.setItem('staffName', name);
        if (super_user) {
          window.localStorage.setItem('superUser', JSON.parse(super_user));
        }
        if (allow_access) {
          window.localStorage.setItem('allowAccess', JSON.parse(allow_access));
        }

        if (email === 'ranconautosltd@gmail.com') {
          window.localStorage.setItem('rancon', true);
        }

        if (ploc) {
          this.props.history.push(ploc);
        } else {
          this.props.history.push('/');
        }
      })
      .catch((e) => e);
  };

  responseGoogleFailed = (response) => {
    console.log('Login Failed', response);
  };

  responseGoogleSuccess = (response) => {
    this.props.postInfo['id_token'] = response.tokenId;
    this.props.postInfo['socialauth_type'] = 'google-plus';
    this.props.postInfo['login_type'] = 'socialauth';
    this.loginUser(this.props.postInfo);
  };

  render() {
    return (
      <GoogleLogin
        clientId='308668390355-l50jt9oqghcuqshm45p517khtk95dfih.apps.googleusercontent.com'
        theme='dark'
        onSuccess={this.responseGoogleSuccess}
        onFailure={this.responseGoogleFailed}
        buttonText={this.props.buttonText}
      />
    );
  }
}

export default withRouter(LoginGoogle);

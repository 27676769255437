import React from 'react';
import {
  Loader,
  Icon,
  Table,
  Button,
  Header,
  Label,
  Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import {
  reportView,
  reportGet,
  reportDownloadExcel,
  reportDownloadPdf,
} from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';

const durationLimit = reportsEnv.obd.durationLimit;
const assetLimit = reportsEnv.obd.assetLimit;

class OBDD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      selectedAssets: [],
      data: [],
      filteredEngineData: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      obdFeaturesList: [],
      obdFeaturesSelected: [],
    };
  }

  componentDidMount() {
    reportGet('v1/reports/obd_fields').then((response) => {
      this.setState({ obdFeaturesList: response.data });
    });

    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  handleFeatureSelect = (e, data) => {
    this.setState((prevState) => ({
      obdFeaturesSelected: prevState.obdFeaturesSelected.includes(data.value)
        ? prevState.obdFeaturesSelected.filter((item) => item !== data.value)
        : [...prevState.obdFeaturesSelected, data.value],
    }));
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportView(
      reportsEnv.obd.viewPath,
      {
        asset_ids: ids,
        from: startTime,
        to: endTime,
        obd_payload: this.state.obdFeaturesSelected,
      },
      reportsEnv.obd.newApi,
    )
      .then((response) => {
        this.setState({
          data: response.data,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadPdfBtn = () => {
    this.setState({ pdfDisableBtn: true });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadPdf(
      reportsEnv.obd.pdfPath,
      {
        asset_ids: ids,
        from: startTime,
        to: endTime,
        obd_payload: this.state.obdFeaturesSelected,
      },
      reportsEnv.obd.newApi,
    )
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_OBD_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.pdf`,
        );
        this.setState({ pdfDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ pdfDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(
      reportsEnv.obd.excelPath,
      {
        asset_ids: ids,
        from: startTime,
        to: endTime,
        obd_payload: this.state.obdFeaturesSelected,
      },
      reportsEnv.obd.newApi,
    )
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_OBD_Report_${moment().format('DD-MMMM-YYYY_hh:mm A')}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      obdFeaturesList,
      obdFeaturesSelected,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `OBD [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0 10px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            {obdFeaturesList.length > 0 && (
              <Header as='h4'>Select OBD Features First</Header>
            )}

            {obdFeaturesList.length > 0 && (
              <div style={{ margin: '5px 0 25px 0', width: '70%' }}>
                {obdFeaturesList.map((item, index) => (
                  <span
                    key={index}
                    style={{
                      display: 'inline-block',
                      marginLeft: '10px',
                      marginTop: '5px',
                      textTransform: 'capitalize',
                    }}
                  >
                    <Checkbox
                      label={item.split('_').join(' ')}
                      onClick={this.handleFeatureSelect}
                      checked={obdFeaturesSelected.includes(item)}
                      value={item}
                    />
                  </span>
                ))}
              </div>
            )}

            {obdFeaturesSelected.length > 0 &&
              isValidDuration &&
              isValidAsset && (
                <Button.Group>
                  <Button
                    color='black'
                    style={{ width: '200px', fontSize: '16px' }}
                    onClick={this.downloadReport}
                    icon='eye'
                    content='VIEW HERE'
                    disabled={pdfDisableBtn || excelDisableBtn}
                  />

                  <Button
                    color='teal'
                    style={{ width: '200px', fontSize: '16px' }}
                    icon='file pdf outline'
                    content='Download PDF'
                    onClick={this.handleDownloadPdfBtn}
                    disabled={pdfDisableBtn || excelDisableBtn}
                    loading={pdfDisableBtn}
                  />

                  <Button
                    color='teal'
                    style={{ width: '200px', fontSize: '16px' }}
                    icon='file excel outline'
                    content='Download Excel'
                    onClick={this.handleDownloadExcelBtn}
                    disabled={excelDisableBtn || pdfDisableBtn}
                    loading={excelDisableBtn}
                  />
                </Button.Group>
              )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>OBD</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />
            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'></div>
                    </div>
                  </div>
                </div>

                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      {obdFeaturesSelected.map((feature, index) => (
                        <Table.HeaderCell key={index}>
                          <span style={{ textTransform: 'capitalize' }}>
                            {feature.split('_').join(' ')}
                          </span>
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {item.obd_data.map((day, idx) =>
                      day.map((current_info) => (
                        <Table.Row key={current_info.time$date}>
                          <Table.Cell>
                            {current_info.time
                              ? moment(current_info.time.$date).format(
                                  'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                )
                              : ''}
                          </Table.Cell>
                          {obdFeaturesSelected.map((feature, index) => (
                            <Table.Cell key={index}>
                              {current_info.obd_data[feature]}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      )),
                    )}
                  </Table.Body>
                </Table>
                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            {' '}
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default OBDD;

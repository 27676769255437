import React from 'react';
import {
  Loader,
  Icon,
  Table,
  Button,
  Header,
  Label,
  Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import FileSaver from 'file-saver';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Line,
  Tooltip as TooltipChart,
  ComposedChart,
  Area,
} from 'recharts';

import SelectAssetInReport from '../SelectAssetInReport';
import { alertMessage } from '../../../helper/alert-message';
import { reportView, reportDownloadExcel } from '../helper/dataService';
import { getIdsAndTime, getDuration } from '../helper/helper';
import reportsEnv from '../reportsEnv';
import { getData, handleMultipleRequest } from '../../../helper/dataService';
import { digitalInputValueFormat } from '../../../helper/digital-input-value-format';
import { REGULAR_PATH } from '../../../../env';

const { durationLimit, assetLimit } = reportsEnv.temperatureA;

class CustomTooltip extends React.Component {
  render() {
    const { active, payload } = this.props;

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: 'white',
            padding: 10,
            border: '1px solid #D3D3D3',
          }}
        >
          <p>{payload[0].payload.time}</p>

          {payload.map((data) => {
            const hasDigitalInput =
              data.dataKey === 'digitalInput1' ||
              data.dataKey === 'digitalInput2' ||
              data.dataKey === 'digitalInput3';

            let isReversed = false;
            const inputName = data.dataKey;
            if (inputName === 'digitalInput1') {
              isReversed = data.payload.digitalInput1Reversed;
            } else if (inputName === 'digitalInput2') {
              isReversed = data.payload.digitalInput2Reversed;
            } else if (inputName === 'digitalInput3') {
              isReversed = data.payload.digitalInput3Reversed;
            }

            let hasValue;
            if (isReversed) {
              hasValue = data.value === 0;
            } else {
              hasValue = data.value === 50;
            }

            const label = digitalInputValueFormat(
              data.name,
              hasValue,
              isReversed,
            );

            return (
              <p style={{ color: data.stroke }} key={data.name}>
                <span>{data.name}</span>: {hasDigitalInput ? label : data.value}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  }
}

class Temperature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      pdfDisableBtn: false,
      excelDisableBtn: false,
      assetsList: [],
      selectedAssets: [],
      data: [],
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      graphList: [],
      temperature1: false,
      temperature2: false,
      temperature3: false,
      temperature4: false,
      temperature5: false,
      temperature6: false,
      temperature7: false,
      temperature8: false,
      digitalInput1: false,
      digitalInput2: false,
      digitalInput3: false,
      assetsWithTemperature: [],
      graphListWithStatus: [],
      digitalInput1Reversed: false,
      digitalInput2Reversed: false,
      digitalInput3Reversed: false,
      digitalInput1Name: '',
      digitalInput2Name: '',
      digitalInput3Name: '',
      enableDigitalInput1: false,
      enableDigitalInput2: false,
      enableDigitalInput3: false,
    };
  }

  componentDidMount() {
    const {
      selectedAssets = [],
      startTime,
      endTime,
    } = JSON.parse(window.localStorage.getItem('reports_state'));

    this.setState({
      selectedAssets,
      startTime: moment(startTime),
      endTime: moment(endTime),
    });
  }

  getBack = () => {
    this.setState({
      data: [],
    });
  };

  downloadReport = () => {
    this.setState({
      active: true,
      pdfDisableBtn: false,
      excelDisableBtn: false,
    });
    const { ids, startTime, endTime } = getIdsAndTime(this.state);

    let promises = [];

    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      let url = `${REGULAR_PATH}/assets/${id}/last_data?access_token=`;

      promises.push(getData(url));
    }

    let lastDataResponse = handleMultipleRequest(promises);

    lastDataResponse
      .then((results) => {
        results.forEach((res) => {
          if (
            res.data.temperature_sensor_2_name ||
            res.data.temperature_sensor_3_name ||
            res.data.temperature_sensor_4_name ||
            res.data.temperature_sensor_5_name ||
            res.data.temperature_sensor_6_name ||
            res.data.temperature_sensor_7_name ||
            res.data.temperature_sensor_8_name ||
            res.data.temperature_sensor_name ||
            res.data.digital_input_sensor_1_name ||
            res.data.digital_input_sensor_2_name ||
            res.data.digital_input_sensor_3_name
          ) {
            const asset = {
              name: res.data.name,
              temperature1Name: res.data.temperature_sensor_name || '',
              temperature2Name: res.data.temperature_sensor_2_name || '',
              temperature3Name: res.data.temperature_sensor_3_name || '',
              temperature4Name: res.data.temperature_sensor_4_name || '',
              temperature5Name: res.data.temperature_sensor_5_name || '',
              temperature6Name: res.data.temperature_sensor_6_name || '',
              temperature7Name: res.data.temperature_sensor_7_name || '',
              temperature8Name: res.data.temperature_sensor_8_name || '',
              digitalInput1Name: res.data.digital_input_sensor_1_name || '',
              digitalInput2Name: res.data.digital_input_sensor_2_name || '',
              digitalInput3Name: res.data.digital_input_sensor_3_name || '',
            };

            if (res.data.digital_input_sensor_1 === 'True') {
              this.setState({ digitalInput1: true });
            }
            if (res.data.digital_input_sensor_2 === 'True') {
              this.setState({ digitalInput2: true });
            }
            if (res.data.digital_input_sensor_3 === 'True') {
              this.setState({ digitalInput3: true });
            }

            this.setState((prevState) => ({
              assetsWithTemperature: [...prevState, asset],
            }));

            this.setState({
              digitalInput1Name: res.data.digital_input_sensor_1_name || '',
              digitalInput2Name: res.data.digital_input_sensor_2_name || '',
              digitalInput3Name: res.data.digital_input_sensor_3_name || '',
            });

            this.setState({
              digitalInput1Reversed:
                res.data.digital_input_sensor_1_reversed === 'True'
                  ? true
                  : false,
            });
            this.setState({
              digitalInput2Reversed:
                res.data.digital_input_sensor_2_reversed === 'True'
                  ? true
                  : false,
            });
            this.setState({
              digitalInput3Reversed:
                res.data.digital_input_sensor_3_reversed === 'True'
                  ? true
                  : false,
            });
          }
        });
      })
      .catch((error) => {});

    reportView(reportsEnv.temperatureA, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        const { data } = response;

        const temp = [...data];

        const assetListWithTemperature = [...this.state.assetsWithTemperature];

        assetListWithTemperature.forEach((asset) => {
          const index = temp.findIndex(
            (d) => d.asset_detail.name === asset.name,
          );
          if (index > -1) {
            temp[index].temperature1Name = asset.temperature1Name;
            temp[index].temperature2Name = asset.temperature2Name;
            temp[index].temperature3Name = asset.temperature3Name;
            temp[index].temperature4Name = asset.temperature4Name;
            temp[index].temperature5Name = asset.temperature5Name;
            temp[index].temperature6Name = asset.temperature6Name;
            temp[index].temperature7Name = asset.temperature7Name;
            temp[index].temperature8Name = asset.temperature8Name;
            temp[index].digitalInput1Name = asset.digitalInput1Name;
            temp[index].digitalInput2Name = asset.digitalInput2Name;
            temp[index].digitalInput3Name = asset.digitalInput3Name;
          }
        });

        const convertToGraphData = (hasValue, isReversed) => {
          if (hasValue) {
            return isReversed ? 0 : 50;
          } else {
            return isReversed ? 50 : 0;
          }
        };

        const graphList = data.map((asset) => {
          let filteredList = [];

          asset.data.forEach((item) => {
            const {
              temperature,
              temperature_2,
              temperature_3,
              temperature_4,
              temperature_5,
              temperature_6,
              temperature_7,
              temperature_8,
            } = item;

            let graphData = {};
            if (temperature) {
              graphData = {
                ...graphData,
                temperature1: temperature,
              };
              this.setState({ temperature1: true });
            }
            if (temperature_2) {
              graphData = {
                ...graphData,
                temperature2: temperature_2,
              };
              this.setState({ temperature2: true });
            }
            if (temperature_3) {
              graphData = {
                ...graphData,
                temperature3: temperature_3,
              };
              this.setState({ temperature3: true });
            }
            if (temperature_4) {
              graphData = {
                ...graphData,
                temperature4: temperature_4,
              };
              this.setState({ temperature4: true });
            }
            if (temperature_5) {
              graphData = {
                ...graphData,
                temperature5: temperature_5,
              };
              this.setState({ temperature5: true });
            }
            if (temperature_6) {
              graphData = {
                ...graphData,
                temperature6: temperature_6,
              };
              this.setState({ temperature6: true });
            }
            if (temperature_7) {
              graphData = {
                ...graphData,
                temperature7: temperature_7,
              };
              this.setState({ temperature7: true });
            }
            if (temperature_8) {
              graphData = {
                ...graphData,
                temperature8: temperature_8,
              };
              this.setState({ temperature8: true });
            }

            filteredList.push({
              time: moment(item.time.$date).format('DD/MM/YY, hh:mm:ss a'),
              digitalInput1: convertToGraphData(
                item.digital_input_1,
                this.state.digitalInput1Reversed,
              ),
              digitalInput2: convertToGraphData(
                item.digital_input_2,
                this.state.digitalInput2Reversed,
              ),
              digitalInput3: convertToGraphData(
                item.digital_input_3,
                this.state.digitalInput3Reversed,
              ),
              digitalInput1Reversed: this.state.digitalInput1Reversed,
              digitalInput2Reversed: this.state.digitalInput2Reversed,
              digitalInput3Reversed: this.state.digitalInput3Reversed,

              ...graphData,
            });
          });
          return filteredList;
        });

        this.setState({
          data: temp,
          graphList,
          active: false,
        });
      })
      .catch((error) => {
        this.setState({ active: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleDownloadExcelBtn = () => {
    this.setState({ excelDisableBtn: true });

    const { ids, startTime, endTime } = getIdsAndTime(this.state);
    reportDownloadExcel(reportsEnv.temperature, {
      asset_ids: ids,
      from: startTime,
      to: endTime,
    })
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `Finder_Temperature_Report_${moment().format(
            'DD-MMMM-YYYY_hh:mm A',
          )}.xlsx`,
        );
        this.setState({ excelDisableBtn: false });
      })
      .catch((error) => {
        this.setState({ excelDisableBtn: false });
        alertMessage(`Sorry! Something went wrong`);
      });
  };

  handleCheck = (_, data) => {
    const { checked, label } = data;
    if (label === this.state.digitalInput1Name) {
      this.setState({ enableDigitalInput1: checked });
    } else if (label === this.state.digitalInput2Name) {
      this.setState({ enableDigitalInput2: checked });
    } else {
      this.setState({ enableDigitalInput3: checked });
    }
  };

  render() {
    const {
      selectedAssets,
      data,
      active,
      pdfDisableBtn,
      excelDisableBtn,
      startTime,
      endTime,
      graphList,
      temperature1,
      temperature2,
      temperature3,
      temperature4,
      temperature5,
      temperature6,
      temperature7,
      temperature8,
      digitalInput1,
      digitalInput2,
      digitalInput3,
      digitalInput1Reversed,
      digitalInput2Reversed,
      digitalInput3Reversed,
      digitalInput1Name,
      digitalInput2Name,
      digitalInput3Name,
      enableDigitalInput1,
      enableDigitalInput2,
      enableDigitalInput3,
    } = this.state;

    const duration = getDuration(endTime, startTime);
    const assetsQuantity = selectedAssets.length;

    const isDurationLimitCross = duration > durationLimit;
    const isAssetLimitCross = assetsQuantity > assetLimit;

    if (isDurationLimitCross) {
      alertMessage(
        `You can't select this report more than ${durationLimit} days.`,
      );
    } else if (isAssetLimitCross) {
      alertMessage(
        `You can't select this report more than ${assetLimit} assets.`,
      );
    }

    const title = `Temperature [${durationLimit} days][${assetLimit} asset]`;
    const isValidDuration =
      duration > 0 && endTime.isSameOrAfter(startTime) && !isDurationLimitCross;
    const isValidAsset = assetsQuantity > 0 && !isAssetLimitCross;

    return (
      <div>
        {!active && data.length <= 0 && (
          <div className=' center-report'>
            <Header as='h2'>{title}</Header>

            <div style={{ margin: '20px 0' }}>
              <Label.Group size='large'>
                <Label>
                  <Icon name='car' />
                  {assetsQuantity} assets
                </Label>
                <Label>
                  <Icon name='calendar' />
                  {duration} days
                </Label>
                <Label>
                  <Icon name='play' />
                  {moment(startTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
                <Label>
                  <Icon name='stop' />
                  {moment(endTime).format('dddd, MMMM Do, YYYY, hh:mm:ss A')}
                </Label>
              </Label.Group>
            </div>

            {isValidDuration && isValidAsset && (
              <Button.Group>
                <Button
                  color='black'
                  style={{ width: '200px', fontSize: '16px' }}
                  onClick={this.downloadReport}
                  icon='eye'
                  content='VIEW HERE'
                  disabled={pdfDisableBtn || excelDisableBtn}
                />

                <Button
                  color='teal'
                  style={{ width: '200px', fontSize: '16px' }}
                  icon='file excel outline'
                  content='Download Excel'
                  onClick={this.handleDownloadExcelBtn}
                  disabled={excelDisableBtn || pdfDisableBtn}
                  loading={excelDisableBtn}
                />
              </Button.Group>
            )}
          </div>
        )}

        {!active && data.length > 0 ? (
          <div style={{ margin: '2%' }}>
            <div className='left-right'>
              <div>
                <Header as='h2'>Temperature</Header>
                <p>
                  Generated on: {moment().format('MMMM Do, YYYY, hh:mm:ss A')}
                </p>
              </div>
              <Button primary onClick={this.getBack}>
                Back
              </Button>
            </div>

            <br />

            {data.map((item, i) => (
              <div key={i} id={item.asset_detail.name}>
                <div className='ui stackable grid'>
                  <div className='eight wide column' style={{ paddingLeft: 0 }}>
                    <div className='ui card fluid'>
                      <div className='content'>
                        <div className='header'>{item.asset_detail.name}</div>

                        <div className='description'>
                          <div>
                            <p>
                              <i className='arrow right icon' /> From:
                              {moment(startTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                            <p>
                              <i className='arrow right icon' /> Till:{' '}
                              {moment(endTime).format(
                                'dddd, MMMM Do, YYYY, hh:mm:ss A',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='eight wide column'
                    style={{ paddingRight: 0 }}
                  >
                    <div className='ui card fluid'>
                      <div className='content'>
                        <h2 className='ui center aligned icon header'>
                          <i className='circular car icon' />
                        </h2>
                        {!item.data.some(
                          (data) =>
                            data.temperature ||
                            data.temperature2 ||
                            data.temperature3 ||
                            data.temperature4 ||
                            data.temperature5 ||
                            data.temperature6 ||
                            data.temperature7 ||
                            data.temperature8,
                        ) ? (
                          <h4
                            style={{
                              textAlign: 'center',
                              fontSize: 20,
                            }}
                          >
                            Temperature Sensor Not Connected
                          </h4>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {item.data.some(
                  (data) =>
                    data.temperature ||
                    data.temperature2 ||
                    data.temperature3 ||
                    data.temperature4 ||
                    data.temperature5 ||
                    data.temperature6 ||
                    data.temperature7 ||
                    data.temperature8,
                ) ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ display: 'flex', gap: 15 }}>
                        <Checkbox
                          checked={enableDigitalInput1}
                          label={digitalInput1Name}
                          onChange={this.handleCheck}
                        />
                        <Checkbox
                          checked={enableDigitalInput2}
                          label={digitalInput2Name}
                          onChange={this.handleCheck}
                        />
                        <Checkbox
                          checked={enableDigitalInput3}
                          label={digitalInput3Name}
                          onChange={this.handleCheck}
                        />
                      </div>
                    </div>

                    {graphList[i].length > 1 ? (
                      <ResponsiveContainer height={200} width='100%'>
                        <ComposedChart
                          data={graphList[i]}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis dataKey='time' />
                          <YAxis />
                          <TooltipChart content={<CustomTooltip />} />
                          <Legend />
                          {temperature1 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature1'
                              stroke='#ef5675'
                              name={
                                `${data[i].temperature1Name} (°C)` ||
                                'Temperature 1'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature2 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature2'
                              stroke='#FFA38F'
                              name={
                                `${data[i].temperature2Name} (°C)` ||
                                'Temperature 2'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature3 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature3'
                              stroke='#36C2CE'
                              name={
                                `${data[i].temperature3Name} (°C)` ||
                                'Temperature 3'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature4 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature4'
                              stroke='#4A249D'
                              name={
                                `${data[i].temperature4Name} (°C)` ||
                                'Temperature 4'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature5 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature5'
                              stroke='#4A249D'
                              name={
                                `${data[i].temperature5Name} (°C)` ||
                                'Temperature 5'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature6 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature6'
                              stroke='#4A249D'
                              name={
                                `${data[i].temperature6Name} (°C)` ||
                                'Temperature 6'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature7 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature7'
                              stroke='#4A249D'
                              name={
                                `${data[i].temperature7Name} (°C)` ||
                                'Temperature 7'
                              }
                              dot={false}
                            />
                          ) : null}
                          {temperature8 ? (
                            <Line
                              type='monotone'
                              dataKey='temperature8'
                              stroke='#4A249D'
                              name={
                                `${data[i].temperature8Name} (°C)` ||
                                'Temperature 8'
                              }
                              dot={false}
                            />
                          ) : null}
                          {enableDigitalInput1 ? (
                            <Area
                              type='monotone'
                              dataKey='digitalInput1'
                              fill='#e5e5f6'
                              stroke='#8884d8'
                              name={
                                `${data[i].digitalInput1Name}` ||
                                'Digital Indicator 1'
                              }
                            />
                          ) : null}

                          {enableDigitalInput2 ? (
                            <Area
                              type='monotone'
                              dataKey='digitalInput2'
                              fill='#e2ff4b'
                              stroke='#8884d8'
                              name={
                                `${data[i].digitalInput2Name}` ||
                                'Digital Indicator 2'
                              }
                            />
                          ) : null}

                          {enableDigitalInput3 ? (
                            <Area
                              type='monotone'
                              dataKey='digitalInput3'
                              fill='#f4c4cc'
                              stroke='#8884d8'
                              name={
                                `${data[i].digitalInput3Name}` ||
                                'Digital Indicator 3'
                              }
                            />
                          ) : null}
                        </ComposedChart>
                      </ResponsiveContainer>
                    ) : null}
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Time</Table.HeaderCell>
                          {temperature1 ? (
                            <Table.HeaderCell>
                              {`${item.temperature1Name} (°C)` ||
                                'Temperature 1(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature2 ? (
                            <Table.HeaderCell>
                              {`${item.temperature2Name} (°C)` ||
                                'Temperature 2(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature3 ? (
                            <Table.HeaderCell>
                              {`${item.temperature3Name} (°C)` ||
                                'Temperature 3(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature4 ? (
                            <Table.HeaderCell>
                              {`${item.temperature4Name} (°C)` ||
                                'Temperature 4(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature5 ? (
                            <Table.HeaderCell>
                              {`${item.temperature5Name} (°C)` ||
                                'Temperature 5(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature6 ? (
                            <Table.HeaderCell>
                              {`${item.temperature6Name} (°C)` ||
                                'Temperature 6(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature7 ? (
                            <Table.HeaderCell>
                              {`${item.temperature7Name} (°C)` ||
                                'Temperature 7(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {temperature8 ? (
                            <Table.HeaderCell>
                              {`${item.temperature8Name} (°C)` ||
                                'Temperature 8(°C)'}
                            </Table.HeaderCell>
                          ) : null}
                          {digitalInput1 ? (
                            <Table.HeaderCell>
                              {item.digitalInput1Name || 'Digital Indicator 1'}
                            </Table.HeaderCell>
                          ) : null}
                          {digitalInput2 ? (
                            <Table.HeaderCell>
                              {item.digitalInput2Name || 'Digital Indicator 2'}
                            </Table.HeaderCell>
                          ) : null}
                          {digitalInput3 ? (
                            <Table.HeaderCell>
                              {item.digitalInput3Name || 'Digital Indicator 3'}
                            </Table.HeaderCell>
                          ) : null}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {item.data.map((item, id) => {
                          if (
                            item.temperature ||
                            item.temperature2 ||
                            item.temperature3 ||
                            item.temperature4 ||
                            item.temperature5 ||
                            item.temperature6 ||
                            item.temperature7 ||
                            item.temperature4
                          ) {
                            return (
                              <Table.Row key={id}>
                                <Table.Cell>
                                  {item.time
                                    ? moment(item.time.$date).format(
                                        'dddd, MMMM Do, YYYY, hh:mm:ss A',
                                      )
                                    : ''}
                                </Table.Cell>
                                {temperature1 ? (
                                  <Table.Cell>
                                    {item.temperature || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature2 ? (
                                  <Table.Cell>
                                    {item.temperature_2 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature3 ? (
                                  <Table.Cell>
                                    {item.temperature_3 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature4 ? (
                                  <Table.Cell>
                                    {item.temperature_4 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature5 ? (
                                  <Table.Cell>
                                    {item.temperature_5 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature6 ? (
                                  <Table.Cell>
                                    {item.temperature_6 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature7 ? (
                                  <Table.Cell>
                                    {item.temperature_7 || '-'}
                                  </Table.Cell>
                                ) : null}
                                {temperature8 ? (
                                  <Table.Cell>
                                    {item.temperature_8 || '-'}
                                  </Table.Cell>
                                ) : null}

                                {digitalInput1 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      digitalInput1Name,
                                      item.digital_input_1,
                                      digitalInput1Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                                {digitalInput2 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      digitalInput2Name,
                                      item.digital_input_2,
                                      digitalInput2Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                                {digitalInput3 ? (
                                  <Table.Cell>
                                    {digitalInputValueFormat(
                                      digitalInput3Name,
                                      item.digital_input_3,
                                      digitalInput3Reversed,
                                    )}
                                  </Table.Cell>
                                ) : null}
                              </Table.Row>
                            );
                          }

                          return null;
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                ) : null}

                <br />
              </div>
            ))}
            {data.length > 1 ? <SelectAssetInReport assetList={data} /> : null}
          </div>
        ) : (
          <Loader active={active} inline='centered'>
            Generating...
          </Loader>
        )}
      </div>
    );
  }
}

export default Temperature;

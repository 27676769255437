import React, { Component } from 'react';
import { Button, Form, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Message from '../Message';
import { postData } from '../dataHelper';

class Add extends Component {
  state = {
    plateNumberList: this.props.plateNumberList,
    registrationNumber: '',
    purchaseDate: null,
    chassisNumber: '',
    engineNumber: '',
    cc: '',
    capacity: '',
    model: '',
    brand: '',
    colour: '',
    fuelType: '',
    taxTokenValidity: null,
    fitnessValidity: null,
    routePermitValidity: null,
    insuranceValidity: null,
    userName: '',
    insuranceCertificateNumber: '',
    registrationOn: '',
    cngCylinderRetestDate: null,
    fireExtinguisherRefillDate: null,
    noOfFireExtinguisher: '',
    driverLicenseExpireDate: null,
    driverName: '',
    driverContactNumber: '',
    remarks: '',
    messageShow: false,
    messageType: '',
    messageInfo: '',
    submitted: false,
  };

  handlePurchaseDate = (value) => {
    this.setState({ purchaseDate: value });
  };

  handleFitnessValidity = (value) => {
    this.setState({ fitnessValidity: value });
  };
  handleTaxTokenValidity = (value) => {
    this.setState({ taxTokenValidity: value });
  };
  handleRoutePermitValidity = (value) => {
    this.setState({ routePermitValidity: value });
  };
  handleInsuranceValidity = (value) => {
    this.setState({ insuranceValidity: value });
  };
  handleCngCylinderRetestDate = (value) => {
    this.setState({ cngCylinderRetestDate: value });
  };
  handleFireExtinguisherRefillDate = (value) => {
    this.setState({ fireExtinguisherRefillDate: value });
  };
  handleDriverLicenseExpireDate = (value) => {
    this.setState({ driverLicenseExpireDate: value });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleRightTime = (e, data) => {
    this.setState({ rightTime: data.checked });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ messageShow: false, submitted: true });

    const {
      registrationNumber,
      purchaseDate,
      chassisNumber,
      engineNumber,
      cc,
      capacity,
      model,
      brand,
      colour,
      fuelType,
      taxTokenValidity,
      fitnessValidity,
      routePermitValidity,
      insuranceValidity,
      userName,
      insuranceCertificateNumber,
      registrationOn,
      cngCylinderRetestDate,
      fireExtinguisherRefillDate,
      noOfFireExtinguisher,
      driverLicenseExpireDate,
      driverName,
      driverContactNumber,
      remarks,
    } = this.state;

    const data = {
      asset_id: registrationNumber,
      purchase_date: purchaseDate ? moment(purchaseDate).unix() : '',
      chassis_number: chassisNumber,
      engine_number: engineNumber,
      total_cc: cc,
      capacity,
      model_year: model,
      brand,
      color: colour,
      fuel_type: fuelType,
      tax_token_validity: taxTokenValidity
        ? moment(taxTokenValidity).unix()
        : '',
      fitness_validity: fitnessValidity ? moment(fitnessValidity).unix() : '',
      route_permit_validity: routePermitValidity
        ? moment(routePermitValidity).unix()
        : '',
      insurance_validity: insuranceValidity
        ? moment(insuranceValidity).unix()
        : '',
      user_name: userName,
      insurance_certificate_no: insuranceCertificateNumber,
      registration_on: registrationOn,
      cng_retest: cngCylinderRetestDate
        ? moment(cngCylinderRetestDate).unix()
        : '',
      fire_refil: fireExtinguisherRefillDate
        ? moment(fireExtinguisherRefillDate).unix()
        : '',
      no_fire_ext: noOfFireExtinguisher,
      driver_license_expiry_date: driverLicenseExpireDate
        ? moment(driverLicenseExpireDate).unix()
        : '',
      driver_name: driverName,
      driver_contact_number: driverContactNumber,
      remarks,
    };

    postData(`${this.props.org}_delivery`, data)
      .then((response) => {
        const { message, status } = response.data;
        if (status === 'success') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'positive',
            submitted: false,
            registrationNumber: '',
            purchaseDate: null,
            chassisNumber: '',
            engineNumber: '',
            cc: '',
            capacity: '',
            model: '',
            brand: '',
            colour: '',
            fuelType: '',
            taxTokenValidity: null,
            fitnessValidity: null,
            routePermitValidity: null,
            insuranceValidity: null,
            userName: '',
            insuranceCertificateNumber: '',
            registrationOn: '',
            cngCylinderRetestDate: null,
            fireExtinguisherRefillDate: null,
            noOfFireExtinguisher: '',
            driverLicenseExpireDate: null,
            driverName: '',
            driverContactNumber: '',
            remarks: '',
          });
          setTimeout(() => {
            this.setState({ messageShow: false });
          }, 3000);
        } else if (status === 'error') {
          this.setState({
            messageShow: true,
            messageInfo: message,
            messageType: 'warning',
            submitted: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitted: false });
      });
  };

  handleAssetSelect = (e, data) => {
    this.setState({ registrationNumber: data.value });
  };

  render() {
    const {
      plateNumberList,
      messageShow,
      messageType,
      messageInfo,
      registrationNumber,
      purchaseDate,
      chassisNumber,
      engineNumber,
      cc,
      capacity,
      model,
      brand,
      colour,
      fuelType,
      taxTokenValidity,
      fitnessValidity,
      routePermitValidity,
      insuranceValidity,
      userName,
      insuranceCertificateNumber,
      registrationOn,
      cngCylinderRetestDate,
      fireExtinguisherRefillDate,
      noOfFireExtinguisher,
      driverLicenseExpireDate,
      driverName,
      driverContactNumber,
      remarks,
      submitted,
    } = this.state;

    return (
      <div style={{ margin: '10px auto', width: '70%' }}>
        <Form>
          <Form.Field>
            <label>Registration No.</label>
            <Dropdown
              search
              selection
              options={plateNumberList}
              onChange={this.handleAssetSelect}
              value={registrationNumber}
            />
          </Form.Field>

          <Form.Field>
            <label>Purchase Date</label>
            <DatePicker
              selected={purchaseDate}
              onChange={this.handlePurchaseDate}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label name='chassisNumber'>Chassis Number</label>
            <input
              value={chassisNumber}
              onChange={this.handleChange}
              name='chassisNumber'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='engineNumber'>Engine Number</label>
            <input
              value={engineNumber}
              onChange={this.handleChange}
              name='engineNumber'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='cc'>CC</label>
            <input
              value={cc}
              onChange={this.handleChange}
              name='cc'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='capacity'>Capacity</label>
            <input
              value={capacity}
              onChange={this.handleChange}
              name='capacity'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='model'>Model</label>
            <input
              value={model}
              onChange={this.handleChange}
              name='model'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='brand'>Brand</label>
            <input
              value={brand}
              onChange={this.handleChange}
              name='brand'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='colour'>Colour</label>
            <input
              value={colour}
              onChange={this.handleChange}
              name='colour'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='fuelType'>Fuel Type</label>
            <input
              value={fuelType}
              onChange={this.handleChange}
              name='fuelType'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label>Tax Token Validity</label>
            <DatePicker
              selected={taxTokenValidity}
              onChange={this.handleTaxTokenValidity}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label>Fitness Validity</label>
            <DatePicker
              selected={fitnessValidity}
              onChange={this.handleFitnessValidity}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label>Route Permit Validity</label>
            <DatePicker
              selected={routePermitValidity}
              onChange={this.handleRoutePermitValidity}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label>Insurance Validity</label>
            <DatePicker
              selected={insuranceValidity}
              onChange={this.handleInsuranceValidity}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label name='userName'>User Name</label>
            <input
              value={userName}
              onChange={this.handleChange}
              name='userName'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='insuranceCertificateNumber'>
              Insurance Certificate Number
            </label>
            <input
              value={insuranceCertificateNumber}
              onChange={this.handleChange}
              name='insuranceCertificateNumber'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='registrationOn'>Registration On</label>
            <input
              value={registrationOn}
              onChange={this.handleChange}
              name='registrationOn'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label>CNG Cylinder Retest Date</label>
            <DatePicker
              selected={cngCylinderRetestDate}
              onChange={this.handleCngCylinderRetestDate}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label>Fire Extinguisher Refill Date</label>
            <DatePicker
              selected={fireExtinguisherRefillDate}
              onChange={this.handleFireExtinguisherRefillDate}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label name='noOfFireExtinguisher'>No. Of Fire Extinguisher</label>

            <input
              value={noOfFireExtinguisher}
              onChange={this.handleChange}
              name='noOfFireExtinguisher'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label>Driver License Expire Date</label>

            <DatePicker
              selected={driverLicenseExpireDate}
              onChange={this.handleDriverLicenseExpireDate}
              dateFormat='LL'
            />
          </Form.Field>

          <Form.Field>
            <label name='driverName'>Driver Name</label>
            <input
              value={driverName}
              onChange={this.handleChange}
              name='driverName'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='driverContactNumber'>Driver Contact Number</label>
            <input
              value={driverContactNumber}
              onChange={this.handleChange}
              name='driverContactNumber'
              type='text'
            />
          </Form.Field>

          <Form.Field>
            <label name='remarks'>Remarks</label>
            <textarea
              value={remarks}
              onChange={this.handleChange}
              name='remarks'
              type='text'
            />
          </Form.Field>
        </Form>
        <br />
        {messageShow && <Message type={messageType} info={messageInfo} />}

        <Button
          primary
          disabled={submitted}
          loading={submitted}
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default Add;

import React from 'react';
import { Button, Loader } from 'semantic-ui-react';
import { getData } from '../../../../vms/dataHelper';
import LiveStream from './live-stream';
import PreviousStream from './previous-stream';
import VideoPlayer from './video-player';
import moment from 'moment';

class JC400Stream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      url: '',
      message: '',
      isPrevious: false,
      isLive: true,
      availableTimes: [],
      date: moment(),
      time: '',
      channel: 0,
    };
  }

  fetchStreamUrl = (channel, date, time) => {
    this.setState({ fetching: true, url: '', message: '' });

    let query = `channel=${channel}`;

    if (date && time) {
      query += `&from=${date.format('YYYY-MM-DD')} ${time}`;
    }

    getData(`assets/${this.props.assetId}/video_stream`, query)
      .then(({ data }) => {
        const status = data.status;

        if (status === 'success') {
          const { stream_url } = data.data;
          this.setState({ url: stream_url, message: '' });
        } else if (status === 'error') {
          const message = data.error;
          this.setState({ message, url: '' });
        }

        this.setState({ fetching: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
  };

  fetchAvailableTimes = (channel, date) => {
    this.setState({ fetching: true, url: '', message: '' });

    const query = `channel=${channel}&date=${date.format('YYYY-MM-DD')}`;

    getData(`assets/${this.props.assetId}/stream_info`, query)
      .then(({ data }) => {
        const status = data.status;

        if (status === 'success') {
          const { time_list } = data;
          this.setState({
            availableTimes: time_list.sort((a, b) => a.localeCompare(b)),
            time: time_list[0],
            message: '',
          });
        } else if (status === 'error') {
          const message = data.error;
          this.setState({ message, availableTimes: [] });
        }

        this.setState({ fetching: false });
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
  };

  handlePrevious = () => {
    this.setState({
      isPrevious: true,
      isLive: false,
      url: '',
      message: '',
      channel: 1,
      date: moment(),
      time: '',
      availableTimes: [],
    });
  };

  handleLive = () => {
    this.setState({
      isLive: true,
      isPrevious: false,
      url: '',
      message: '',
      channel: 0,
      availableTimes: [],
      date: moment(),
      time: '',
    });
  };

  handleDate = (date) => this.setState({ date });

  handleTime = (time) => this.setState({ time });

  handleChannel = (_, { value }) =>
    this.setState({ channel: value, availableTimes: [], time: '' });

  handleTimeControl = (action, currentIndex, currentTime) => {
    const { availableTimes, channel, date } = this.state;

    if (currentIndex >= 0) {
      let time = currentTime;

      if (action === 'forward') {
        time = availableTimes[currentIndex + 1];
      } else if (action === 'backward') {
        time = availableTimes[currentIndex - 1];
      }

      this.setState({ time });

      this.fetchStreamUrl(channel, date, time);
    }
  };

  render() {
    const {
      fetching,
      url,
      message,
      isPrevious,
      isLive,
      availableTimes,
      date,
      time,
      channel,
    } = this.state;

    const currentTimeIndex = availableTimes.findIndex((item) => item === time);

    return (
      <div>
        <Button.Group>
          <Button
            fluid
            size='tiny'
            disabled={fetching}
            active={isLive}
            onClick={this.handleLive}
          >
            Live
          </Button>
          <Button
            fluid
            size='tiny'
            disabled={fetching}
            active={isPrevious}
            onClick={this.handlePrevious}
          >
            Previous
          </Button>
        </Button.Group>

        <div>
          {url ? (
            <div>
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {isPrevious ? (
                  <span>Channel: {channel === 1 ? '1' : '2'}</span>
                ) : null}
                {isLive ? (
                  <span>Channel: {channel === 0 ? '1' : '2'}</span>
                ) : null}
              </div>
              <VideoPlayer url={url} />
              {isPrevious ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    icon='backward'
                    disabled={currentTimeIndex === 0}
                    onClick={() =>
                      this.handleTimeControl('backward', currentTimeIndex, time)
                    }
                  />
                  <Button
                    color='red'
                    onClick={() => this.setState({ url: '' })}
                  >
                    Close
                  </Button>
                  <Button
                    icon='forward'
                    disabled={currentTimeIndex === availableTimes.length - 1}
                    onClick={() =>
                      this.handleTimeControl('forward', currentTimeIndex, time)
                    }
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color='red'
                    onClick={() => this.setState({ url: '' })}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                margin: '10px 0',
              }}
            >
              {fetching ? <Loader active inline='centered' /> : null}

              {isLive && !fetching ? (
                <LiveStream
                  channel={channel}
                  handleChannel={this.handleChannel}
                  fetchStreamUrl={this.fetchStreamUrl}
                />
              ) : null}

              {isPrevious && !fetching ? (
                <PreviousStream
                  availableTimes={availableTimes}
                  channel={channel}
                  date={date}
                  time={time}
                  handleChannel={this.handleChannel}
                  handleDate={this.handleDate}
                  handleTime={this.handleTime}
                  fetchStreamUrl={this.fetchStreamUrl}
                  fetchAvailableTimes={this.fetchAvailableTimes}
                />
              ) : null}
            </div>
          )}

          {message ? (
            <p
              style={{
                margin: '10px 0',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              {message}
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

export default JC400Stream;

import { observable, action } from 'mobx';
import moment from 'moment';

class Timer {
  @observable
  start = moment().startOf('day');
  @observable
  end = moment();

  @action
  handleStartTime = (start) => {
    this.start = start;
  };
  @action
  handleEndTime = (end) => {
    this.end = end;
  };
}

export default new Timer();

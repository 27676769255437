import React from 'react';
import moment from 'moment';
import { getData } from '../../vms/dataHelper';

class IOElements extends React.Component {
  state = {
    fuel_consumption: '',
    fuel_consumption_reset_time: '',
    current_daily_fuel_consumption: '',
  };
  componentDidMount() {
    this.fetchIO();
    this.fetchIOInterval = setInterval(() => {
      this.fetchIO();
    }, 10000);
  }

  fetchIO = () => {
    getData(`assets/${this.props.assetId}/last_data/io`)
      .then(({ data: { status, data } }) => {
        if (status === 'success') {
          const {
            fuel_consumption,
            fuel_consumption_reset_time,
            current_daily_fuel_consumption,
          } = data;
          this.setState({
            fuel_consumption,
            fuel_consumption_reset_time,
            current_daily_fuel_consumption,
          });
        }
      })
      .catch((error) => console.error('io_elements', error));
  };

  componentWillMount() {
    clearInterval(this.fetchIOInterval);
  }
  render() {
    const {
      fuel_consumption,
      fuel_consumption_reset_time,
      current_daily_fuel_consumption,
    } = this.state;
    return (
      <div>
        <div style={{ marginTop: '5px' }}>
          <h4 style={{ color: '#32325d', marginBottom: 0 }}>
            Total Fuel Consumption
          </h4>
          {fuel_consumption_reset_time && (
            <h4 style={style.FontColor}>
              <h5 style={style.FontColor}>{Math.round(fuel_consumption)} L</h5>
              [Since{' '}
              {moment(Number(fuel_consumption_reset_time)).format(
                'D-MMM-YY, hh:mm A',
              )}
              ]
            </h4>
          )}
        </div>

        {current_daily_fuel_consumption && (
          <div>
            <h4 style={{ color: '#32325d', marginBottom: 0 }}>
              Today's Fuel Consumption
            </h4>
            <h5 style={style.FontColor}>
              {Math.round(current_daily_fuel_consumption)} L
            </h5>
          </div>
        )}
      </div>
    );
  }
}

const style = {
  FontColor: {
    color: '#988e8e',
  },
};

export default IOElements;

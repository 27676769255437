import React, { Component } from 'react';
import { Cell, PieChart, Pie } from 'recharts';

class TemperatureGauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 280,
      height: 210,
      colorData: [
        {
          value: 25, // range -50 to -25
          color: '#009FBD',
        },
        {
          value: 25, // range -24 to 0
          color: '#36BA98',
        },
        {
          value: 25, // range 1 to 25
          color: '#F4CE14',
        },
        {
          value: 25, // range 26 to 50
          color: '#F16052',
        },
      ],
    };
  }

  getSumValues() {
    return this.state.colorData.map((cur) => cur.value).reduce((a, b) => a + b);
  }

  Arrow = ({ cx, cy, midAngle, innerRadius }) => {
    const { width } = this.state;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (innerRadius + width * 0.03) * cos;
    const my = cy + (innerRadius + width * 0.03) * sin;
    const ex = mx - 0.1;
    const ey = my;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dx={-90}
          dy={50}
          textAnchor='start'
          fill={'#9299b8'}
          fontSize='1em'
          fontFamily='Arial, Helvetica, sans-serif'
          fontWeight='normal'
        >
          Low
        </text>
        <text
          x={cx}
          y={cy}
          dx={65}
          dy={50}
          textAnchor='start'
          fill={'#9299b8'}
          fontSize='1em'
          fontFamily='Arial, Helvetica, sans-serif'
          fontWeight='normal'
        >
          High
        </text>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor='middle'
          fill={'#5B5B5B'}
          fontSize='1.8em'
          fontFamily='Arial, Helvetica, sans-serif'
          fontWeight='bold'
        >
          {this.props.value}°C
        </text>
        <text
          x={cx}
          y={cy}
          dy={-102}
          textAnchor='middle'
          fill={'#5B5B5B'}
          fontSize='0.85em'
          fontFamily='Arial, Helvetica, sans-serif'
          fontWeight='bold'
        >
          {this.props.name}
        </text>
        <g transform={`translate(${ex + (cos >= 0 ? 1 : -1) * 2} ${ey})`}>
          <g transform={`rotate(${-midAngle + 180})`}>
            <polygon
              points='20,-10 20,10 -10,0'
              style={{ fill: '#5B5B5B', strokeWidth: '1' }}
            />
          </g>
        </g>
      </g>
    );
  };

  render() {
    const { width, height, colorData } = this.state;
    const sumValues = this.getSumValues();

    const normalizedValue = ((Number(this.props.value) + 50) / 100) * sumValues;

    const arrowData = [
      { value: normalizedValue },
      { value: 1 },
      { value: sumValues - normalizedValue },
    ];

    const pieProps = {
      startAngle: 200,
      endAngle: -20,
      cx: width / 2,
      cy: width / 2 - 20,
    };

    const pieRadius = {
      outerRadius: '89%',
      innerRadius: '65%',
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PieChart width={width} height={height}>
          <Pie
            data={colorData}
            fill='#eee'
            {...pieRadius}
            {...pieProps}
            dataKey='value'
          >
            {colorData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Pie
            stroke='none'
            activeIndex={1}
            activeShape={this.Arrow}
            data={arrowData}
            outerRadius={pieRadius.outerRadius}
            innerRadius={pieRadius.innerRadius}
            fill='none'
            {...pieProps}
            dataKey='value'
          />
        </PieChart>
      </div>
    );
  }
}

export default TemperatureGauge;

import { observable } from 'mobx';
import { getData } from './../helper/dataService.js';
import { REGULAR_PATH } from '../../env';

class Assets {
  @observable
  all = getData(`${REGULAR_PATH}/assets/list?access_token=`);
}

export default new Assets();

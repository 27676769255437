import { observable, action } from 'mobx';

class User {
  @observable
  accessToken;
  @observable
  refreshToken;
  @observable
  isLoggedIn;

  constructor() {
    this.accessToken = '';
    this.refreshToken = '';
    this.isLoggedIn = false;
  }

  @action
  changeToken = (accessToken, refreshToken, login) => {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.isLoggedIn = login;
  };
}

export default new User();

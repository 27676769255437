import React from 'react';
import { Button, Loader, Table, Search } from 'semantic-ui-react';
import axios from 'axios';
import { BASE_PATH } from '../../../../env';
import moment from 'moment';
import { getActiveAssetList } from '../../reports/helper/helper';

const token = () => window.localStorage.getItem('user');

class List extends React.Component {
  state = {
    loading: true,
    data: [],
    search: '',
    submit: false,
    addToBillId: '',
    addToBillTime: moment(),
    message: '',
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    this.setState({ loading: true });
    const assetIds = getActiveAssetList().map((item) => item.key);
    axios
      .post(
        `${BASE_PATH}/v1.2/reports/get_runners_asset?access_token=${token()}`,
        JSON.stringify({
          asset_ids: assetIds,
        }),
      )
      .then((response) => {
        this.setState({
          loading: false,
          data: response.data.filter((item) => item),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  handleSearch = (event, data) => {
    this.setState({ search: data.value.toLowerCase() });
  };

  render() {
    const { loading, data, search } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div>
        <Search
          onSearchChange={this.handleSearch}
          value={search}
          placeholder='Search Here'
          showNoResults={false}
          style={{ width: '300px' }}
        />

        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>Asset ID</Table.HeaderCell>
              <Table.HeaderCell>Asset Name</Table.HeaderCell>
              <Table.HeaderCell>CIN</Table.HeaderCell>
              <Table.HeaderCell>Customer Name</Table.HeaderCell>
              <Table.HeaderCell>Mobile Number</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell>Model</Table.HeaderCell>
              <Table.HeaderCell>Vehicle Delivery Date</Table.HeaderCell>
              <Table.HeaderCell>Region/Place</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data
              .filter(({ asset_name }) =>
                asset_name.toLowerCase().includes(search),
              )
              .map(
                (
                  {
                    _id,
                    asset_oid,
                    asset_id,
                    asset_name,
                    delivery_date,
                    cin,
                    customer_name,
                    unit,
                    mobile_no,
                    region,
                    model_number,
                  },
                  index,
                ) => (
                  <Table.Row key={_id.$oid}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{asset_id}</Table.Cell>
                    <Table.Cell>{asset_name}</Table.Cell>
                    <Table.Cell>{cin}</Table.Cell>
                    <Table.Cell>{customer_name}</Table.Cell>
                    <Table.Cell>{mobile_no}</Table.Cell>
                    <Table.Cell>{unit}</Table.Cell>
                    <Table.Cell>{model_number}</Table.Cell>
                    <Table.Cell>
                      {moment(delivery_date).isValid()
                        ? moment(delivery_date).format('dddd, MMMM Do, YYYY')
                        : null}
                    </Table.Cell>
                    <Table.Cell>{region}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() =>
                          this.props.selectAssetIdForEdit(asset_oid.$oid)
                        }
                      >
                        Edit
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default List;

import React from 'react';
import {
  Button,
  Loader,
  Table,
  Search,
  Popup,
  Modal,
  Message,
} from 'semantic-ui-react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { REGULAR_PATH } from '../../../../env';
import moment from 'moment';

const token = () => window.localStorage.getItem('user');
const org = () => window.localStorage.getItem('organization');

class List extends React.Component {
  state = {
    loading: true,
    data: [],
    search: '',
    submit: false,
    addToBillId: '',
    addToBillTime: moment(),
    message: '',
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${REGULAR_PATH}/syngenta_bill_rate?org_oid=${org()}&access_token=${token()}`,
      )
      .then((response) => {
        this.setState({ loading: false, data: response.data });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  handleSearch = (event, data) => {
    this.setState({ search: data.value.toLowerCase() });
  };

  handleDelete = (id) => {
    this.setState({ submit: true });
    axios
      .delete(
        `${REGULAR_PATH}/syngenta_bill_rate?bill_oid=${id}&access_token=${token()}`,
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({ status, submit: false });
        if (status === 'Bill deleted') {
          this.fetchList();
        }
      })
      .catch(() => {
        this.setState({ submit: false });
      });
  };

  handleAddToBillId = (id) => {
    this.setState({
      addToBillId: id,
    });
  };

  handleAddToBillTime = (value) => {
    this.setState({
      addToBillTime: value,
    });
  };

  handleAddToBill = () => {
    const { addToBillId, addToBillTime } = this.state;
    this.setState({ submit: true });
    axios
      .post(
        `${REGULAR_PATH}/syngenta_bills?access_token=${token()}`,
        JSON.stringify({
          bill_rate_oid: addToBillId,
          bill_date: moment(addToBillTime).unix(),
        }),
      )
      .then((response) => {
        const status = response.data.status;
        this.setState({
          message: status,
          submit: false,
          addToBillId: false,
        });
        setTimeout(() => {
          this.setState({ message: '' });
        }, 5000);
      })
      .catch(() => {
        this.setState({ submit: false, addToBillId: false });
      });
  };

  render() {
    const {
      loading,
      data,
      search,
      submit,
      addToBillId,
      addToBillTime,
      message,
    } = this.state;

    if (loading) {
      return (
        <Loader active inline='centered'>
          Loading...
        </Loader>
      );
    }

    return (
      <div>
        <Search
          onSearchChange={this.handleSearch}
          value={search}
          placeholder='Search Here'
          showNoResults={false}
          style={{ width: '300px' }}
        />
        {message && message === 'Bill created' && (
          <Message positive>Bill created successfully.</Message>
        )}

        {message && message !== 'Bill created' && (
          <Message negative> {message || 'Sorry Something Went Wrong'}</Message>
        )}
        <Table celled textAlign='center'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Geofence</Table.HeaderCell>
              <Table.HeaderCell>Asset</Table.HeaderCell>
              <Table.HeaderCell>Area [Decimal]</Table.HeaderCell>
              <Table.HeaderCell>Amount [tk]</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data
              .filter(
                ({ geofence_name, asset_name }) =>
                  geofence_name.toLowerCase().includes(search) ||
                  asset_name.toLowerCase().includes(search),
              )
              .sort((a, b) => {
                if (Number(a.created_on.$date) < Number(b.created_on.$date))
                  return 1;

                if (Number(a.created_on.$date) > Number(b.created_on.$date))
                  return -1;

                return 0;
              })
              .map(({ _id, geofence_name, asset_name, area, amount }) => (
                <Table.Row key={_id.$oid}>
                  <Table.Cell>{geofence_name}</Table.Cell>
                  <Table.Cell>{asset_name}</Table.Cell>
                  <Table.Cell>{area ? Number(area).toFixed(2) : 0}</Table.Cell>
                  <Table.Cell>
                    {amount ? Number(amount).toFixed(2) : 0}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() =>
                        this.props.handleEditData({
                          id: _id.$oid,
                          geofence_name,
                          asset_name,
                          area,
                          amount,
                        })
                      }
                      disabled={submit}
                    >
                      Edit
                    </Button>
                    <Popup
                      on='click'
                      trigger={<Button disabled={submit}>Delete</Button>}
                    >
                      <Button onClick={() => this.handleDelete(_id.$oid)}>
                        Yes
                      </Button>
                    </Popup>

                    <Button
                      disabled={submit}
                      onClick={() => this.handleAddToBillId(_id.$oid)}
                    >
                      Make a Bill
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Modal
            closeOnDimmerClick={false}
            closeIcon
            onClose={() => this.setState({ addToBillId: '' })}
            open={addToBillId ? true : false}
          >
            <Modal.Header>Add to bill</Modal.Header>
            <Modal.Content>
              <DatePicker
                selected={addToBillTime}
                onChange={this.handleAddToBillTime}
                dateFormat='LLL'
                maxDate={moment()}
              />
              <br />
              <Button onClick={this.handleAddToBill}>SUBMIT</Button>
            </Modal.Content>
          </Modal>
        </Table>
      </div>
    );
  }
}

export default List;

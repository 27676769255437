import React from 'react';
import ClickOutside from '../../helper/click-outside';
import { refreshToken } from '../../helper/refresh-token';
import { REGULAR_PATH } from '../../../env';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Input,
  Checkbox,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class AssetSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectAll: false,
      selected: [],
      search: '',
      openDropdown: false,
    };
  }

  componentDidMount() {
    this.getAssets(this.props.passToAsset.all);
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgAssets !== prevProps.orgAssets) {
      const assets = this.props.orgAssets;

      this.setState({
        selected: [],
        selectAll: false,
        search: '',
        openDropdown: false,
        options: assets.map(({ active, label, value }) => ({
          id: value,
          name: label,
          active,
          checked: false,
        })),
      });

      if (assets.length > 0) {
        window.localStorage.setItem('assetList', JSON.stringify(assets));
      } else {
        window.localStorage.setItem('assetList', JSON.stringify([]));
      }

      this.props.passState([], true);

      this.props.history.push('/');
    }
  }

  processAssetList = (assetList) => {
    const options = [];

    const assets = assetList.map(({ _id, allow_view, name, asset_type }) => {
      const id = _id.$oid;

      options.push({ id, name, active: allow_view, checked: false });

      return {
        key: id,
        value: id,
        active: allow_view,
        label: name,
        asset_type: asset_type,
      };
    });

    this.setState({
      options: options.sort(function (x, y) {
        return x.active === y.active ? 0 : x.active ? -1 : 1;
      }),
    });

    if (assets.length > 0) {
      window.localStorage.setItem('assetList', JSON.stringify(assets));
    } else {
      window.localStorage.setItem('assetList', JSON.stringify([]));
    }
  };

  getAssets = (allAssets) => {
    allAssets
      .then((res) => {
        this.processAssetList(res.data);
      })
      .catch((error) => {
        if (error.response.data.error === 'expired token') {
          let extendedToken = refreshToken(`${REGULAR_PATH}/allAssets/list`);
          extendedToken.then((res) => {
            this.processAssetList(res.data);
          });
        }
      });
    return `assetOptions`;
  };

  handleSearch = (_, data) => {
    this.setState({ search: data.value });
  };

  handleSelect = (_, data) => {
    const options = this.state.options.map((option) => ({
      ...option,
      checked: option.id === data.id ? data.checked : option.checked,
    }));

    const selected = options
      .filter((option) => option.checked)
      .map((option) => option.id);

    this.setState({ options, selected, selectAll: false });

    this.props.passState(selected, true);
    this.props.history.push('/');
  };

  handleSelectAll = (checked) => {
    const selected = [];
    const options = this.state.options.map((option) => {
      if (checked) {
        selected.push(option.id);
      }
      return {
        ...option,
        checked: checked,
      };
    });

    this.setState({
      options,
      selected,
      selectAll: checked,
    });

    this.props.passState(selected, true);

    this.props.history.push('/');
  };

  openDropdown = () => {
    this.setState({ openDropdown: true });
  };

  closeDropdown = () => {
    this.setState({ openDropdown: false });
  };

  toggleDropdown = () =>
    this.setState({ openDropdown: !this.state.openDropdown });

  render() {
    const { options, search, selected, selectAll, openDropdown } = this.state;

    const totalSelected = selected.length;
    const filteredOptions = options.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase()),
    );

    if (options.length > 0) {
      return (
        <ClickOutside handleEvent={this.closeDropdown}>
          <div>
            <Dropdown
              floating
              trigger={
                <span
                  onClick={this.toggleDropdown}
                  style={{ padding: '0 5px' }}
                >
                  {totalSelected > 0
                    ? `${totalSelected} selected`
                    : 'SELECT ASSET'}
                </span>
              }
              open={openDropdown}
              style={{ color: 'white' }}
            >
              <DropdownMenu>
                <Input
                  placeholder='Search For Your Asset'
                  className='search'
                  onChange={this.handleSearch}
                />
                <DropdownMenu scrolling>
                  {filteredOptions.length >= 500 || search.length > 0 ? null : (
                    <DropdownItem
                      value='Select All'
                      content={
                        <Checkbox
                          label={'Select All'}
                          checked={selectAll}
                          onClick={(_, data) =>
                            this.handleSelectAll(data.checked)
                          }
                        />
                      }
                    />
                  )}

                  {filteredOptions.map(({ id, name, active, checked }) => (
                    <DropdownItem
                      key={id}
                      value={id}
                      content={
                        <Checkbox
                          id={id}
                          label={name}
                          disabled={!active}
                          checked={checked}
                          onClick={this.handleSelect}
                        />
                      }
                    />
                  ))}
                </DropdownMenu>
              </DropdownMenu>
            </Dropdown>
          </div>
        </ClickOutside>
      );
    } else {
      return <span />;
    }
  }
}

export default withRouter(AssetSuggestion);
